import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import { useMediaQuery } from "@material-ui/core/useMediaQuery";
import React from "react";
import { Applications } from "../../types/Applications";
import "./Apply.css";

export const CreditRequest: React.FC<any> = (props: {
   application: Applications;
   handleChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: "amountReq" | "numberEmployees" | "annualRevenue" | "ownership",
   ) => void;
   handleChangeMethod: (
      event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      value: "value",
   ) => void;
   handleCheckChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      value:
         | "Per Diem"
         | "Work Order"
         | "POD/BOL"
         | "Scale Ticket"
         | "Interchange In/Out"
         | "Accessorial Approval"
         | "Rate Confirmation"
         | "Customs Paperwork"
         | "Driver Detention",
   ) => void;
   handleChangeButton: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      value: "Email" | "EDI" | "Other" | "Corporation" | "Partnership" | "Individual" | "OwnershipOther",
   ) => void;
   errorHighlight: {
      perDiem: { error: boolean; message: string };
      amountReq: { error: boolean; message: string };
      numberEmployees: { error: boolean; message: string };
      annualRevenue: { error: boolean; message: string };
      method: { error: boolean; message: string };
      ownership: { error: boolean; message: string };
      documents: { error: boolean; message: string };
   };
   isContainerPort: boolean;
   perDiem: boolean;
}): JSX.Element => {
   return (
      <>
         <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
               <h1>Credit Request</h1>
            </Grid>
         </Grid>
         {props.isContainerPort ? (
            <Grid container direction="row" alignItems="center" justifyContent="center">
               <Grid item xs={12} alignItems="center" justifyContent="center">
                  <Typography
                     style={{
                        fontWeight: 700,
                        fontSize: "16px",
                     }}
                  >
                     ***Please note that in any instance where ContainerPort Group covers an expense on your behalf
                     outside of dray, fuel, and accessorials, an administration processing fee of 15% will be applied
                     (including but not limited to Per Diem and storage) unless otherwise agreed in writing prior to
                     shipment acceptance.***
                  </Typography>
               </Grid>
               <Grid item xs={12} alignItems="center" justifyContent="center" style={{ height: "30px" }}>
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={props.perDiem}
                           color="primary"
                           value={"Per Diem"}
                           onChange={(event) => props.handleCheckChange(event, "Per Diem")}
                           name="Per Diem"
                           disableRipple
                        />
                     }
                     label="I Agree"
                  />
               </Grid>
               <Grid item xs={12} alignItems="center" justifyContent="center">
                  <FormControl required error={props.errorHighlight.perDiem.error ? true : false} component="fieldset">
                     <FormHelperText>
                        {props.errorHighlight.perDiem.error ? props.errorHighlight.perDiem.message : " "}
                     </FormHelperText>
                  </FormControl>
               </Grid>
            </Grid>
         ) : (
            <></>
         )}
         <Grid container direction="row" alignContent="space-around" alignItems="center" justifyContent="center">
            <Grid item xs={4}>
               <TextField
                  required={true}
                  id="standard-required"
                  label="Credit Amount Requested"
                  onChange={(event) => props.handleChange(event, "amountReq")}
                  value={props.application.amountReq.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  helperText={props.errorHighlight.amountReq.error ? props.errorHighlight.amountReq.message : " "}
                  error={props.errorHighlight.amountReq.error ? true : false}
                  variant="outlined"
                  inputProps={{ maxLength: 9 }}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachMoneyIcon />
                        </InputAdornment>
                     ),
                  }}
               />
            </Grid>
            <Grid item xs={4}>
               <TextField
                  required={true}
                  id="standard-required"
                  variant="outlined"
                  label="# of Employees"
                  onChange={(event) => props.handleChange(event, "numberEmployees")}
                  value={props.application.numberEmployees.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  helperText={
                     props.errorHighlight.numberEmployees.error ? props.errorHighlight.numberEmployees.message : " "
                  }
                  error={props.errorHighlight.numberEmployees.error ? true : false}
                  inputProps={{ maxLength: 9 }}
               />
            </Grid>
            <Grid item xs={4}>
               <TextField
                  required={true}
                  id="standard-required"
                  variant="outlined"
                  label="Annual Revenue"
                  onChange={(event) => props.handleChange(event, "annualRevenue")}
                  value={props.application.annualRevenue.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  helperText={
                     props.errorHighlight.annualRevenue.error ? props.errorHighlight.annualRevenue.message : " "
                  }
                  error={props.errorHighlight.annualRevenue.error ? true : false}
                  inputProps={{ maxLength: 19 }}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachMoneyIcon />
                        </InputAdornment>
                     ),
                  }}
               />
            </Grid>
         </Grid>
         <Grid container direction="row" alignItems="center" justifyContent="center" alignContent="space-around">
            <Grid item xs={5} direction="column">
               <h3>Preferred Method (Select One)</h3>
               <Grid container direction="row">
                  <Grid container direction="column">
                     <FormControl>
                        <Paper
                           variant="outlined"
                           style={{
                              borderColor: props.errorHighlight.method.error ? "red" : "inherit",
                              minHeight: "160px",
                           }}
                        >
                           <Grid container direction="row">
                              <Grid container xs={4} direction="column">
                                 <ButtonGroup orientation="vertical">
                                    <Button
                                       variant="contained"
                                       color={
                                          props.application.method.code.toLowerCase() === "email"
                                             ? "primary"
                                             : undefined
                                       }
                                       onClick={(event) => props.handleChangeButton(event, "Email")}
                                       style={{ minHeight: "53px" }}
                                    >
                                       Email
                                    </Button>
                                    <Button
                                       variant="contained"
                                       color={
                                          props.application.method.code.toLowerCase() === "edi" ? "primary" : undefined
                                       }
                                       onClick={(event) => props.handleChangeButton(event, "EDI")}
                                       style={{ minHeight: "54px" }}
                                    >
                                       EDI (Available Upon Request)
                                    </Button>
                                    <Button
                                       variant="contained"
                                       color={
                                          props.application.method.code.toLowerCase() === "other"
                                             ? "primary"
                                             : undefined
                                       }
                                       onClick={(event) => props.handleChangeButton(event, "Other")}
                                       style={{ minHeight: "53px" }}
                                    >
                                       Other
                                    </Button>
                                 </ButtonGroup>
                              </Grid>
                              <Grid container xs={7} direction="column" style={{ marginLeft: "1rem" }}>
                                 <Grid container direction="row" style={{ minHeight: "53px" }}>
                                    <TextField
                                       required
                                       label="email"
                                       onChange={(event) => props.handleChangeMethod(event, "value")}
                                       disabled={props.application.method.code === "Email" ? false : true}
                                       value={
                                          props.application.method.code === "Email"
                                             ? props.application.method.value
                                             : ""
                                       }
                                    />
                                 </Grid>
                                 <Grid container direction="row" style={{ minHeight: "54px" }} />
                                 <Grid container direction="row" style={{ minHeight: "53px" }}>
                                    <TextField
                                       required
                                       label="other"
                                       onChange={(event) => props.handleChangeMethod(event, "value")}
                                       disabled={props.application.method.code === "Other" ? false : true}
                                       value={
                                          props.application.method.code === "Other"
                                             ? props.application.method.value
                                             : ""
                                       }
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Paper>
                        <FormHelperText style={{ color: "red", marginLeft: "1rem" }}>
                           {props.errorHighlight.method.error ? props.errorHighlight.method.message : " "}
                        </FormHelperText>
                     </FormControl>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={3} direction="column">
               <h3>Ownership (Select One)</h3>
               <Grid container direction="row">
                  <Grid container direction="column">
                     <FormControl>
                        <Paper
                           variant="outlined"
                           style={{
                              borderColor: props.errorHighlight.ownership.error ? "red" : "inherit",
                              minHeight: "160px",
                           }}
                        >
                           <Grid container direction="column">
                              <ButtonGroup orientation="vertical">
                                 <Button
                                    variant="contained"
                                    color={
                                       props.application.ownership.toLowerCase() === "corporation"
                                          ? "primary"
                                          : undefined
                                    }
                                    onClick={(event) => props.handleChangeButton(event, "Corporation")}
                                    style={{ minHeight: "40px" }}
                                 >
                                    Corporation
                                 </Button>
                                 <Button
                                    variant="contained"
                                    color={
                                       props.application.ownership.toLowerCase() === "partnership"
                                          ? "primary"
                                          : undefined
                                    }
                                    onClick={(event) => props.handleChangeButton(event, "Partnership")}
                                    style={{ minHeight: "40px" }}
                                 >
                                    Partnership
                                 </Button>
                                 <Button
                                    variant="contained"
                                    color={
                                       props.application.ownership.toLowerCase() === "individual"
                                          ? "primary"
                                          : undefined
                                    }
                                    onClick={(event) => props.handleChangeButton(event, "Individual")}
                                    style={{ minHeight: "40px" }}
                                 >
                                    Individual
                                 </Button>
                                 <Button
                                    variant="contained"
                                    color={
                                       props.application.ownership.toLowerCase() === "other" ? "primary" : undefined
                                    }
                                    onClick={(event) => props.handleChangeButton(event, "OwnershipOther")}
                                    style={{ minHeight: "40px" }}
                                 >
                                    Other
                                 </Button>
                              </ButtonGroup>
                           </Grid>
                        </Paper>
                        <FormHelperText style={{ color: "red" }}>
                           {props.errorHighlight.ownership.error ? props.errorHighlight.ownership.message : " "}
                        </FormHelperText>
                     </FormControl>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={4} direction="column">
               <h3>Documents Required With Invoice</h3>
               <Paper
                  variant="outlined"
                  style={{
                     borderColor: props.errorHighlight.documents.error ? "red" : "inherit",
                     minHeight: "160px",
                  }}
               >
                  <FormGroup
                     style={{
                        marginLeft: "1rem",
                     }}
                  >
                     <Grid container direction="row">
                        <Grid item xs={6} direction="column" alignItems="flex-start" justifyContent="flex-start">
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={props.application.documents.indexOf("Work Order") > -1 ? true : false}
                                    color="primary"
                                    value={"Work Order"}
                                    onChange={(event) => props.handleCheckChange(event, "Work Order")}
                                    name="Work Order"
                                    disableRipple
                                 />
                              }
                              label="Work Order"
                           />
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={props.application.documents.indexOf("POD/BOL") > -1 ? true : false}
                                    color="primary"
                                    value={"POD/BOL"}
                                    onChange={(event) => props.handleCheckChange(event, "POD/BOL")}
                                    name="POD/BOL"
                                    disableRipple
                                 />
                              }
                              label="POD/BOL"
                           />
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={props.application.documents.indexOf("Scale Ticket") > -1 ? true : false}
                                    value={"Scale Ticket"}
                                    color="primary"
                                    onChange={(event) => props.handleCheckChange(event, "Scale Ticket")}
                                    name="Scale Ticket"
                                    disableRipple
                                 />
                              }
                              label="Scale Ticket"
                           />
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={
                                       props.application.documents.indexOf("Interchange In/Out") > -1 ? true : false
                                    }
                                    color="primary"
                                    value={"Interchange In/Out"}
                                    onChange={(event) => props.handleCheckChange(event, "Interchange In/Out")}
                                    name="Interchange In/Out"
                                    disableRipple
                                 />
                              }
                              label="Interchange In/Out"
                           />
                        </Grid>
                        <Grid item xs={6} direction="column" alignItems="flex-end" justifyContent="flex-end">
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={
                                       props.application.documents.indexOf("Accessorial Approval") > -1 ? true : false
                                    }
                                    color="primary"
                                    value={"Accessorial Approval"}
                                    onChange={(event) => props.handleCheckChange(event, "Accessorial Approval")}
                                    name="Accessorial Approval"
                                    disableRipple
                                 />
                              }
                              label="Accessorial Approval"
                           />
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={
                                       props.application.documents.indexOf("Rate Confirmation") > -1 ? true : false
                                    }
                                    color="primary"
                                    value={"Rate Confirmation"}
                                    onChange={(event) => props.handleCheckChange(event, "Rate Confirmation")}
                                    name="Rate Confirmation"
                                    disableRipple
                                 />
                              }
                              label="Rate Confirmation"
                           />
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={
                                       props.application.documents.indexOf("Customs Paperwork") > -1 ? true : false
                                    }
                                    color="primary"
                                    value={"Customs Paperwork"}
                                    onChange={(event) => props.handleCheckChange(event, "Customs Paperwork")}
                                    name="Customs Paperwork"
                                    disableRipple
                                 />
                              }
                              label="Customs Paperwork"
                           />
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={
                                       props.application.documents.indexOf("Driver Detention") > -1 ? true : false
                                    }
                                    color="primary"
                                    value={"Driver Detention"}
                                    onChange={(event) => props.handleCheckChange(event, "Driver Detention")}
                                    name="Driver Detention"
                                    disableRipple
                                 />
                              }
                              label="Driver Detention"
                           />
                        </Grid>
                     </Grid>
                  </FormGroup>
               </Paper>
               <FormHelperText style={{ color: "red" }}>
                  {props.errorHighlight.documents.error ? props.errorHighlight.documents.message : " "}
               </FormHelperText>
            </Grid>
         </Grid>
      </>
   );
};

export default CreditRequest;
