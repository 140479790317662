import React, { createContext, Dispatch, useReducer } from "react";
import { Users } from "../../types/Users";
import { UserActions, userReducer } from "./Reducers";

type InitialStateType = {
   CurrentUser: Users;
};

const initialState = {
   CurrentUser: {
      _id: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      active: null,
      companies: null,
      divisions: null,
      security: "",
      invite: "",
      maximumApprovalAmount: 0,
      department: "",
      addDate: null,
      deleteDate: null,
      homePage: {
         page: null,
         view: null,
      },
   },
};

const UserContext = createContext<{
   state: InitialStateType;
   dispatch: Dispatch<UserActions>;
}>({
   state: initialState,
   dispatch: () => null,
});

const mainReducer = ({ CurrentUser }: InitialStateType, action: UserActions) => ({
   CurrentUser: userReducer(CurrentUser, action),
});

// eslint-disable-next-line react/prop-types
const UserProvider: React.FC = ({ children }) => {
   const [state, dispatch] = useReducer(mainReducer, initialState);
   return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
//BUG IN prettier.
