import { Divider, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import * as CookieTools from "../../utilities/CookieTools";
import CompanyDialog from "./CompanyDialog";

const useStyles = makeStyles((theme) => ({
   containerStyle: {
      [theme.breakpoints.up("sm")]: {
         width: "100%",
      },
      backgroundColor: "#002D72",
      width: "100%",
      height: "78px",
      maxHeight: "78px",
      zIndex: 3,
   },
   title: {
      flexGrow: 1,
      textAlign: "center",
      color: "white",
   },
   headerButtons: {
      backgroundColor: "#002D72",
      color: "#ffffff",
      alignItems: "flex-end",
      fontSize: "24px",
   },
   headerUser: {
      backgroundColor: "#002D72",
      paddingLeft: ".5rem",
      color: "#778BA3",
      alignItems: "flex-end",
      fontSize: "24px",
   },
}));

export const Header: React.FC<any> = (props: { content: string; loading: boolean }): JSX.Element => {
   const history = useHistory();
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [anchor, setAnchor] = useState<null | HTMLElement>(null);
   const [profileTitle, setProfileTitle] = useState("");
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const [companyModal, setCompanyModal] = useState(false);

   //loading Use Effect. Set the name of the selected company/selected division
   useEffect(() => {
      if (!props.loading && companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         const companies = CookieTools.getCookie("selected-company").split(",");
         const divisions = CookieTools.getCookie("selected-division").split(",");

         if (companies.length > 1) {
            setProfileTitle("");
            return;
         }

         if (divisions.length === 1 && divisions.toString()) {
            const selectedDivision = _.filter(divisionState.Divisions, (x) => x._id === divisions[0]);

            setProfileTitle(selectedDivision.length > 0 ? selectedDivision[0].name : "");
            return;
         }

         if (companies.length === 1 && companies.toString()) {
            const selectedCompany = _.filter(companyState.Companies, (x) => x._id === companies[0]);

            setProfileTitle(selectedCompany.length > 0 ? selectedCompany[0].name : "");
            return;
         }

         setProfileTitle("");
      }
   }, [props.loading, companyState, divisionState]);

   async function handleOpenProfile(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      setAnchor(event.currentTarget);
   }

   async function handleCloseProfile() {
      setAnchor(null);
   }

   async function handleLogout() {
      CookieTools.deleteCookie("ca-user-id");
      CookieTools.deleteCookie("selected-company");
      CookieTools.deleteCookie("selected-division");
      window.location.replace("/login");
   }

   async function handleProfile() {
      window.location.replace("/users/details/" + CookieTools.getCookie("ca-user-id"));
   }

   return (
      <>
         <AppBar position="fixed" className={classes.containerStyle}>
            <Toolbar>
               <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ height: mobileScreen ? "78px" : "" }}
               >
                  <div className={classes.headerButtons}>
                     <Tooltip title="Profile" arrow>
                        <IconButton style={{ color: "#ffffff" }} onClick={(event) => handleOpenProfile(event)}>
                           <PersonIcon />
                        </IconButton>
                     </Tooltip>
                     <Menu
                        id="fade-menu"
                        anchorEl={anchor}
                        keepMounted
                        open={Boolean(anchor)}
                        onClose={() => handleCloseProfile()}
                        TransitionComponent={Fade}
                        style={{ marginTop: "2rem", marginLeft: "1rem" }}
                     >
                        <div
                           style={{
                              display: profileTitle ? "block" : "none",
                           }}
                        >
                           <Typography
                              style={{
                                 textAlign: "center",
                                 fontWeight: 700,
                              }}
                              color="primary"
                           >
                              {profileTitle}
                           </Typography>
                        </div>
                        <MenuItem onClick={() => handleProfile()}>
                           <PersonIcon />
                           <ListItemText
                              style={{ marginLeft: ".5rem" }}
                              primary="Profile"
                              secondary="Manage your profile"
                           />
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => setCompanyModal(true)}>
                           <BusinessCenterIcon />
                           <ListItemText
                              style={{ marginLeft: ".5rem" }}
                              primary="Company"
                              secondary="Change your company account"
                           />
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => handleLogout()}>
                           <ExitToAppIcon />
                           <Typography style={{ fontWeight: 700, marginLeft: ".5rem" }}>Logout</Typography>
                        </MenuItem>
                     </Menu>
                  </div>
                  <Tooltip title="Hey, it's you!" arrow>
                     <div className={classes.headerUser}>{props.content ? props.content : "User"}</div>
                  </Tooltip>
               </Grid>
            </Toolbar>
         </AppBar>
         <CompanyDialog
            open={companyModal}
            handleClose={() => setCompanyModal(false)}
            title={`beep boop`}
            text={`beep boop`}
         />
      </>
   );
};

export default Header;
