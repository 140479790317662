import {
   Breadcrumbs,
   Button,
   ButtonGroup,
   Card,
   CardContent,
   CardHeader,
   CircularProgress,
   Collapse,
   Divider,
   FormControlLabel,
   Grid,
   IconButton,
   InputAdornment,
   Link,
   Select,
   Switch,
   TextField,
   Typography,
   useMediaQuery,
} from "@material-ui/core";
import { blue, green, grey, purple, red } from "@material-ui/core/colors";
import {
   Theme,
   ThemeProvider,
   createStyles,
   createTheme,
   makeStyles,
   useTheme,
   withStyles,
} from "@material-ui/core/styles";
import {
   AttachMoney as AttachMoneyIcon,
   Close as CloseIcon,
   NavigateNext as NavigateNextIcon,
   Save as SaveIcon,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getUserById, setUser } from "../../api/UserApi";
import TransferList from "../../components/TransferList/TransferList";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import CheckAnimation from "../../styles/CheckAnimation";
import { Companies } from "../../types/Companies";
import { Divisions } from "../../types/Divisions";
import { Users } from "../../types/Users";
import * as CookieTools from "../../utilities/CookieTools";
import { DateFormatter } from "../../utilities/DateFormatter";
import "./UserDetails.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      headerDrawerOpen: {
         marginLeft: 240,
      },
      headerDrawerClosed: {
         marginLeft: theme.spacing(7),
      },
      divider: {
         backgroundColor: "#778BA3",
         height: "3px",
         width: "100%",
      },
      activeChip: {
         backgroundColor: green[500],
         color: "white",
      },
      inactiveChip: {
         backgroundColor: red[500],
         color: "white",
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      labelMobile: {
         flexDirection: "column",
         fontSize: "7px",
      },
      buttonProgress: {
         color: green[500],
      },
   }),
);

const readOnlyButtonTheme = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: grey[900],
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
      action: {
         disabledBackground: grey[900],
         disabled: "#ffffff",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const adminButtonTheme = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: blue[500],
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
      action: {
         disabledBackground: blue[500],
         disabled: "#ffffff",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const superAdminButtonTheme = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: purple[200],
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
      action: {
         disabledBackground: purple[200],
         disabled: "#ffffff",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const buttonTheme = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#002D72",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const GreySwitch = withStyles({
   switchBase: {
      color: grey[300],
      "&$checked": {
         color: grey[500],
      },
      "&$checked + $track": {
         backgroundColor: grey[500],
      },
   },
   checked: {},
   track: {},
})(Switch);

export const UserDetails: React.FC<any> = ({ match }: RouteComponentProps<{ id: string }>): JSX.Element => {
   const classes = useStyles();
   const history = useHistory();
   const theme = useTheme();
   const handleError = useErrorHandler();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const { state: drawerState } = useContext(DrawerContext);
   const [selectedUser, setSelectedUser] = useState<Users>({
      _id: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      active: false,
      companies: [],
      divisions: [],
      security: "",
      invite: "",
      maximumApprovalAmount: 0,
      department: "",
      addDate: new Date(),
      deleteDate: null,
      homePage: {
         page: "",
         view: "",
      },
   });
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);
   const [companyList, setCompanyList] = useState<Divisions[]>([]);
   const [leftList, setLeftList] = React.useState<{ _id: string; value: string }[]>([]);
   const [rightList, setRightList] = React.useState<{ _id: string; value: string }[]>([]);
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [transferDisabled, setTransferDisabled] = useState<boolean>(false);
   const [loading, setLoading] = useState(true);
   const [homePage, setHomePage] = useState(userState.CurrentUser.homePage.page);
   const [homeView, setHomeView] = useState(userState.CurrentUser.homePage.view);

   //States Use Effect.
   useEffect(() => {
      //Merge these for a clean transfer list.
      if (
         companyState.Companies.length > 0 &&
         divisionState.Divisions.length > 0 &&
         selectedUser._id !== "" &&
         companyList.length === 0
      ) {
         let newCompanyList: Divisions[] = [];

         companyState.Companies.forEach(function (company: Companies) {
            if (company.active === false || company.deleteDate !== null) {
               return;
            }

            const divisionsAssigned = _.filter(
               divisionState.Divisions,
               (x) => x.company_id === company._id && x.active === true && x.deleteDate === null,
            );

            if (divisionsAssigned.length > 0) {
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: company.denialCodes,
                     contacts: company.contacts,
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState, selectedUser]);

   //States Use Effect.
   useEffect(() => {
      //Merge these for a clean transfer list.
      if (companyList.length > 0 && selectedUser._id !== "") {
         let newLeftList: { _id: string; value: string }[] = [];
         let newRightList: { _id: string; value: string }[] = [];

         companyList.forEach(function (division: Divisions) {
            if (division._id !== "") {
               const divisionsAssigned = _.includes(selectedUser.divisions, division._id);

               if (divisionsAssigned) {
                  newRightList = [...newRightList, { _id: division._id, value: division.code + " | " + division.name }];
               } else {
                  newLeftList = [...newLeftList, { _id: division._id, value: division.code + " | " + division.name }];
               }
            } else {
               const companiesAssigned = _.includes(selectedUser.companies, division.company_id);

               if (companiesAssigned) {
                  newRightList = [
                     ...newRightList,
                     { _id: division.company_id, value: division.code + " | " + division.name },
                  ];
               } else {
                  newLeftList = [
                     ...newLeftList,
                     { _id: division.company_id, value: division.code + " | " + division.name },
                  ];
               }
            }
         });

         newLeftList = _.orderBy(newLeftList, ["value"], ["asc"]);
         newRightList = _.orderBy(newRightList, ["value"], ["asc"]);

         setLeftList(newLeftList);
         setRightList(newRightList);
         setTransferDisabled(userState.CurrentUser.security.toUpperCase() === "SUPER ADMIN" ? false : true);
         setLoading(false);
      }
   }, [companyList, selectedUser]);

   function breadcrumbOnClick(path: string) {
      if (path) {
         history.push(path);
      }
   }

   async function getUser() {
      try {
         const responseData = await getUserById(match.params.id);

         if (responseData.success) {
            setSelectedUser(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   useEffect(() => {
      getUser();
   }, []);

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function onChangeActive(event: React.ChangeEvent<HTMLInputElement>) {
      try {
         const userObj = { ...selectedUser };
         userObj.active = event.target.checked;
         setSelectedUser(userObj);
      } catch (err) {
         handleError(err);
      }
   }

   async function onChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: "firstName" | "lastName" | "maximumApprovalAmount" | "department",
   ) {
      try {
         const userObj = { ...selectedUser };

         if (value === "maximumApprovalAmount") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            userObj.maximumApprovalAmount = onlyNums ? +onlyNums : 0;
         } else {
            userObj[value] = event.target.value;
         }

         setSelectedUser(userObj);
      } catch (err) {
         handleError(err);
      }
   }

   async function onChangeHomePage(event: React.ChangeEvent<{ value: unknown }>, value: "page" | "view") {
      const userObj = { ...selectedUser };
      userObj.homePage[value] = event.target.value as string;
      setSelectedUser(userObj);
   }

   async function onChangeSecurity(value: "Read Only" | "Admin" | "Super Admin") {
      try {
         const userObj = { ...selectedUser };
         userObj.security = value;
         setSelectedUser(userObj);
      } catch (err) {
         //handleError(err);
      }
   }

   async function saveUser() {
      try {
         if (rightList.length <= 0) {
            openAlertMessage("You must select at least one company or division!", "warning");
            return;
         }

         if (
            userState.CurrentUser.security.toUpperCase() === "READ ONLY" &&
            userState.CurrentUser._id !== selectedUser._id
         ) {
            openAlertMessage("Access to save the user is denied!", "warning");
            return;
         }

         if (selectedUser.maximumApprovalAmount === 0 && selectedUser.security.toUpperCase() !== "READ ONLY") {
            openAlertMessage("You must enter a maximum approval amount!", "warning");
            return;
         }

         setButtonClicked(true);

         const userObj = { ...selectedUser };
         userObj.companies = [];
         userObj.divisions = [];

         rightList.forEach(async function (value: { _id: string; value: string }) {
            const divisionsAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id.toString() === value._id.toString(),
            );

            if (divisionsAssigned.length > 0) {
               userObj.divisions?.push(value._id);

               const companyAssigned = _.includes(userObj.companies, divisionsAssigned[0].company_id);

               if (!companyAssigned) {
                  userObj.companies?.push(divisionsAssigned[0].company_id.toString());
               }
            } else {
               userObj.companies?.push(value._id);
            }
         });

         if (userObj.security.toUpperCase() === "ADMIN" && userObj.companies.length > 1) {
            openAlertMessage("Admins can only be assigned to one company!", "warning");
            return;
         }

         const responseData = await setUser(userObj);

         if (responseData.success) {
            openAlertMessage("Successfully updated user.", "success");
            setButtonClicked(false);
            getUser();
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         setButtonClicked(false);
         handleError(err);
      }
   }

   async function handleClose() {
      try {
         history.push("/users");
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <>
         <div className="UserDetails">
            <div className="UserDetails-root">
               {loading ? (
                  <>
                     <CheckAnimation />
                  </>
               ) : (
                  <>
                     <div className="UserDetails-header">
                        <div
                           className={
                              drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                           }
                        >
                           <Grid
                              xs={12}
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              style={{ height: "60px" }}
                           >
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 7 : 10}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <Breadcrumbs separator={<NavigateNextIcon />}>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/users")}
                                    >
                                       <Typography noWrap variant="h6" style={{ fontWeight: 700 }}>
                                          {"Users"}
                                       </Typography>
                                    </Link>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/users/details/" + match.params.id)}
                                    >
                                       <Typography noWrap variant="h6" style={{ fontWeight: 700 }}>
                                          {selectedUser.firstName
                                             ? selectedUser.firstName + " " + selectedUser.lastName
                                             : null}
                                       </Typography>
                                    </Link>
                                 </Breadcrumbs>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 5 : 2}
                                 justifyContent="center"
                                 alignItems="center"
                                 style={{ height: "60px" }}
                              >
                                 <ButtonGroup>
                                    <Button
                                       onClick={() => handleClose()}
                                       variant="contained"
                                       classes={{
                                          label: mobileScreen ? classes.labelMobile : classes.label,
                                       }}
                                    >
                                       <CloseIcon />
                                       Close
                                    </Button>
                                    <Button
                                       onClick={() => saveUser()}
                                       variant="contained"
                                       color="primary"
                                       classes={{
                                          label: mobileScreen ? classes.labelMobile : classes.label,
                                       }}
                                       disabled={
                                          (userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN" &&
                                             selectedUser._id !== CookieTools.getCookie("ca-user-id")) ||
                                          buttonClicked
                                       }
                                    >
                                       {buttonClicked ? (
                                          <CircularProgress size={20} className={classes.buttonProgress} />
                                       ) : (
                                          <SaveIcon />
                                       )}
                                       {mobileScreen ? "Save" : "Save User"}
                                    </Button>
                                 </ButtonGroup>
                              </Grid>
                           </Grid>
                        </div>
                     </div>
                     <div
                        className={
                           drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                        }
                     >
                        <Collapse in={alertMessage ? true : false}>
                           <Alert
                              variant="filled"
                              severity={alertType}
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       setAlertMessage("");
                                    }}
                                 >
                                    <CloseIcon />
                                 </IconButton>
                              }
                           >
                              {alertMessage}
                           </Alert>
                        </Collapse>
                        <div className="UserDetails-body">
                           <div
                              className={
                                 drawerState.DrawerOpen === true
                                    ? "UserDetails-layout-drawer-open"
                                    : "UserDetails-layout"
                              }
                           >
                              <Grid container direction={"row"} item xs={12}>
                                 <Grid container direction={"column"} item xs={mobileScreen ? 12 : 6}>
                                    <Grid
                                       container
                                       direction={"row"}
                                       item
                                       xs={12}
                                       justifyContent="flex-start"
                                       alignItems="flex-start"
                                    >
                                       <Card style={{ width: "100%" }}>
                                          <CardHeader
                                             className={"UserDetails-card-header1"}
                                             action={
                                                <div
                                                   style={{
                                                      display:
                                                         (selectedUser.active === false && selectedUser.invite) ||
                                                         selectedUser._id === CookieTools.getCookie("ca-user-id")
                                                            ? "none"
                                                            : "block",
                                                   }}
                                                >
                                                   <FormControlLabel
                                                      style={{ marginTop: ".25rem" }}
                                                      control={
                                                         <GreySwitch
                                                            checked={selectedUser.active ? true : false}
                                                            onChange={(event) => onChangeActive(event)}
                                                            disabled={
                                                               userState.CurrentUser.security.toUpperCase() !==
                                                               "SUPER ADMIN"
                                                            }
                                                         />
                                                      }
                                                      label={selectedUser.active ? "Active" : "Inactive"}
                                                      labelPlacement="start"
                                                   />
                                                </div>
                                             }
                                             title={
                                                <Typography noWrap variant="h6">
                                                   {"User Information"}
                                                </Typography>
                                             }
                                             style={{ height: "4rem" }}
                                          />
                                          <Divider className={classes.divider} />
                                          <CardContent>
                                             <Grid container direction={"column"} item xs={12} alignItems="center">
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <TextField
                                                         id="tf-email"
                                                         label="Email"
                                                         variant="outlined"
                                                         name="email"
                                                         value={selectedUser.email}
                                                         disabled={true}
                                                      />
                                                   </Grid>
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <TextField
                                                         id="tf-dateAdded"
                                                         label="Date Added"
                                                         variant="outlined"
                                                         name="dateAdded"
                                                         value={DateFormatter(selectedUser.addDate)}
                                                         disabled={true}
                                                         style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      />
                                                   </Grid>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <TextField
                                                         id="tf-firstName"
                                                         label="First Name"
                                                         variant="outlined"
                                                         name="firstName"
                                                         value={selectedUser.firstName}
                                                         onChange={(event) => onChange(event, "firstName")}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() !==
                                                               "SUPER ADMIN" &&
                                                            selectedUser._id !== CookieTools.getCookie("ca-user-id")
                                                         }
                                                      />
                                                   </Grid>
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <TextField
                                                         id="tf-lastName"
                                                         label="Last Name"
                                                         variant="outlined"
                                                         name="lastName"
                                                         value={selectedUser.lastName}
                                                         style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                         onChange={(event) => onChange(event, "lastName")}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() !==
                                                               "SUPER ADMIN" &&
                                                            selectedUser._id !== CookieTools.getCookie("ca-user-id")
                                                         }
                                                      />
                                                   </Grid>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <TextField
                                                         id="tf-maximumApprovalAmount"
                                                         label="Maximum Approval Amount"
                                                         variant="outlined"
                                                         name="maximumApprovalAmount"
                                                         value={selectedUser.maximumApprovalAmount.toLocaleString(
                                                            undefined,
                                                            { maximumFractionDigits: 0 },
                                                         )}
                                                         onChange={(event) => onChange(event, "maximumApprovalAmount")}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() !==
                                                               "SUPER ADMIN" ||
                                                            (userState.CurrentUser.security.toUpperCase() ===
                                                               "SUPER ADMIN" &&
                                                               selectedUser._id === CookieTools.getCookie("ca-user-id"))
                                                         }
                                                         inputProps={{ maxLength: 9 }}
                                                         InputProps={{
                                                            startAdornment: (
                                                               <InputAdornment position="start">
                                                                  <AttachMoneyIcon />
                                                               </InputAdornment>
                                                            ),
                                                         }}
                                                      />
                                                   </Grid>
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <TextField
                                                         id="tf-department"
                                                         label="Department"
                                                         variant="outlined"
                                                         name="department"
                                                         value={selectedUser.department}
                                                         onChange={(event) => onChange(event, "department")}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() !==
                                                               "SUPER ADMIN" ||
                                                            (userState.CurrentUser.security.toUpperCase() ===
                                                               "SUPER ADMIN" &&
                                                               selectedUser._id === CookieTools.getCookie("ca-user-id"))
                                                         }
                                                      />
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                          </CardContent>
                                       </Card>
                                       <Card style={{ width: "100%", marginTop: theme.spacing(7) }}>
                                          <CardHeader
                                             className={"UserDetails-card-header1"}
                                             style={{ height: "4rem" }}
                                             title={
                                                <Typography noWrap variant="h6">
                                                   {"Security"}
                                                </Typography>
                                             }
                                          />
                                          <Divider className={classes.divider} />
                                          <Grid
                                             container
                                             direction="row"
                                             alignItems="center"
                                             justifyContent="center"
                                             alignContent="space-around"
                                          >
                                             <ButtonGroup orientation="vertical" fullWidth>
                                                <ThemeProvider
                                                   theme={
                                                      selectedUser.security.toUpperCase() === "READ ONLY"
                                                         ? readOnlyButtonTheme
                                                         : buttonTheme
                                                   }
                                                >
                                                   <Button
                                                      color={
                                                         selectedUser.security.toUpperCase() === "READ ONLY"
                                                            ? "primary"
                                                            : undefined
                                                      }
                                                      variant="contained"
                                                      style={{ borderRadius: "0em" }}
                                                      onClick={() =>
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                            ? null
                                                            : onChangeSecurity("Read Only")
                                                      }
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                   >
                                                      Read Only
                                                   </Button>
                                                </ThemeProvider>
                                                <ThemeProvider
                                                   theme={
                                                      selectedUser.security.toUpperCase() === "ADMIN"
                                                         ? adminButtonTheme
                                                         : buttonTheme
                                                   }
                                                >
                                                   <Button
                                                      color={
                                                         selectedUser.security.toUpperCase() === "ADMIN"
                                                            ? "primary"
                                                            : undefined
                                                      }
                                                      variant="contained"
                                                      style={{ borderRadius: "0em" }}
                                                      onClick={() =>
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                            ? null
                                                            : onChangeSecurity("Admin")
                                                      }
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                   >
                                                      Admin
                                                   </Button>
                                                </ThemeProvider>
                                                <ThemeProvider
                                                   theme={
                                                      selectedUser.security.toUpperCase() === "SUPER ADMIN"
                                                         ? superAdminButtonTheme
                                                         : buttonTheme
                                                   }
                                                >
                                                   <Button
                                                      color={
                                                         selectedUser.security.toUpperCase() === "SUPER ADMIN"
                                                            ? "primary"
                                                            : undefined
                                                      }
                                                      variant="contained"
                                                      style={{ borderRadius: "0em" }}
                                                      onClick={() =>
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                            ? null
                                                            : onChangeSecurity("Super Admin")
                                                      }
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                   >
                                                      Super Admin
                                                   </Button>
                                                </ThemeProvider>
                                             </ButtonGroup>
                                          </Grid>
                                       </Card>
                                    </Grid>
                                 </Grid>
                                 <Grid container direction={"column"} item xs={mobileScreen ? 12 : 6}>
                                    <Grid
                                       container
                                       direction={"row"}
                                       item
                                       xs={12}
                                       justifyContent="flex-start"
                                       alignItems="flex-start"
                                    >
                                       <Card style={{ width: "100%" }}>
                                          <CardHeader
                                             className={"UserDetails-card-header1"}
                                             style={{ height: "4rem" }}
                                             title={
                                                <Typography noWrap variant="h6">
                                                   {"Companies | Divisions"}
                                                </Typography>
                                             }
                                          />
                                          <Divider className={classes.divider} />
                                          <CardContent>
                                             <Grid container direction={"column"} item xs={12} alignItems="center">
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <TransferList
                                                      leftTitle={"Available Companies | Divisions"}
                                                      rightTitle={"Assigned Companies | Divisions"}
                                                      leftItems={leftList}
                                                      rightItems={rightList}
                                                      setLeftItems={setLeftList}
                                                      setRightItems={setRightList}
                                                      disabled={transferDisabled}
                                                      readOnly={false}
                                                   />
                                                </Grid>
                                             </Grid>
                                          </CardContent>
                                       </Card>
                                       <Card style={{ width: "100%" }}>
                                          <CardHeader
                                             className={"UserDetails-card-header1"}
                                             action={
                                                <div
                                                   style={{
                                                      display:
                                                         (selectedUser.active === false && selectedUser.invite) ||
                                                         selectedUser._id === CookieTools.getCookie("ca-user-id")
                                                            ? "none"
                                                            : "block",
                                                   }}
                                                >
                                                   <FormControlLabel
                                                      style={{ marginTop: ".25rem" }}
                                                      control={
                                                         <GreySwitch
                                                            checked={selectedUser.active ? true : false}
                                                            onChange={(event) => onChangeActive(event)}
                                                            disabled={
                                                               userState.CurrentUser.security.toUpperCase() !==
                                                               "SUPER ADMIN"
                                                            }
                                                         />
                                                      }
                                                      label={selectedUser.active ? "Active" : "Inactive"}
                                                      labelPlacement="start"
                                                   />
                                                </div>
                                             }
                                             title={
                                                <Typography noWrap variant="h6">
                                                   {"Default Home Page"}
                                                </Typography>
                                             }
                                             style={{ height: "4rem" }}
                                          />
                                          <Divider className={classes.divider} />
                                          <CardContent>
                                             <Grid container direction={"column"} item xs={12} alignItems="center">
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <Select
                                                         native
                                                         variant="outlined"
                                                         fullWidth
                                                         value={homePage}
                                                         onChange={(event) => {
                                                            setHomePage(event.target.value as string);
                                                            onChangeHomePage(event, "page");
                                                         }}
                                                      >
                                                         <option value="applications">Applications</option>
                                                         <option value="activations">Activations</option>
                                                      </Select>
                                                   </Grid>
                                                   <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                      <Select
                                                         native
                                                         variant="outlined"
                                                         fullWidth
                                                         value={homeView}
                                                         onChange={(event) => {
                                                            setHomeView(event.target.value as string);
                                                            onChangeHomePage(event, "view");
                                                         }}
                                                      >
                                                         {[
                                                            "SENT",
                                                            "NEW",
                                                            "PENDING",
                                                            "APPROVED",
                                                            "DENIED",
                                                            "REVIEWED",
                                                         ].map((view) => (
                                                            <option key={view} value={view}>
                                                               {view}
                                                            </option>
                                                         ))}
                                                      </Select>
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                          </CardContent>
                                       </Card>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
      </>
   );
};

export default UserDetails;
