import { createStyles, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const useActivationStyles = makeStyles((theme) =>
   createStyles({
      container: {
         borderRadius: "8px",
         padding: "24px",
      },
      labelText: {
         fontSize: "16px",
      },
      title: {
         color: theme.palette.primary.main,
         letterSpacing: "0.1px",
         fontSize: "24px",
         "& span": {
            color: "#A0A0A0",
         },
      },
      section2: {
         display: "flex",
         justifyContent: "space-between",
         gap: "24px",
      },
      section2Title: {
         fontSize: "20px",
         fontWeight: 700,
         letterSpacing: "0.1px",
         color: theme.palette.primary.main,
         paddingLeft: "11px",
      },
      section2Paragraph: {
         fontSize: "14px",
      },
      formControl: {
         width: "100%",
         minWidth: "200px",
      },
      note: {
         padding: "8px 16px",
         backgroundColor: "#EEEEEE",
         textAlign: "center",
         fontSize: "16px",
         marginTop: "45px",
         marginBottom: "25px",
         fontWeight: "bold",
      },
      buttonProgress: {
         color: green[500],
      },
      controlLabel: {
         "& .MuiTypography-root.MuiFormControlLabel-label": {
            fontSize: "16px",
         },
      },
      colorTheme: {
         "& .MuiTypography-root.MuiFormControlLabel-label": {
            color: theme.palette.primary.main,
         },
      },
   }),
);
