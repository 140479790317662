import { MenuItem, useMediaQuery } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { blue, green, grey, purple, red } from "@material-ui/core/colors";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SearchIcon from "@material-ui/icons/Search";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { getDivisionList, removeDivision } from "../../api/DivisionApi";
import { CompanyContext } from "../../stores/Companies/Context";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import CheckAnimation from "../../styles/CheckAnimation";
import { Divisions } from "../../types/Divisions";
import { DateFormatter } from "../../utilities/DateFormatter";
import DivisionNew from "./DivisionNew";
import "./DivisionView.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      headerDrawerOpen: {
         marginLeft: 240,
      },
      headerDrawerClosed: {
         marginLeft: theme.spacing(7),
      },
      avatar: {
         width: theme.spacing(6),
         height: theme.spacing(6),
      },
      divider: {
         backgroundColor: "#778BA3",
         height: "3px",
         width: "100%",
      },
      activeChip: {
         backgroundColor: green[500],
         color: "white",
      },
      inactiveChip: {
         backgroundColor: red[500],
         color: "white",
      },
      readOnlyChip: {
         color: "white",
         backgroundColor: grey[900],
      },
      adminChip: {
         color: "white",
         backgroundColor: blue[500],
      },
      superAdminChip: {
         color: "white",
         backgroundColor: purple[200],
      },
   }),
);

export const DivisionView: React.FC<any> = (): JSX.Element => {
   const classes = useStyles();
   const history = useHistory();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();
   const { state: drawerState } = useContext(DrawerContext);
   const { state: userState } = useContext(UserContext);
   const { state: companyState } = useContext(CompanyContext);
   const [division, setDivision] = useState<Divisions[]>([]);
   const [originalDivision, setOriginalDivision] = useState<Divisions[]>([]);
   const [selectedDivision, setSelectedDivision] = useState<Divisions>({
      _id: "",
      company_id: "",
      code: "",
      name: "",
      managers: [],
      active: false,
      prefix: "",
      counter: 0,
      addDate: new Date(),
      deleteDate: new Date(),
      invitationBody: "",
      invitationTopNote: "",
      emailTemplate: "",
      docuSignTemplateId: "",
      receiptFirstParagraph: "",
      receiptSecondParagraph: "",
      deniedFirstParagraph: "",
      deniedSecondParagraph: "",
      approvedFirstParagraph: "",
      approvedSecondParagraph: "",
      approvedBoldParagraph: "",
      contactApprovedBody: "",
      contactDeniedBody: "",
      duplicationNotification: "",
      contactDuplicationNotification: "",
      denialCodes: [],
      contacts: [],
      quickCreditAmount: 0,
   });
   const [deleteModal, setDeleteModal] = useState(false);
   const [divisionModal, setDivisionModal] = useState(false);
   const [loading, setLoading] = useState(true);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [active, setActive] = useState<"Active" | "Inactive" | "All">("Active");
   const [filter, setFilter] = useState<string>("");
   const [applicationCounts, setApplicationCounts] = useState<{ item1: string; item2: number }[]>([]);
   const activeOptions = ["All", "Active", "Inactive"];
   let themeCounter = 1;

   function breadcrumbOnClick(path: string) {
      if (path) {
         history.push(path);
      }
   }

   async function getDivision() {
      try {
         const responseData = await getDivisionList(true);

         if (responseData.success) {
            let filteredObjects = _.orderBy(
               _.filter(
                  responseData.data,
                  (x) =>
                     x.name.toLowerCase().includes(filter.toLowerCase()) ||
                     x.code.toLowerCase().includes(filter.toLowerCase()) ||
                     DateFormatter(x.addDate) === filter,
               ),
               ["name"],
               ["asc"],
            );

            if (active === "Active") {
               filteredObjects = _.filter(filteredObjects, (x) => x.active === true);
            } else if (active === "Inactive") {
               filteredObjects = _.filter(filteredObjects, (x) => x.active === false);
            }

            setDivision(filteredObjects);
            setOriginalDivision(responseData.data);
            setApplicationCounts(responseData.applicationCount);
         }
      } catch (err) {
         handleError(err);
      }
   }

   useEffect(() => {
      getDivision();
   }, []);

   useEffect(() => {
      if (division.length > 0) {
         setLoading(false);
      }
   }, [division]);

   async function handleDetailsClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         history.push("/divisions/details/" + division[index]._id);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleDeleteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         setSelectedDivision(division[index]);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   function getTheme() {
      try {
         if (themeCounter === 1) {
            themeCounter = 2;
            return "DivisionView-card-header1";
         }

         if (themeCounter === 2) {
            themeCounter = 3;
            return "DivisionView-card-header2";
         }

         if (themeCounter === 3) {
            themeCounter = 1;
            return "DivisionView-card-header3";
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function deleteDivision() {
      try {
         setDeleteModal(!deleteModal);

         const responseData = await removeDivision(selectedDivision._id ? selectedDivision._id : "");

         if (responseData.success) {
            openAlertMessage("Successfully deleted division.", "success");
            getDivision();
         } else {
            openAlertMessage(responseData.message, "warning");
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function onChangeFilter(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      try {
         let filteredObjects = _.orderBy(
            _.filter(
               originalDivision,
               (x) =>
                  x.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                  x.code.toLowerCase().includes(filter.toLowerCase()) ||
                  DateFormatter(x.addDate).includes(event.target.value),
            ),
            ["name"],
            ["asc"],
         );

         if (active === "Active") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === true);
         } else if (active === "Inactive") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === false);
         }

         //set the hook with the newly filtered data and it will render
         setDivision(filteredObjects);
         setFilter(event.target.value);
      } catch (err) {
         handleError(err);
      }
   }

   async function onChangeFilterActive(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      try {
         let filteredObjects = _.orderBy(
            _.filter(
               originalDivision,
               (x) =>
                  x.name.toLowerCase().includes(filter.toLowerCase()) ||
                  x.code.toLowerCase().includes(filter.toLowerCase()) ||
                  DateFormatter(x.addDate).includes(event.target.value),
            ),
            ["name"],
            ["asc"],
         );

         if (event.target.value === "Active") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === true);
         } else if (event.target.value === "Inactive") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === false);
         }

         //set the hook with the newly filtered data and it will render
         setDivision(filteredObjects);
         setActive(event.target.value === "Active" ? "Active" : event.target.value === "Inactive" ? "Inactive" : "All");
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <>
         <div className="DivisionView">
            <div className="DivisionView-root">
               {loading ? (
                  <>
                     <CheckAnimation />
                  </>
               ) : (
                  <>
                     <div className="DivisionView-header">
                        <div
                           className={
                              drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                           }
                        >
                           <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              style={{ height: "60px" }}
                           >
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={2}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <Breadcrumbs separator={<NavigateNextIcon />}>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/division")}
                                    >
                                       <Typography noWrap variant="h6" style={{ fontWeight: 700 }}>
                                          {"Divisions"}
                                       </Typography>
                                    </Link>
                                 </Breadcrumbs>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 6 : 4}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <TextField
                                    id="tf-search"
                                    placeholder="Search..."
                                    variant="outlined"
                                    name="search"
                                    size={"small"}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <SearchIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                    value={filter}
                                    onChange={(event) => onChangeFilter(event)}
                                 />
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 3 : 2}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "50px" }}
                              >
                                 <TextField
                                    select
                                    id="tf-active"
                                    variant="outlined"
                                    name="active"
                                    size="small"
                                    value={active}
                                    label={"Active"}
                                    onChange={(event) => onChangeFilterActive(event)}
                                 >
                                    {activeOptions.map((option) => (
                                       <MenuItem key={option} value={option}>
                                          {option}
                                       </MenuItem>
                                    ))}
                                 </TextField>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 1 : 4}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              ></Grid>
                           </Grid>
                        </div>
                     </div>
                     <div
                        className={
                           drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                        }
                     >
                        <Collapse in={alertMessage ? true : false}>
                           <Alert
                              variant="filled"
                              severity={alertType}
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       setAlertMessage("");
                                    }}
                                 >
                                    <CloseIcon />
                                 </IconButton>
                              }
                           >
                              {alertMessage}
                           </Alert>
                        </Collapse>
                        <div className="DivisionView-body">
                           <div
                              className={
                                 drawerState.DrawerOpen === true
                                    ? "DivisionView-layout-drawer-open"
                                    : "DivisionView-layout"
                              }
                           >
                              <Grid container direction={"column"} item xs={12}>
                                 <Grid
                                    container
                                    direction={"row"}
                                    item
                                    xs={12}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                 >
                                    <div
                                       style={{
                                          display:
                                             userState.CurrentUser.security.toUpperCase() === "SUPER ADMIN"
                                                ? "block"
                                                : "none",
                                       }}
                                    >
                                       <Card className="DivisionView-card-active">
                                          <CardHeader
                                             title={
                                                <Typography noWrap variant="h5">
                                                   Add New Division
                                                </Typography>
                                             }
                                          />
                                          <CardActions disableSpacing>
                                             <Grid
                                                container
                                                direction={"row"}
                                                item
                                                xs={12}
                                                justifyContent="center"
                                                style={{ height: "100%", width: "100%" }}
                                             >
                                                <Button
                                                   style={{ height: "210px", width: "250px" }}
                                                   onClick={() => setDivisionModal(true)}
                                                >
                                                   <AddIcon style={{ height: "210px", width: "250px" }} />
                                                </Button>
                                             </Grid>
                                          </CardActions>
                                       </Card>
                                    </div>
                                    {division.map((division, index) => {
                                       return (
                                          <Card
                                             key={index}
                                             className={
                                                division.active
                                                   ? "DivisionView-card-active"
                                                   : "DivisionView-card-inactive"
                                             }
                                          >
                                             <CardHeader
                                                className={getTheme()}
                                                action={
                                                   <Chip
                                                      label={division.active ? "Active" : "Inactive"}
                                                      style={{ marginTop: "0.75rem" }}
                                                      className={
                                                         division.active ? classes.activeChip : classes.inactiveChip
                                                      }
                                                      size="small"
                                                   />
                                                }
                                                title={
                                                   <Typography style={{ maxWidth: "230px" }} noWrap variant="h6">
                                                      {division.name}
                                                   </Typography>
                                                }
                                             />
                                             <Divider className={classes.divider} />
                                             <CardContent>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography noWrap>
                                                      <b>Company: </b>{" "}
                                                      {companyState.Companies.filter(
                                                         (x) => x._id === division.company_id,
                                                      ).map((x) => {
                                                         return x.code;
                                                      })}
                                                   </Typography>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography noWrap>
                                                      <b>Name: </b> {division.name}
                                                   </Typography>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography noWrap>
                                                      <b>Code: </b> {division.code}
                                                   </Typography>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography noWrap>
                                                      <b># of Completed Applications: </b>{" "}
                                                      {applicationCounts
                                                         .filter((x) => x.item1 === division._id)
                                                         .map((x) => {
                                                            return x.item2.toString();
                                                         })}
                                                   </Typography>
                                                </Grid>
                                             </CardContent>
                                             <CardActions disableSpacing>
                                                <Grid container direction={"row"} item xs={12}>
                                                   <Grid
                                                      container
                                                      item
                                                      xs={6}
                                                      alignItems="center"
                                                      justifyContent="flex-start"
                                                   ></Grid>
                                                   <Grid
                                                      container
                                                      item
                                                      xs={6}
                                                      alignItems="center"
                                                      justifyContent="flex-end"
                                                   >
                                                      <Tooltip title="Edit Division" arrow>
                                                         <IconButton
                                                            onClick={(event) => handleDetailsClick(event, index)}
                                                         >
                                                            <EditIcon color="primary" />
                                                         </IconButton>
                                                      </Tooltip>
                                                   </Grid>
                                                </Grid>
                                             </CardActions>
                                          </Card>
                                       );
                                    })}
                                 </Grid>
                              </Grid>
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
         <DivisionNew
            open={divisionModal}
            handleClose={() => {
               setDivisionModal(!divisionModal);
               getDivision();
            }}
            openAlertMessage={openAlertMessage}
         />
      </>
   );
};

export default DivisionView;
