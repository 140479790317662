import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import InputMask from "react-input-mask";
import { Applications } from "../../types/Applications";
import { countryList } from "../../utilities/CountryList";
import "./Apply.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         width: "100%",
      },
   }),
);

export const CustomerInfo: React.FC<any> = (props: {
   application: Applications;
   handleChange: (
      event:
         | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
         | MaterialUiPickersDate
         | React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
           }>,
      value:
         | "companyName"
         | "customerType"
         | "customerSubType"
         | "brokerBond"
         | "companyEstablishedDate"
         | "accountsPayableEmail"
         | "companyContact"
         | "companyEmail"
         | "companyPhone",
   ) => void;
   handleChangeDate: (event: MaterialUiPickersDate) => void;
   handleChangeAddress: (
      event:
         | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
         | React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
           }>,
      value: "address1" | "address2" | "city" | "state" | "zip" | "country",
   ) => void;
   errorHighlight: {
      companyName: { error: boolean; message: string };
      customerType: { error: boolean; message: string };
      customerSubType: { error: boolean; message: string };
      brokerBond: { error: boolean; message: string };
      companyEstablishedDate: { error: boolean; message: string };
      accountsPayableEmail: { error: boolean; message: string };
      companyContact: { error: boolean; message: string };
      address1: { error: boolean; message: string };
      address2: { error: boolean; message: string };
      city: { error: boolean; message: string };
      state: { error: boolean; message: string };
      zip: { error: boolean; message: string };
      email: { error: boolean; message: string };
      phoneNumber: { error: boolean; message: string };
      country: { error: boolean; message: string };
   };
}): JSX.Element => {
   const classes = useStyles();

   return (
      <>
         <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
               <h1>Company Information</h1>
            </Grid>
         </Grid>
         <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
               <TextField
                  required={true}
                  id="tf-companyName"
                  label="Name of Company Requesting Credit"
                  variant="outlined"
                  onChange={(event) => props.handleChange(event, "companyName")}
                  value={props.application.companyName}
                  helperText={props.errorHighlight.companyName.error ? props.errorHighlight.companyName.message : " "}
                  error={props.errorHighlight.companyName.error ? true : false}
                  inputProps={{ maxLength: 100 }}
               />
            </Grid>
            <Grid item xs={6}>
               <TextField
                  required={true}
                  id="tf-companyContact"
                  label="Company Contact"
                  variant="outlined"
                  onChange={(event) => props.handleChange(event, "companyContact")}
                  value={props.application.companyContact}
                  helperText={
                     props.errorHighlight.companyContact.error ? props.errorHighlight.companyContact.message : " "
                  }
                  error={props.errorHighlight.companyContact.error ? true : false}
                  inputProps={{ maxLength: 100 }}
               />
            </Grid>
         </Grid>
         <Grid container xs={12} direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                     required={true}
                     disableToolbar
                     variant="inline"
                     inputVariant="outlined"
                     format="MM/dd/yyyy"
                     id="tf-companyEstablishedDate"
                     label="Company Established Date"
                     onChange={(event) => props.handleChangeDate(event)}
                     value={props.application.companyEstablishedDate}
                     helperText={
                        props.errorHighlight.companyEstablishedDate.error
                           ? props.errorHighlight.companyEstablishedDate.message
                           : " "
                     }
                     error={props.errorHighlight.companyEstablishedDate.error ? true : false}
                     KeyboardButtonProps={{
                        "aria-label": "change date",
                     }}
                  />
               </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
               <TextField
                  required={true}
                  id="tf-accountsPayableEmail"
                  label="Accounts Payable Email"
                  variant="outlined"
                  onChange={(event) => props.handleChange(event, "accountsPayableEmail")}
                  value={props.application.accountsPayableEmail}
                  helperText={
                     props.errorHighlight.accountsPayableEmail.error
                        ? props.errorHighlight.accountsPayableEmail.message
                        : " "
                  }
                  error={props.errorHighlight.accountsPayableEmail.error ? true : false}
               />
            </Grid>
         </Grid>
         <Grid container xs={12} direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
               <TextField
                  required={true}
                  id="tf-address1"
                  label="Address"
                  variant="outlined"
                  onChange={(event) => props.handleChangeAddress(event, "address1")}
                  value={props.application.address.address1}
                  helperText={props.errorHighlight.address1.error ? props.errorHighlight.address1.message : " "}
                  error={props.errorHighlight.address1.error ? true : false}
                  inputProps={{ maxLength: 100 }}
               />
            </Grid>
            <Grid item xs={6}>
               <TextField
                  id="tf-address2"
                  label="Address 2"
                  variant="outlined"
                  onChange={(event) => props.handleChangeAddress(event, "address2")}
                  value={props.application.address.address2}
                  helperText={props.errorHighlight.address2.error ? props.errorHighlight.address2.message : " "}
                  error={props.errorHighlight.address2.error ? true : false}
                  inputProps={{ maxLength: 100 }}
               />
            </Grid>
         </Grid>
         <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={4}>
               <TextField
                  required={true}
                  id="tf-city"
                  label="City"
                  variant="outlined"
                  onChange={(event) => props.handleChangeAddress(event, "city")}
                  value={props.application.address.city}
                  helperText={props.errorHighlight.city.error ? props.errorHighlight.city.message : " "}
                  error={props.errorHighlight.city.error ? true : false}
                  inputProps={{ maxLength: 100 }}
               />
            </Grid>
            <Grid item>
               <TextField
                  required={true}
                  id="tf-state"
                  label="State"
                  variant="outlined"
                  onChange={(event) => props.handleChangeAddress(event, "state")}
                  value={props.application.address.state}
                  helperText={props.errorHighlight.state.error ? props.errorHighlight.state.message : " "}
                  error={props.errorHighlight.state.error ? true : false}
                  inputProps={{ maxLength: 2 }}
               />
            </Grid>
            <Grid item xs={3}>
               <TextField
                  required={true}
                  id="tf-zip"
                  label="Zip Code"
                  variant="outlined"
                  onChange={(event) => props.handleChangeAddress(event, "zip")}
                  value={props.application.address.zip}
                  helperText={props.errorHighlight.zip.error ? props.errorHighlight.zip.message : " "}
                  error={props.errorHighlight.zip.error ? true : false}
                  inputProps={{ maxLength: 10 }}
               />
            </Grid>
            <Grid item xs={4}>
               <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                     id="lbl-country"
                     style={{ color: props.errorHighlight.country.error ? "red" : "inherit" }}
                  >
                     Country *
                  </InputLabel>
                  <Select
                     required={true}
                     labelId="lbl-country"
                     id="tf-country"
                     className="country"
                     variant="outlined"
                     onChange={(event) => props.handleChangeAddress(event, "country")}
                     defaultValue={""}
                     value={props.application.address.country}
                     label="Country"
                     error={props.errorHighlight.country.error ? true : false}
                  >
                     {countryList.map((country, index) => {
                        return (
                           <MenuItem key={index} value={country.name}>
                              {country.name}
                           </MenuItem>
                        );
                     })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                     {props.errorHighlight.country.error ? props.errorHighlight.country.message : " "}
                  </FormHelperText>
               </FormControl>
            </Grid>
         </Grid>
         <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
               <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                     id="lbl-customerType"
                     style={{ color: props.errorHighlight.customerType.error ? "red" : "inherit" }}
                  >
                     Customer Type *
                  </InputLabel>
                  <Select
                     id="tf-customerType"
                     labelId="lbl-customerType"
                     variant="outlined"
                     value={props.application.customerType}
                     onChange={(event) => props.handleChange(event, "customerType")}
                     defaultValue={""}
                  >
                     <MenuItem value="BCO">BCO</MenuItem>
                     <MenuItem value="SSL">SSL</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                     {props.errorHighlight.customerType.error ? props.errorHighlight.customerType.message : null}
                  </FormHelperText>
               </FormControl>
            </Grid>
            <Grid item>
               <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                     id="lbl-customerSubType"
                     style={{ color: props.errorHighlight.customerSubType.error ? "red" : "inherit" }}
                  >
                     Customer Sub-Type *
                  </InputLabel>
                  <Select
                     id="tf-customerSubType"
                     labelId="lbl-customerSubType"
                     variant="outlined"
                     value={props.application.customerSubType}
                     onChange={(event) => props.handleChange(event, "customerSubType")}
                  >
                     <MenuItem value="3Pl">3Pl</MenuItem>
                     <MenuItem value="Broker">Broker</MenuItem>
                     <MenuItem value="Freight Forwarder">Freight Forwarder</MenuItem>
                     <MenuItem value="IMC">IMC</MenuItem>
                     <MenuItem value="NVO">NVO</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                     {props.errorHighlight.customerSubType.error ? props.errorHighlight.customerSubType.message : null}
                  </FormHelperText>
               </FormControl>
            </Grid>
            <Grid item>
               <TextField
                  id="tf-companyWebsite"
                  label="Broker's Bond"
                  variant="outlined"
                  value={props.application.brokerBond}
                  onChange={(event) => props.handleChange(event, "brokerBond")}
                  disabled={props.application.customerSubType !== "Broker"}
                  style={{ visibility: props.application.customerSubType === "Broker" ? "visible" : "hidden" }}
                  helperText={
                     props.errorHighlight.brokerBond.error
                        ? props.errorHighlight.brokerBond.message
                        : props.application.customerSubType !== "Broker"
                        ? " "
                        : null
                  }
                  error={props.errorHighlight.brokerBond.error ? true : false}
               />
            </Grid>
         </Grid>
         <Grid container xs={12} direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
               <TextField
                  required={true}
                  id="tf-companyEmail"
                  label="Email"
                  variant="outlined"
                  onChange={(event) => props.handleChange(event, "companyEmail")}
                  value={props.application.companyEmail}
                  helperText={props.errorHighlight.email.error ? props.errorHighlight.email.message : " "}
                  error={props.errorHighlight.email.error ? true : false}
               />
            </Grid>
            <Grid item xs={6}>
               <InputMask
                  mask="999-999-9999"
                  value={props.application.companyPhone}
                  onChange={(event) => props.handleChange(event, "companyPhone")}
               >
                  {() => (
                     <TextField
                        required={true}
                        id="tf-companyPhone"
                        label="Phone #"
                        variant="outlined"
                        error={props.errorHighlight.phoneNumber.error ? true : false}
                        inputProps={{
                           autocomplete: "tel-national",
                           form: {
                              autocomplete: "on",
                           },
                        }}
                     />
                  )}
               </InputMask>
               <FormHelperText style={{ color: "red" }}>
                  {props.errorHighlight.phoneNumber.error ? props.errorHighlight.phoneNumber.message : " "}
               </FormHelperText>
            </Grid>
         </Grid>
      </>
   );
};

export default CustomerInfo;
