import { Collapse } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { login as loginApi } from "../../api/UserApi";
import { Users } from "../../types/Users";
import * as CookieTools from "../../utilities/CookieTools";
import "./Login.css";

export const Login: React.FC<any> = ({ match }: RouteComponentProps<{ loggedOut: string }>): JSX.Element => {
   const handleError = useErrorHandler();
   const history = useHistory();
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [showLoggedOut, setShowLoggedOut] = useState<boolean>(true);
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");

   const [user, setUser] = useState<Users>({
      _id: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      active: false,
      companies: [],
      divisions: [],
      security: "",
      invite: "",
      maximumApprovalAmount: 0,
      department: "",
      addDate: new Date(),
      deleteDate: null,
      homePage: {
         page: "",
         view: "",
      },
   });

   const loginUser = async () => {
      try {
         if (!user.email) {
            openAlertMessage("Please enter your email address.", "warning");
            return;
         }

         if (!user.password) {
            openAlertMessage("Please enter your password.", "warning");
            return;
         }

         const responseData = await loginApi(user);

         if (responseData.success === false) {
            openAlertMessage(responseData.message, "warning");
            return;
         } else {
            CookieTools.deleteCookie("selected-company");
            CookieTools.deleteCookie("selected-division");
            history.push("/");
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
      }
   };

   function handleEnterKey(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) {
      if (event.key === "Enter") {
         loginUser();
      }
   }

   const onChange = async (
      key: "email" | "password",
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
   ) => {
      try {
         const newUser = _.cloneDeep(user);
         newUser[key] = event?.target?.value ?? "";
         setUser(newUser);
      } catch (err) {
         handleError(err);
      }
   };

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <>
         <Collapse in={match.params.loggedOut && showLoggedOut ? true : false}>
            <Alert
               variant="filled"
               severity={"warning"}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setShowLoggedOut(false);
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {"You have been logged out due to inactivity."}
            </Alert>
         </Collapse>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <div className={"grid-container-login"}>
            <div className="blurred-boxLogin grid-item-content-login">
               <div className="login">
                  <h3>For the best experience, use Chrome, Edge or Brave browsers.</h3>
                  <h1>Login</h1>
                  <FormControl>
                     <Input
                        className="inputTextbox"
                        name="email"
                        type="text"
                        autoFocus={true}
                        placeholder="Email"
                        value={user?.email}
                        onChange={(event) => onChange("email", event)}
                        onKeyDown={(event) => handleEnterKey(event)}
                     />
                  </FormControl>
                  <FormControl>
                     <Input
                        className="inputTextbox"
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={user?.password}
                        onChange={(event) => onChange("password", event)}
                        onKeyDown={(event) => handleEnterKey(event)}
                     />
                  </FormControl>
                  <div id="forgotpassLink" onClick={() => history.push("/forgotPassword")}>
                     <a>Forgot Password?</a>
                  </div>
                  <Button id="loginButton" variant="contained" color="primary" onClick={() => loginUser()}>
                     Login
                  </Button>
               </div>
            </div>
         </div>
      </>
   );
};

export default Login;
