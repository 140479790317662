import { Collapse } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { registerUser, validateInvite } from "../../api/UserApi";
import CheckAnimation from "../../styles/CheckAnimation";
import * as CookieTools from "../../utilities/CookieTools";
import { ExpiredContainerPort as ExpiredContainerPortPage } from "./Expired/ExpiredContainerPort";
import "./Register.css";

export const Register: React.FC<any> = ({
   match,
}: RouteComponentProps<{ _id: string; token: string }>): JSX.Element => {
   const history = useHistory();
   const handleError = useErrorHandler();

   const id = match.params._id;
   const token = match.params.token;
   const user = {
      _id: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      active: false,
      companies: [],
      divisions: [],
      security: "",
      invite: "",
      maximumApprovalAmount: 0,
      department: "",
      addDate: new Date(),
      deleteDate: null,
      homePage: {
         page: "",
         view: "",
      },
   };

   const [state, setState] = React.useState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      ConfirmPassword: "",
      error: null,
   });

   const [loading, setLoading] = React.useState(true);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [tokenExpired, setExpired] = React.useState(false);

   const [policy, setPolicy] = React.useState({
      policy1: false,
      policy2: false,
      policy3: false,
      policy4: false,
      policy5: false,
      policy6: false,
   });

   useEffect(() => {
      validateUser(id);
   }, []);

   async function validateUser(id: string) {
      try {
         const responseData = await validateInvite(id);

         if (!responseData.success) {
            setExpired(true);
         }

         setLoading(false);
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }
   function checkPolicy(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) {
      try {
         const newState = { ...state };
         const newPolicy = { ...policy };
         const lowerCase = new RegExp(/[a-z]/);
         const upperCase = new RegExp(/[A-Z]/);
         const specialChar = new RegExp(/[!@#\$%\^\&*\)\(+=._-]/);
         const minChar = new RegExp(/.{8,}/);
         const minNum = new RegExp(/(?=.*?[0-9])/);

         if (value === "firstname") {
            if (event.target.value != "") {
               newPolicy.policy5 = true;
            } else {
               newPolicy.policy5 = false;
            }
         }

         if (value === "lastname") {
            if (event.target.value != "") {
               newPolicy.policy6 = true;
            } else {
               newPolicy.policy6 = false;
            }
         }

         if (value === "password") {
            if (event.target.value.match(lowerCase) && event.target.value.match(upperCase)) {
               newPolicy.policy1 = true;
            } else {
               newPolicy.policy1 = false;
            }
            if (event.target.value.match(minNum)) {
               newPolicy.policy2 = true;
            } else {
               newPolicy.policy2 = false;
            }
            if (event.target.value.match(specialChar)) {
               newPolicy.policy3 = true;
            } else {
               newPolicy.policy3 = false;
            }
            if (event.target.value.match(minChar)) {
               newPolicy.policy4 = true;
            } else {
               newPolicy.policy4 = false;
            }
            newState.password = event.target.value;
         }
         setPolicy(newPolicy);
         setState(newState);
      } catch (err) {
         //throw error
      }
   }

   async function onSubmit() {
      if (state.password !== state.ConfirmPassword) {
         setState({
            ...state,
         });

         openAlertMessage("Passwords must match.", "warning");
         return;
      }

      if (!policy.policy5) {
         openAlertMessage("First name is required.", "warning");
         return;
      }

      if (!policy.policy6) {
         openAlertMessage("Last name is required.", "warning");
         return;
      }

      const newUser = { ...user };
      newUser.active = true;
      newUser.firstName = state.firstName;
      newUser.lastName = state.lastName;
      newUser.password = state.password;
      newUser.email = state.email;
      newUser.invite = token;
      newUser._id = id;
      const newAlert = { ...alert };
      const responseData = await registerUser(newUser);

      if (responseData.success === false) {
         if (responseData.message) {
            openAlertMessage(responseData.message, "warning");
            return;
         }

         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
         return;
      } else {
         CookieTools.deleteCookie("selected-company");
         CookieTools.deleteCookie("selected-division");
         history.push("/");
      }
   }

   function onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) {
      const newState = { ...state };

      try {
         if (value === "firstname") {
            newState.firstName = event.target.value;
            checkPolicy(event, "firstname");
         }
         if (value === "lastname") {
            newState.lastName = event.target.value;
            checkPolicy(event, "lastname");
         }
         if (value === "confirmpassword") {
            newState.ConfirmPassword = event.target.value;
         }
         setState(newState);
      } catch (err) {
         //throw error
      }
   }

   return (
      <>
         {loading ? (
            <>
               <CheckAnimation />
            </>
         ) : tokenExpired ? (
            <>
               <ExpiredContainerPortPage />
            </>
         ) : (
            <>
               <Collapse in={alertMessage ? true : false}>
                  <Alert
                     variant="filled"
                     severity={alertType}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                              setAlertMessage("");
                           }}
                        >
                           <CloseIcon />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
               <div className={"grid-container-registration"}>
                  <div className="blurred-box-registration grid-item-content-registration">
                     <div className="register">
                        <h1>Register</h1>
                        <FormControl>
                           <Input
                              type="text"
                              onChange={(event) => onChange(event, "firstname")}
                              value={state.firstName}
                              className="inputTextbox-registration"
                              placeholder="First Name"
                           />
                        </FormControl>
                        <FormControl>
                           <Input
                              type="text"
                              onChange={(event) => onChange(event, "lastname")}
                              value={state.lastName}
                              className="inputTextbox-registration"
                              placeholder="Last Name"
                           />
                        </FormControl>
                        <FormControl>
                           <Input
                              type="password"
                              onChange={(event) => checkPolicy(event, "password")}
                              value={state.password}
                              className="inputTextbox-registration"
                              placeholder="Password"
                           />
                        </FormControl>
                        <FormControl>
                           <Input
                              type="password"
                              onChange={(event) => onChange(event, "confirmpassword")}
                              value={state.ConfirmPassword}
                              className="inputTextbox-registration"
                              placeholder="Confirm Password"
                           />
                        </FormControl>
                        {policy.policy1 &&
                        policy.policy2 &&
                        policy.policy3 &&
                        policy.policy4 &&
                        policy.policy5 &&
                        policy.policy6 ? (
                           <Button
                              id="registerButton"
                              className="registerButton"
                              variant="contained"
                              color="primary"
                              onClick={() => onSubmit()}
                           >
                              Register
                           </Button>
                        ) : (
                           <Button id="registerButton" disabled={true} variant="contained" color="primary">
                              Register
                           </Button>
                        )}
                        <div style={{ marginTop: "2rem" }}>
                           <div
                              style={{
                                 marginLeft: "1rem",
                                 marginBottom: "0.50rem",
                                 float: "left",
                              }}
                           >
                              {"Password must include: "}
                           </div>
                           <List>
                              <ListItem>
                                 <div
                                    style={{
                                       display: "inline-flex",
                                    }}
                                 >
                                    <div
                                       style={{
                                          marginRight: "0.25rem",
                                       }}
                                    >
                                       {policy.policy1 ? (
                                          <DoneIcon
                                             style={{
                                                color: "green",
                                                fontSize: "large",
                                             }}
                                          />
                                       ) : (
                                          <ClearIcon
                                             style={{
                                                color: "red",
                                                fontSize: "large",
                                             }}
                                          />
                                       )}
                                    </div>
                                    <div>{"Lowercase and uppercase letters"}</div>
                                 </div>
                                 <br></br>
                              </ListItem>
                              <ListItem>
                                 <div style={{ display: "inline-flex" }}>
                                    <div style={{ marginRight: "0.25rem" }}>
                                       {policy.policy2 ? (
                                          <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                       ) : (
                                          <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                       )}
                                    </div>
                                    <div>{"Contain 1 number"}</div>
                                 </div>{" "}
                                 <br></br>
                              </ListItem>
                              <ListItem>
                                 <div style={{ display: "inline-flex" }}>
                                    <div style={{ marginRight: "0.25rem" }}>
                                       {policy.policy3 ? (
                                          <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                       ) : (
                                          <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                       )}
                                    </div>
                                    <div>{"Contain 1 special character (!,@,$)"}</div>
                                 </div>{" "}
                                 <br></br>
                              </ListItem>
                              <ListItem>
                                 <div style={{ display: "inline-flex" }}>
                                    {" "}
                                    <div style={{ marginRight: "0.25rem" }}>
                                       {policy.policy4 ? (
                                          <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                       ) : (
                                          <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                       )}
                                    </div>
                                    <div>{"8 or more characters"}</div>
                                 </div>
                              </ListItem>
                           </List>
                        </div>
                     </div>
                  </div>
               </div>
            </>
         )}
      </>
   );
};

export default Register;
