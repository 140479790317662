import { Button, createStyles, InputAdornment, makeStyles, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import React, { useState } from "react";
import { Applications } from "../../types/Applications";
import { DateFormatter } from "../../utilities/DateFormatter";
import AdoptSign from "./AdoptSign";
import "./Apply.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         width: "100%",
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
   }),
);

export const SignComplete: React.FC<any> = (props: {
   application: Applications;
   setApplication: (value: React.SetStateAction<Applications>) => void;
   handleChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: "signature" | "signatureDate" | "printName" | "signatureTitle",
   ) => void;
   errorHighlight: {
      signature: { error: boolean; message: string };
      signatureTitle: { error: boolean; message: string };
   };
   division: string;
}): JSX.Element => {
   const classes = useStyles();
   const [adoptAndSignModal, setAdoptAndSignModal] = useState(false);

   return (
      <>
         <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
               <h1>Sign and Complete</h1>
            </Grid>
            <Grid item xs={12} style={{ textAlignLast: "center", fontSize: "14px" }}>
               <p>
                  In the event transportation services are provided, applicant agrees to accept responsibility for all
                  accessorial charges as described in the long haul drayage guides.
                  <br /> Applicant certifies that all information on this form is correct. In consideration to extending
                  credit, customer agrees to make payment within Net 30-day credit terms.
                  <br /> Please note that failure to adhere to the Net 30-day credit terms can result in loss of credit
                  with Container Port Group, Inc.
               </p>
               <br /> <br />
               <h3>*** Credit Application must be signed by a member of Company Management. ***</h3>
            </Grid>
         </Grid>
         <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
               <TextField
                  required={true}
                  id="tf-signature"
                  label="Signature"
                  variant="outlined"
                  onChange={(event) => event.preventDefault()}
                  value={props.application.signature}
                  InputProps={{
                     style: { fontFamily: '"Brush Script MT"', fontSize: "22px" },
                     endAdornment: !props.application.signature ? (
                        <InputAdornment position="end">
                           <Button
                              style={{
                                 backgroundColor: "#FFC820",
                                 color: "red",
                                 marginRight: "-.90rem",
                                 height: "3.5rem",
                              }}
                              onClick={() => setAdoptAndSignModal(true)}
                              variant="contained"
                              classes={{
                                 label: classes.label,
                              }}
                           >
                              <CreateIcon style={{ backgroundColor: "#FFC820", color: "red" }} />
                              {"Adopt Signature"}
                           </Button>
                        </InputAdornment>
                     ) : null,
                  }}
                  helperText={props.errorHighlight.signature.error ? props.errorHighlight.signature.message : " "}
                  error={props.errorHighlight.signature.error ? true : false}
               />
            </Grid>
            <Grid item xs={6}>
               <TextField
                  required={true}
                  disabled={true}
                  id="tf-signatureDate"
                  label="Date"
                  variant="outlined"
                  value={
                     props.application.signatureDate
                        ? DateFormatter(props.application.signatureDate)
                        : DateFormatter(Date.now())
                  }
                  InputProps={{
                     style: { fontFamily: '"Brush Script MT"' },
                  }}
                  helperText={" "}
               />
            </Grid>
         </Grid>
         <Grid container direction="row" alignItems="center">
            <Grid item xs={6}>
               <TextField
                  disabled={true}
                  required={true}
                  id="tf-printName"
                  label="Printed Name"
                  variant="outlined"
                  value={props.application.signature}
                  helperText={" "}
               />
            </Grid>
            <Grid item xs={6}>
               <TextField
                  required={true}
                  id="tf-title"
                  label="Title"
                  variant="outlined"
                  onChange={(event) => props.handleChange(event, "signatureTitle")}
                  value={props.application.signatureTitle}
                  helperText={
                     props.errorHighlight.signatureTitle.error ? props.errorHighlight.signatureTitle.message : " "
                  }
                  error={props.errorHighlight.signatureTitle.error ? true : false}
                  inputProps={{ maxLength: 100 }}
               />
            </Grid>
         </Grid>
         <AdoptSign
            open={adoptAndSignModal}
            handleClose={() => {
               setAdoptAndSignModal(!adoptAndSignModal);
            }}
            application={props.application}
            setApplication={props.setApplication}
            division={props.division}
         />
      </>
   );
};

export default SignComplete;
