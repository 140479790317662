import { makeStyles, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { setApplication as setApplicationApi } from "../../api/ApplicationApi";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { UserContext } from "../../stores/Users/Context";
import { Applications } from "../../types/Applications";
import { Contacts } from "../../types/Contacts";
import { DenialCodes } from "../../types/DenialCodes";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   formControl: {
      margin: theme.spacing(1),
   },
   saveButton: {
      color: "white",
      backgroundColor: green[500],
      "&:hover": {
         backgroundColor: green[600],
      },
   },
   buttonProgress: {
      color: green[500],
   },
}));

export const DenialDialog: React.FC<any> = (props: {
   open: boolean;
   application: Applications;
   setApplication: (value: React.SetStateAction<Applications>) => void;
   handleClose: () => void;
   onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: "notes") => void;
   onSelectChange: (
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) => void;
   denialCodes: DenialCodes[];
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);

   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);

   //Loading Use Effect.
   useEffect(() => {
      if (props.application.division_id) {
         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id.toString() === props.application.division_id.toString(),
         );

         if (divisionAssigned.length > 0) {
            setContactList(
               _.orderBy(
                  _.filter(divisionAssigned[0].contacts, (x) => x.active === true),
                  [(contact) => contact.name.toLowerCase()],
                  ["asc"],
               ),
            );
         }
      } else {
         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id.toString() === props.application.company_id.toString(),
         );

         if (companyAssigned.length > 0) {
            setContactList(
               _.orderBy(
                  _.filter(companyAssigned[0].contacts, (x) => x.active === true),
                  [(contact) => contact.name.toLowerCase()],
                  ["asc"],
               ),
            );
         }
      }
   }, [props.open]);

   //Contact list Use Effect.
   useEffect(() => {
      const contactAssigned = _.filter(contactList, (x) =>
         props.application.contacts.includes(props.application.contacts ? x._id : props.application.contacts),
      );

      setSelectedContactsList(contactAssigned);
   }, [contactList]);

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function saveApplication() {
      try {
         if (props.application.status === "APPROVED") {
            openAlertMessage("You cannot edit because the application has already been approved!", "warning");
            return;
         }

         if (props.application.status === "DENIED") {
            openAlertMessage("You cannot edit because the application has already been denied!", "warning");
            return;
         }

         if (!props.application.code) {
            openAlertMessage("You must select a denial code!", "warning");
            return;
         }

         if (props.application.contacts.length === 0) {
            openAlertMessage("You must select at least one contact!", "warning");
            return;
         }

         setButtonClicked(true);

         const applicationObj = { ...props.application };
         applicationObj.approvedBy_id = userState.CurrentUser._id;
         applicationObj.status = "DENIED";

         const responseData = await setApplicationApi(applicationObj, "DENIED");

         if (responseData.success) {
            openAlertMessage("Successfully denied application.", "success");
            setButtonClicked(false);
            window.location.replace("/");
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "warning");
         setButtonClicked(false);
      }
   }

   async function handleSelectContactChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const applicationObj = { ...props.application };
         const contacts = event.target.value ? (event.target.value as string[]) : [];
         applicationObj.contacts = event.target.value ? (event.target.value as string[]) : [];

         props.setApplication(applicationObj);

         const contactAssigned = _.filter(contactList, (x) => contacts.includes(contacts ? x._id : contacts));

         setSelectedContactsList(contactAssigned);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChipDelete(_id: string) {
      try {
         setSelectedContactsList(selectedContactsList.filter((x) => x._id !== _id));

         const applicationObj = { ...props.application };
         applicationObj.contacts = applicationObj.contacts.filter((x) => x !== _id);

         props.setApplication(applicationObj);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         fullScreen={mobileScreen}
         fullWidth
         maxWidth={"xs"}
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            Application Denial
         </DialogTitle>
         <DialogContent>
            <Collapse in={alertMessage ? true : false}>
               <Alert
                  variant="filled"
                  severity={alertType}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           setAlertMessage("");
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <FormControl variant="outlined" style={{ width: "100%" }}>
               <InputLabel id="demo-simple-select-outlined-label">Denial Code</InputLabel>
               <Select
                  id="select-denialCodes"
                  labelId="demo-simple-select-outlined-label"
                  label="Denial Code"
                  defaultValue=""
                  value={props.application.code}
                  onChange={(event) => props.onSelectChange(event)}
               >
                  {props.denialCodes?.map((denialCode) => {
                     return (
                        <MenuItem key={denialCode._id} value={denialCode.code}>
                           {denialCode.code}
                        </MenuItem>
                     );
                  })}
               </Select>
            </FormControl>
            <TextField
               id="tf-applicationNotes"
               label="Application Notes"
               name="applicationNotes"
               multiline
               minRows={15}
               maxRows={15}
               onChange={(event) => props.onChange(event, "notes")}
               value={props.application.notes}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
            />
            <FormControl variant="outlined" style={{ marginTop: "0.75rem", width: "100%" }}>
               <InputLabel>Contact</InputLabel>
               <Select
                  id="select-contact"
                  label="Contact"
                  defaultValue=""
                  multiple
                  MenuProps={{
                     getContentAnchorEl: null,
                     transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                     },
                  }}
                  value={props.application.contacts}
                  onChange={(event) => handleSelectContactChange(event)}
                  disabled={contactList.length <= 0}
               >
                  {contactList.map((contact) => {
                     return (
                        <MenuItem key={contact._id} value={contact._id}>
                           {contact.name}
                        </MenuItem>
                     );
                  })}
               </Select>
            </FormControl>
            {selectedContactsList.map((contact) => {
               return (
                  <Chip
                     key={contact._id}
                     label={contact.name}
                     size="small"
                     color="primary"
                     onDelete={() => handleChipDelete(contact._id)}
                     style={{ marginTop: ".5rem" }}
                  />
               );
            })}
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={saveApplication}
                  variant="contained"
                  color="primary"
                  classes={{
                     root: classes.saveButton,
                     label: classes.label,
                  }}
                  disabled={buttonClicked}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <SaveIcon />}
                  Save
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default DenialDialog;
