import { Collapse, createStyles, FormControlLabel, makeStyles, Paper, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import PageviewIcon from "@material-ui/icons/Pageview";
import Alert from "@material-ui/lab/Alert";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { getInviteApplicationPdf, setApplicationReferral } from "../../api/ApplicationApi";
import { Applications } from "../../types/Applications";
import "./Apply.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         width: "100%",
      },
      submitButton: {
         color: "white",
         backgroundColor: "#48bb78",
         "&:disabled": {
            color: "#000000",
         },
         "&:hover": {
            backgroundColor: green[600],
         },
      },
      buttonProgress: {
         color: green[500],
      },
   }),
);

export const SignComplete: React.FC<any> = (props: {
   application: Applications;
   setApplication: React.Dispatch<React.SetStateAction<Applications>>;
   activeStep: number;
   handleCheckChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      value: "Referral" | "Advertisement" | "Trade Show/Conference" | "Social Media" | "Website" | "Salesperson",
   ) => void;
   division: string;
}): JSX.Element => {
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [downloading, setDownloading] = useState<boolean>(false);
   const [viewing, setViewing] = useState<boolean>(false);

   async function submitReferralClicked() {
      const responseData = await setApplicationReferral(props.application);

      if (!responseData.success) {
         alert("Data was unable to save successfully. Please refresh the page and try again.");
         return;
      }
   }

   async function handleViewClick() {
      try {
         setViewing(true);

         const responseData = await getInviteApplicationPdf(props.application._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob));
            });
         } else {
            if (responseData.message) {
               openAlertMessage(responseData.message, "error");
            } else {
               openAlertMessage(
                  "Error loading PDF application. Please contact your administrator for assistance!",
                  "error",
               );
            }
         }

         setViewing(false);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleDownloadClick() {
      try {
         setDownloading(true);

         const responseData = await getInviteApplicationPdf(props.application._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               saveAs(blob, `Application # ${props.application.applicationNumber}.pdf`);
            });
         } else {
            if (responseData.message) {
               openAlertMessage(responseData.message, "error");
            } else {
               openAlertMessage(
                  "Error loading PDF application. Please contact your administrator for assistance!",
                  "error",
               );
            }
         }

         setDownloading(false);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   const classes = useStyles();

   return (
      <>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <Grid container xs={12} direction="row" style={{ height: "10rem" }}>
            <Zoom in={props.activeStep === 5 ? true : false} style={{ transitionDelay: "500ms" }} timeout={500}>
               <Grid container item xs={12} alignItems="center" style={{ textAlignLast: "center" }}>
                  <CheckCircleIcon style={{ fontSize: "10rem", color: "#48bb78" }}></CheckCircleIcon>
               </Grid>
            </Zoom>
         </Grid>
         <Grid container xs={12} direction="row" style={{ height: "4rem", textAlignLast: "center" }}>
            <Zoom in={props.activeStep === 5 ? true : false} style={{ transitionDelay: "750ms" }} timeout={500}>
               <Grid container item xs={12} alignItems="center" style={{ fontSize: "32px", fontWeight: 600 }}>
                  <p>Application Submitted!</p>
               </Grid>
            </Zoom>
         </Grid>
         <Grid container xs={12} direction="row" style={{ height: "4rem", textAlignLast: "center" }}>
            <Zoom in={props.activeStep === 5 ? true : false} style={{ transitionDelay: "750ms" }} timeout={500}>
               <Grid container item xs={12} alignItems="center" style={{ fontSize: "32px", fontWeight: 600 }}>
                  <p>Application #: {props.application.applicationNumber}</p>
               </Grid>
            </Zoom>
         </Grid>
         <Grid container xs={12} direction="row" style={{ height: "3rem", textAlignLast: "center" }}>
            <Zoom in={props.activeStep === 5 ? true : false} style={{ transitionDelay: "750ms" }} timeout={500}>
               <Grid container item xs={12} alignItems="center" style={{ fontSize: "18px", fontWeight: 600 }}>
                  <p>A PDF version of your credit application has been emailed to you.</p>
               </Grid>
            </Zoom>
         </Grid>
         <Grid container xs={12} direction="row" alignItems="center" justifyContent="center">
            <Zoom in={props.activeStep === 5 ? true : false} style={{ transitionDelay: "1000ms" }} timeout={500}>
               <Grid container item xs={4} direction="column">
                  <h2 style={{ fontSize: "20px", textAlign: "center" }}>How did you hear about us?</h2>
                  <Paper
                     variant="outlined"
                     style={{
                        minHeight: "160px",
                        borderRadius: "2rem",
                        borderColor: "#000000",
                     }}
                  >
                     <FormGroup>
                        <Grid container direction="row">
                           <Grid
                              container
                              item
                              xs={6}
                              direction="column"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              style={{ marginLeft: "2.5rem" }}
                           >
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={props.application.referral.indexOf("Referral") > -1 ? true : false}
                                       color="primary"
                                       value={"Referral"}
                                       onChange={(event) => props.handleCheckChange(event, "Referral")}
                                       name="Referral"
                                       disableRipple
                                    />
                                 }
                                 label="Referral"
                              />

                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={props.application.referral.indexOf("Advertisement") > -1 ? true : false}
                                       color="primary"
                                       value={"Advertisement"}
                                       onChange={(event) => props.handleCheckChange(event, "Advertisement")}
                                       name="Advertisement"
                                       disableRipple
                                    />
                                 }
                                 label="Advertisement"
                              />
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={
                                          props.application.referral.indexOf("Trade Show/Conference") > -1
                                             ? true
                                             : false
                                       }
                                       color="primary"
                                       value={"Trade Show/Conference"}
                                       onChange={(event) => props.handleCheckChange(event, "Trade Show/Conference")}
                                       name="Trade Show/Conference"
                                       disableRipple
                                    />
                                 }
                                 label="Trade Show/Conference"
                              />
                           </Grid>
                           <Grid
                              container
                              item
                              xs={4}
                              direction="column"
                              alignItems="flex-end"
                              justifyContent="flex-end"
                           >
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={props.application.referral.indexOf("Website") > -1 ? true : false}
                                       color="primary"
                                       value={"Website"}
                                       onChange={(event) => props.handleCheckChange(event, "Website")}
                                       name="Website"
                                       disableRipple
                                    />
                                 }
                                 label="Website"
                              />
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={props.application.referral.indexOf("Salesperson") > -1 ? true : false}
                                       color="primary"
                                       value={"Salesperson"}
                                       onChange={(event) => props.handleCheckChange(event, "Salesperson")}
                                       name="Salesperson"
                                       disableRipple
                                    />
                                 }
                                 label="Salesperson"
                              />
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={props.application.referral.indexOf("Social Media") > -1 ? true : false}
                                       value={"Social Media"}
                                       color="primary"
                                       onChange={(event) => props.handleCheckChange(event, "Social Media")}
                                       name="Social Media"
                                       disableRipple
                                    />
                                 }
                                 label="Social Media"
                              />
                           </Grid>
                        </Grid>
                        <Button
                           color="primary"
                           variant="contained"
                           disabled={props.application.referral.length === 0 ? true : false}
                           onClick={() => submitReferralClicked()}
                           style={{ marginTop: "1rem", marginBottom: "1rem", alignSelf: "center" }}
                           className={classes.submitButton}
                        >
                           Submit
                        </Button>
                     </FormGroup>
                  </Paper>
               </Grid>
            </Zoom>
            <Grid container item xs={4} direction="column" style={{ marginTop: "3rem" }}>
               <Zoom in={props.activeStep === 5 ? true : false} style={{ transitionDelay: "1000ms" }} timeout={500}>
                  <Grid item xs={3}>
                     <Button
                        onClick={() => handleViewClick()}
                        style={{
                           backgroundColor: viewing
                              ? "#E0E0E0"
                              : props.division.toUpperCase() === "MIDDLEBAY"
                              ? "#8F9864"
                              : props.division.toUpperCase() === "BRISTOL"
                              ? "#1E376B"
                              : "#012968",
                           borderRadius: "1rem",
                           display: "block",
                           width: "20rem",
                        }}
                        disabled={viewing}
                     >
                        {viewing ? (
                           <CircularProgress size={46} className={classes.buttonProgress} />
                        ) : (
                           <PageviewIcon style={{ width: "3rem", height: "3rem", color: "white" }}></PageviewIcon>
                        )}

                        <p style={{ marginTop: "0rem", color: "white" }}>View Application Receipt</p>
                     </Button>
                  </Grid>
               </Zoom>
               <Grid item xs={1}>
                  <Divider style={{ width: "20rem" }}></Divider>
               </Grid>
               <Zoom in={props.activeStep === 5 ? true : false} style={{ transitionDelay: "1000ms" }} timeout={500}>
                  <Grid item xs={3}>
                     <Button
                        onClick={() => handleDownloadClick()}
                        style={{
                           backgroundColor: downloading
                              ? "#E0E0E0"
                              : props.division.toUpperCase() === "MIDDLEBAY"
                              ? "#8F9864"
                              : props.division.toUpperCase() === "BRISTOL"
                              ? "#1E376B"
                              : "#012968",
                           borderRadius: "1rem",
                           display: "block",
                           width: "20rem",
                           textAlign: "center",
                        }}
                        disabled={downloading}
                     >
                        {downloading ? (
                           <CircularProgress size={46} className={classes.buttonProgress} />
                        ) : (
                           <GetAppIcon style={{ width: "3rem", height: "3rem", color: "white" }}></GetAppIcon>
                        )}
                        <p style={{ marginTop: "0rem", color: "white" }}>Download Application Receipt</p>
                     </Button>
                  </Grid>
               </Zoom>
            </Grid>
         </Grid>
      </>
   );
};

export default SignComplete;
