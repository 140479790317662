import {
   Button,
   ButtonGroup,
   Card,
   CardHeader,
   Chip,
   CircularProgress,
   Collapse,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   Grid,
   IconButton,
   InputAdornment,
   MenuItem,
   Select,
   Slide,
   TextField,
   Tooltip,
   Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EmailIcon from "@material-ui/icons/Email";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { sendInvite as sendInviteApi } from "../../api/ApplicationApi";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { UserContext } from "../../stores/Users/Context";
import { Applications } from "../../types/Applications";
import { Companies } from "../../types/Companies";
import { Contacts } from "../../types/Contacts";
import { Divisions } from "../../types/Divisions";
import { validateEmailAddress as validateEmailAddressFunction } from "../../utilities/validateEmailAddress";
import "./ApplicationNew.css";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
   createStyles({
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      buttonProgress: {
         color: green[500],
      },
      checkIcon: {
         color: red[500],
      },
   }),
);

export const ApplicationNew: React.FC<any> = (props: {
   open: boolean;
   handleClose: () => void;
   openAlertMessage: (alertMessage: string, alertType: "error" | "info" | "success" | "warning") => Promise<void>;
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);

   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [emailAddress, setEmailAddress] = useState<string>("");
   const [invalidEmail, setInvalidEmail] = useState<string>("Enter an Email");
   const [companyList, setCompanyList] = useState<Divisions[]>([]);
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);
   const [selectedCompany, setSelectedCompany] = useState<string>("");
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);

   async function inviteUser() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to send an Application invite is denied!", "warning");
            return;
         }

         if (invalidEmail !== "") {
            openAlertMessage(invalidEmail, "warning");
            return;
         }

         if (!selectedCompany) {
            openAlertMessage("You must select at least one company or division!", "warning");
            return;
         }

         if (selectedContacts.length === 0) {
            openAlertMessage("You must select at least one contact!", "warning");
            return;
         }

         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id.toString() === selectedCompany.toString(),
         );

         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id.toString() === selectedCompany.toString(),
         );

         if (divisionAssigned.length > 0) {
            if (!divisionAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!divisionAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !divisionAssigned[0].invitationBody ||
               !divisionAssigned[0].invitationTopNote ||
               !divisionAssigned[0].receiptFirstParagraph ||
               !divisionAssigned[0].receiptSecondParagraph ||
               !divisionAssigned[0].deniedFirstParagraph ||
               !divisionAssigned[0].deniedSecondParagraph ||
               !divisionAssigned[0].approvedFirstParagraph ||
               !divisionAssigned[0].approvedSecondParagraph ||
               !divisionAssigned[0].approvedBoldParagraph ||
               !divisionAssigned[0].contactApprovedBody ||
               !divisionAssigned[0].contactDeniedBody ||
               !divisionAssigned[0].duplicationNotification ||
               !divisionAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         } else {
            if (!companyAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!companyAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !companyAssigned[0].invitationBody ||
               !companyAssigned[0].invitationTopNote ||
               !companyAssigned[0].receiptFirstParagraph ||
               !companyAssigned[0].receiptSecondParagraph ||
               !companyAssigned[0].deniedFirstParagraph ||
               !companyAssigned[0].deniedSecondParagraph ||
               !companyAssigned[0].approvedFirstParagraph ||
               !companyAssigned[0].approvedSecondParagraph ||
               !companyAssigned[0].approvedBoldParagraph ||
               !companyAssigned[0].contactApprovedBody ||
               !companyAssigned[0].contactDeniedBody ||
               !companyAssigned[0].duplicationNotification ||
               !companyAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         }

         setButtonClicked(true);

         const newApplication: Applications = {
            _id: "",
            applicationNumber: "",
            email: emailAddress,
            customerType: "",
            customerSubType: "",
            brokerBond: null,
            companyName: "",
            companyEstablishedDate: null,
            accountsPayableEmail: "",
            companyContact: "",
            companyEmail: "",
            companyWebsite: "",
            ctpatNumber: "",
            address: {
               _id: "",
               address1: "",
               address2: "",
               city: "",
               state: "",
               zip: "",
               country: "",
            },
            billingAddress: {
               _id: "",
               address1: "",
               address2: "",
               city: "",
               state: "",
               zip: "",
               email: "",
               phone: "",
               contact: "",
            },
            chb: {
               _id: "",
               uwlHandleChb: false,
               achImporter: false,
               companyName: "",
               contact: "",
               address: "",
               email: "",
               city: "",
               state: "",
               zip: "",
               phone: "",
            },
            companyPhone: "",
            status: "",
            amountReq: 0,
            method: {
               code: "",
               value: "",
            },
            ownership: "",
            documents: [],
            bankInfo: {
               duns: "",
               bankName: "",
               accountNumber: "",
               principalName: "",
               address1: "",
               address2: "",
               city: "",
               state: "",
               zip: "",
               contact: "",
               phone: "",
               country: "",
               federalTaxNumber: "",
               paymentMethod: "",
            },
            tradeRefInfo: [],
            signature: "",
            signatureDate: null,
            signatureTitle: "",
            code: "",
            approvedBy_id: "",
            approvedAmount: 0,
            netTerms: "",
            annualRevenue: 0,
            numberEmployees: 0,
            company_id: divisionAssigned.length > 0 ? divisionAssigned[0].company_id : selectedCompany.toString(),
            division_id: divisionAssigned.length > 0 ? divisionAssigned[0]._id : "",
            isCOD: null,
            notes: "",
            companyCode: "",
            fedTax_id: "",
            referral: [],
            notifiedBy_id: userState.CurrentUser._id,
            notifiedDate: new Date(),
            docuSignEnvelope_id: "",
            docuSignCompleted: null,
            approvedOrDeniedDate: null,
            contacts: selectedContacts,
            applicationOpened: null,
            addDate: new Date(),
            deleteDate: null,
            tradeReferencesFileName: "",
            tradeReferencesSkipped: false,
         };

         const responseData = await sendInviteApi(newApplication);

         if (responseData.success) {
            props.openAlertMessage("Application invite has been sent out", "success");
            setButtonClicked(false);
            props.handleClose();
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         handleError(err);
      }
   }

   //Loading Use Effect.
   useEffect(() => {
      setAlertMessage("");
      setAlertType("warning");
      setEmailAddress("");
      setInvalidEmail("Enter an Email");
      setSelectedCompany("");
      setButtonClicked(false);
      setContactList([]);
      setSelectedContacts([]);
      setSelectedContactsList([]);
   }, [props.open]);

   //States Use Effect.
   useEffect(() => {
      //Merge these for a clean list.
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         let newCompanyList: Divisions[] = [];

         companyState.Companies.forEach(function (company: Companies) {
            if (company.active === false || company.deleteDate !== null) {
               return;
            }

            const divisionsAssigned = _.filter(
               divisionState.Divisions,
               (x) => x.company_id === company._id && x.active === true && x.deleteDate === null,
            );

            if (divisionsAssigned.length > 0) {
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: [],
                     contacts: [],
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         newCompanyList = _.uniqBy(newCompanyList, "code");
         newCompanyList = _.orderBy(newCompanyList, ["code"], ["asc"]);

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState]);

   async function validateEmailAddress(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      try {
         const email = event.target.value ? event.target.value : "";

         const results = await validateEmailAddressFunction(email);

         setInvalidEmail(results);
         setEmailAddress(email);
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function handleSelectChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         setSelectedCompany(event.target.value ? (event.target.value as string) : "");

         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id.toString() === (event.target.value ? (event.target.value as string) : ""),
         );

         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id.toString() === (event.target.value ? (event.target.value as string) : ""),
         );

         if (divisionAssigned.length > 0) {
            setContactList(
               _.orderBy(
                  _.filter(divisionAssigned[0].contacts, (x) => x.active === true),
                  [(contact) => contact.name.toLowerCase()],
                  ["asc"],
               ),
            );
         } else if (companyAssigned.length > 0) {
            setContactList(
               _.orderBy(
                  _.filter(companyAssigned[0].contacts, (x) => x.active === true),
                  [(contact) => contact.name.toLowerCase()],
                  ["asc"],
               ),
            );
         }

         setSelectedContacts([]);
         setSelectedContactsList([]);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleSelectContactChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const contacts = event.target.value ? (event.target.value as string[]) : [];

         setSelectedContacts(contacts);

         const contactAssigned = _.filter(contactList, (x) => contacts.includes(contacts ? x._id : contacts));

         setSelectedContactsList(contactAssigned);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChipDelete(_id: string) {
      try {
         setSelectedContactsList(selectedContactsList.filter((x) => x._id !== _id));
         setSelectedContacts(selectedContacts.filter((x) => x !== _id));
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog open={props.open || false} TransitionComponent={Transition} keepMounted fullScreen={mobileScreen}>
         <DialogTitle className="ApplicationNew-title">
            <Grid container direction={"row"} item xs={12} alignItems={"center"} justifyContent={"center"}>
               <CreditCardIcon style={{ marginRight: "1rem" }} />
               {"Apply For Credit"}
            </Grid>
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent>
            <Grid
               container
               direction={"row"}
               item
               xs={12}
               style={{ placeContent: "space-evenly", margin: "auto", marginRight: "1.5rem" }}
            >
               <TextField
                  id="ci-email"
                  value={emailAddress}
                  onChange={(event) => validateEmailAddress(event)}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  style={{ width: mobileScreen ? "100%" : "30rem" }}
                  InputProps={
                     invalidEmail
                        ? {
                             startAdornment: (
                                <Tooltip title={invalidEmail} arrow>
                                   <InputAdornment position="start">
                                      <CloseIcon className={classes.checkIcon} />
                                   </InputAdornment>
                                </Tooltip>
                             ),
                          }
                        : {
                             startAdornment: (
                                <Tooltip title={"Valid Email"} arrow>
                                   <InputAdornment position="start">
                                      <CheckIcon className={classes.buttonProgress} />
                                   </InputAdornment>
                                </Tooltip>
                             ),
                          }
                  }
               />
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Card className={"ApplicationNew-cards"}>
                  <CardHeader
                     className={"ApplicationNew-card-header2"}
                     style={{ textAlign: "center" }}
                     title={<Typography>Available Companies | Divisions</Typography>}
                  />
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                     <Select
                        id="select-companies"
                        defaultValue=""
                        value={selectedCompany}
                        onChange={(event) => handleSelectChange(event)}
                     >
                        {companyList.map((company) => {
                           return (
                              <MenuItem
                                 key={company._id ? company._id : company.company_id}
                                 value={company._id ? company._id : company.company_id}
                              >
                                 {company.code + " | " + company.name}
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Card>
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Card className={"ApplicationNew-cards"}>
                  <CardHeader
                     className={"ApplicationNew-card-header2"}
                     style={{ textAlign: "center" }}
                     title={<Typography>Contacts</Typography>}
                  />
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                     <Select
                        id="select-contact"
                        defaultValue=""
                        multiple
                        MenuProps={{
                           getContentAnchorEl: null,
                           transformOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                           },
                        }}
                        value={selectedContacts}
                        onChange={(event) => handleSelectContactChange(event)}
                        disabled={contactList.length <= 0}
                     >
                        {contactList.map((contact) => {
                           return (
                              <MenuItem key={contact._id} value={contact._id}>
                                 {contact.name}
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
                  <Grid container direction={"row"} item xs={12} style={{ marginTop: "1rem", margin: "auto" }}>
                     {selectedContactsList.map((contact) => {
                        return (
                           <Chip
                              key={contact._id}
                              label={contact.name}
                              size="small"
                              color="primary"
                              onDelete={() => handleChipDelete(contact._id)}
                           />
                        );
                     })}
                  </Grid>
               </Card>
            </Grid>
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={inviteUser}
                  variant="contained"
                  color="primary"
                  classes={{
                     label: classes.label,
                  }}
                  disabled={buttonClicked}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <EmailIcon />}
                  Send Application Invite
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default ApplicationNew;
