import { Box, Button, Dialog, MenuItem, Select } from "@material-ui/core";
import React, { useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { setUser } from "../api/UserApi";
import { UserContext } from "../stores/Users/Context";
import { Users } from "../types/Users";
import "./Home.css";

const Home: React.FC = () => {
   const { state: userState } = useContext(UserContext);
   const [homePage, setHomePage] = React.useState(userState.CurrentUser.homePage.page);
   const [homeView, setHomeView] = React.useState(userState.CurrentUser.homePage.view);
   const history = useHistory();

   const redirectToHomePage = () => {
      return <Redirect to={`/${userState.CurrentUser.homePage.page}/${userState.CurrentUser.homePage.view}`} />;
   };

   if (!userState.CurrentUser.homePage.page) {
      const editedUser = { ...userState.CurrentUser } as Users;
      return (
         <Dialog open={true} fullWidth maxWidth={"xs"}>
            <Box className="home-dialog">
               <h1>Please choose a homepage</h1>
               <Select
                  title="Home Page"
                  label="Home Page"
                  value={homePage}
                  onChange={(e) => {
                     setHomePage(e.target.value as string);
                  }}
                  fullWidth
               >
                  <MenuItem value="applications">Applications</MenuItem>
                  <MenuItem value="activations">Activations</MenuItem>
               </Select>
               <h3>Please choose a view</h3>
               <Select
                  title="Home View"
                  label="Home View"
                  value={homeView}
                  onChange={(e) => {
                     setHomeView(e.target.value as string);
                  }}
                  fullWidth
               >
                  {["SENT", "NEW", "PENDING", "APPROVED", "DENIED", "REVIEWED"].map((view) => (
                     <MenuItem key={view} value={view}>
                        {view}
                     </MenuItem>
                  ))}
               </Select>
               <Button
                  onClick={() => {
                     editedUser.homePage.page = homePage;
                     editedUser.homePage.view = homeView;
                     setUser(editedUser);
                     console.log("User saved");
                     history.push(`/${editedUser.homePage.page}/${editedUser.homePage.view}`);
                  }}
               >
                  Save
               </Button>
            </Box>
         </Dialog>
      );
   }
   return redirectToHomePage();
};

export default Home;
