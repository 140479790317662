import { Box } from "@material-ui/core";
import { saveAs } from "file-saver";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
   SetType,
   deleteCustomerActivation,
   getCustomerActivationList,
   getCustomerActivationPdf,
   sendInvite,
   setActivationCopy,
   setCustomerActivation,
} from "../../api/CustomerActivationApi";
import { CardItem, CardList } from "../../components/Activation/ActivationPage/Activation.styled";
import { ActivationSnackbar } from "../../components/Activation/ActivationSnackbar/ActivationSnackbar";
import { ActivationViewFilters } from "../../components/Activation/ActivationViewFilters/ActivationViewFilters";
import {
   ActivationApproveDialog,
   ApprovalParams,
} from "../../components/Activation/Dialogs/ActivarionApproveDialog/ActivationApproveDialog";
import {
   ActivationCopyDialog,
   TypeCopyActivation,
} from "../../components/Activation/Dialogs/ActivationCopyDialog/ActivationCopyDialog";
import { ActivationDeleteDialog } from "../../components/Activation/Dialogs/ActivationDeleteDialog/ActivationDeleteDialog";
import {
   ActivationDenialDialog,
   DenialParams,
} from "../../components/Activation/Dialogs/ActivationDenialDialog/ActivationDenialDialog";
import { ActivationNotesDialog } from "../../components/Activation/Dialogs/ActivationNotesDialog/ActivationNotesDialog";
import { ActivationDetailsButtonType } from "../../components/ActivationDetails/SubHeader/NewSubHeader";
import { ApprovedActivationRow } from "../../components/ActivationRows/ApprovedActivationRow";
import { DeniedActivationRow } from "../../components/ActivationRows/DeniedActivationRow";
import { NewActivationRow } from "../../components/ActivationRows/NewActivationRow";
import { PendingActivationRow } from "../../components/ActivationRows/PendingActivationRow";
import { ReviewActivationRow } from "../../components/ActivationRows/ReviewActionRow";
import { SentActivationRow } from "../../components/ActivationRows/SentActivationRow";
import { LoaderAnimation } from "../../components/Loader/LoaderAnimation";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import { Status as CAStatus, CustomerActivation } from "../../types/CustomerActivation";
import { DenialCodes } from "../../types/DenialCodes";
import { ActivationInvite } from "./ActivationInvite";
import styles from "./ActivationView.module.css";

export interface FetchDataProps {
   company?: string;
   startDate?: Date | null;
   endDate?: Date | null;
   status: Status;
   filter?: string;
   page?: number;
}

interface DeleteDialogProps {
   isOpen: boolean;
   item: string;
   id: string;
}

type ActivationViewProps = RouteComponentProps<{ view: string }>;

interface DialogProps {
   isOpen: boolean;
   id: string;
}

interface AlertProps {
   open: boolean;
   message: string;
   variant: "success" | "error" | "warning" | "info";
}

// create a type for the status that can be iterated over
export const states = ["SENT", "NEW", "PENDING", "APPROVED", "DENIED", "REVIEWED"] as const;
// eslint-disable-next-line prettier/prettier
export type Status = (typeof states)[number];

export const ActivationView: React.FC<ActivationViewProps> = (props) => {
   const selectedView = props.match.params.view ? props.match.params.view.toUpperCase() : "NEW";
   const handleError = useErrorHandler();
   const history = useHistory();
   // contexts
   const { state: drawerState } = useContext(DrawerContext);
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);
   // memos
   const isSideBarOpen = useMemo(() => drawerState.DrawerOpen, [drawerState]);
   // states
   const [customerActivations, setCustomerActivations] = useState<CustomerActivation[]>([]);
   const [activationSelected, setActivationSelected] = useState<CustomerActivation>();
   const [status, setStatus] = useState<Status>(selectedView as Status);
   const [isLoading, setIsLoading] = useState(true);
   const [denialCodesList, setDenialCodesList] = useState<DenialCodes[]>([]);
   const [notesDialog, setNotesDialog] = useState<DialogProps>({ isOpen: false, id: "" });
   const [approvalModal, setApprovalModal] = useState<DialogProps>({ isOpen: false, id: "" });
   const [denialModal, setDenialModal] = useState<DialogProps>({ isOpen: false, id: "" });
   const [copyDialog, setCopyDialog] = useState<DialogProps>({ isOpen: false, id: "" });
   const [deleteDialog, setDeleteDialog] = useState<DeleteDialogProps>({
      isOpen: false,
      item: "",
      id: "",
   });
   const [alert, setAlert] = useState<AlertProps>({
      open: false,
      message: "",
      variant: "success",
   });
   // approved dialog handlers
   const handleDenialDialogOpen = (index: number) => () => {
      const id = customerActivations[index]._id;
      setDenialModal({ isOpen: true, id });
      // get denial codes
      const selectedCustomerActivation = customerActivations.find((activation) => activation._id === id)!;
      if (selectedCustomerActivation.division_id) {
         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id === selectedCustomerActivation.division_id,
         );

         if (divisionAssigned.length > 0) {
            setDenialCodesList(divisionAssigned[0].denialCodes);
         }
      } else {
         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id === selectedCustomerActivation.company_id,
         );

         if (companyAssigned.length > 0) {
            setDenialCodesList(companyAssigned[0].denialCodes);
         }
      }
   };

   // denial dialog handlers
   const handleApprovalDialogOpen = (index: number) => () => {
      const id = customerActivations[index]._id;
      setApprovalModal({ isOpen: true, id });
      // get denial codes
      const selectedCustomerActivation = customerActivations.find((activation) => activation._id === id)!;
      if (selectedCustomerActivation.division_id) {
         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id === selectedCustomerActivation.division_id,
         );

         if (divisionAssigned.length > 0) {
            setDenialCodesList(divisionAssigned[0].denialCodes);
         }
      } else {
         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id === selectedCustomerActivation.company_id,
         );

         if (companyAssigned.length > 0) {
            setDenialCodesList(companyAssigned[0].denialCodes);
         }
      }
   };

   // delete dialog handlers
   const handleDeleteDialogOpen = (index: number) => () => {
      const id = customerActivations[index]._id;
      const email = customerActivations[index].email;
      setDeleteDialog({ isOpen: true, item: email, id });
   };

   const handleDeleteDialogClose = () => {
      setDeleteDialog({ isOpen: false, item: "", id: "" });
   };
   // notes dialog handlers
   const handleNotesDialogOpen = (index: number) => () => {
      const id = customerActivations[index]._id;
      setNotesDialog({ isOpen: true, id });
   };

   const handleNotesDialogClose = () => {
      setNotesDialog({ isOpen: false, id: "" });
   };
   // helper function to get company or division name
   const getCompanyOrDivisionName = (companyId: string, divisionId: string) => {
      // try finding by division
      const division = divisionState.Divisions.find((division) => division._id.toString() === divisionId.toString());
      // if division is found, return division name
      if (division) return division.name;
      // try finding by company
      const company = companyState.Companies.find((company) => company._id.toString() === companyId.toString());
      // if company is found, return company name
      if (company) return company.name;
      // if nothing is found, return N/A
      return "N/A";
   };
   // fetch customer activations
   const fetchData = async ({
      // default values
      company = "",
      startDate = null,
      endDate = null,
      filter = "",
      status,
      page = 1,
   }: FetchDataProps) => {
      // set loading to true
      setIsLoading(true);
      // get data from api with args
      const response = await getCustomerActivationList({
         company,
         startDate,
         endDate,
         filter,
         page,
         status,
      });
      // check if response is successful
      if (!response.success) {
         setCustomerActivations([]);
         setIsLoading(false);
         return;
      }
      // if status is approved or denied, sort by approvedOrDeniedDate
      if (status === "APPROVED" || status === "DENIED") {
         const results = response.data.sort((a: any, b: any) => {
            const aDate = new Date(a.approvedOrDeniedDate);
            const bDate = new Date(b.approvedOrDeniedDate);
            return bDate.getTime() - aDate.getTime();
         });

         setCustomerActivations(results);
      } else {
         setCustomerActivations(response.data);
      }

      try {
      } catch (err) {
         setCustomerActivations([]);
         console.error(err);
      } finally {
         setIsLoading(false);
      }
   };
   // menu actions
   const handleLinkActionClick = (index: number) => () => {
      try {
         const activation = customerActivations[index];

         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            setAlert({ open: true, message: "Access to copy the application link is denied!", variant: "warning" });
            return;
         }

         let theme = "";
         // if division is assigned, get division theme
         if (activation.division_id) {
            const assignedDivision = divisionState.Divisions.find(
               (division) => division._id.toString() === activation.division_id?.toString(),
            );
            theme =
               assignedDivision?.code === "BRI" ? "Bristol" : assignedDivision?.code === "MBT" ? "MiddleBay" : "CPG";
         } else {
            // if division is not assigned, get company theme
            const assignedCompany = companyState.Companies.find(
               (company) => company._id.toString() === activation.company_id.toString(),
            );
            theme = assignedCompany?.prefix ?? "";
         }
         // create link
         const link = `${window.location.href.replace("activations", "")}Activation/${theme}/${activation.email}/${
            activation._id
         }`;
         // copy link to clipboard
         navigator.clipboard.writeText(link);
         // show alert
         setAlert({ open: true, message: "Link copied to clipboard!", variant: "success" });
      } catch (err) {
         setAlert({ open: true, message: "Error copying application link!", variant: "error" });
      }
   };

   const handleResendActionClick = (index: number) => async () => {
      const activation = customerActivations[index];
      const response = await sendInvite(activation);

      if (!response.success) {
         setAlert({ open: true, message: "Error sending activation email!", variant: "error" });
         return;
      }

      setAlert({ open: true, message: "Activation email sent successfully!", variant: "success" });
   };

   const handleDeleteActionClick = async () => {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            setAlert({ open: true, message: "Access to delete the application is denied!", variant: "warning" });
            return;
         }
         // delete activation
         const response = await deleteCustomerActivation(deleteDialog.id);

         if (!response.success) {
            setAlert({ open: true, message: "Error deleting activation!", variant: "error" });
            return;
         }
         // success
         setAlert({ open: true, message: "Activation deleted successfully!", variant: "success" });
         // update customer activations
         setCustomerActivations((prev) => prev.filter((item) => item._id !== deleteDialog.id));
      } catch (err) {
         handleError(err);
      }
   };

   const handlePrintViewPDFActionClick = (index: number) => async () => {
      try {
         const id = customerActivations[index]._id;
         const response = await getCustomerActivationPdf(id);

         if (!response.data) {
            setAlert({ open: true, message: "Error getting activation pdf!", variant: "error" });
            return;
         }

         response.data.arrayBuffer().then((res: BlobPart) => {
            const blob = new Blob([res], { type: "application/pdf" });
            // open pdf in new tab
            window.open(URL.createObjectURL(blob));
         });

         setAlert({ open: true, message: "Activation pdf retrieved successfully!", variant: "success" });
      } catch (err) {
         setAlert({ open: true, message: "Error getting activation pdf!", variant: "error" });
      }
   };

   const handleDownloadPDFActionClick = (index: number) => async () => {
      try {
         const id = customerActivations[index]._id;
         const response = await getCustomerActivationPdf(id);

         if (!response.data) {
            setAlert({ open: true, message: "Error getting activation pdf!", variant: "error" });
            return;
         }

         response.data.arrayBuffer().then((res: BlobPart) => {
            const blob = new Blob([res], { type: "application/pdf" });
            // download pdf file
            saveAs(blob, `Customer Activation # ${customerActivations[index].applicationNumber}.pdf`);
         });

         setAlert({ open: true, message: "Activation pdf retrieved successfully!", variant: "success" });
      } catch (err) {
         setAlert({ open: true, message: "Error getting activation pdf!", variant: "error" });
      }
   };

   const handleEmailActionClick = (index: number) => () => {
      const id = customerActivations[index]._id;
      const company = customerActivations[index].companyName.replace("&", "AND");

      window.open(
         `mailto:?subject=${company} Activation Needs Additional Review (e.g., ABC Warehouse Activation Form Needs Additional Review)&body=Activation Details:%20${window.location.origin}/activations/details/${id}`,
      );
   };
   // notes handlers and values
   const notesValue = customerActivations.find((activation) => activation._id === notesDialog.id)?.notes ?? "";

   const handleSetActivationNotes = (notes: string) => {
      setCustomerActivations((prev) => {
         const index = prev.findIndex((activation) => activation._id === notesDialog.id);
         const newCustomerActivations = [...prev];
         newCustomerActivations[index].notes = notes;
         return newCustomerActivations;
      });
   };

   const handleNotesActionClick = async () => {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            setAlert({ open: true, message: "Access to set notes is denied!", variant: "warning" });
            return;
         }

         // TODO: change status from NEW to PENDING ????

         const activation = customerActivations.find((activation) => activation._id === notesDialog.id);

         const response = await setCustomerActivation(activation!, SetType.Blank);

         if (!response.data) {
            setAlert({ open: true, message: "Error setting activation notes!", variant: "error" });
            return;
         }

         setAlert({ open: true, message: "Activation notes set successfully!", variant: "success" });
      } catch (err) {
         setAlert({ open: true, message: "Error setting activation notes!", variant: "error" });
      }
   };

   const handleSaveDenial = async (denial: DenialParams) => {
      return saveCustomerActivation(false, "denialCode", denial);
   };

   const handleSaveApproval = async (approval: ApprovalParams) => {
      return saveCustomerActivation(false, "approvedAmount", approval);
   };

   const handleGoToDetails = (activationID: string) => () => {
      history.push("/activations/details/" + activationID);
   };

   const handleCopyActivation = (activation: CustomerActivation) => () => {
      setCopyDialog({ isOpen: true, id: activation._id });
      setActivationSelected(activation);
   };

   const handleCopyDialogClose = () => {
      console.log("close");
      setCopyDialog({ isOpen: false, id: "" });
      setActivationSelected(undefined);
   };

   const handleCopyActionClick = async ({ selectedCompany, emailAddress, selectedContacts }: TypeCopyActivation) => {
      console.log({ selectedCompany, emailAddress, selectedContacts });
      try {
         if (!activationSelected) return;

         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to copy the application is denied!", "warning");
            return;
         }

         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id.toString() === selectedCompany.toString(),
         );

         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id.toString() === selectedCompany.toString(),
         );

         if (divisionAssigned.length > 0) {
            if (!divisionAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!divisionAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !divisionAssigned[0].invitationBody ||
               !divisionAssigned[0].invitationTopNote ||
               !divisionAssigned[0].receiptFirstParagraph ||
               !divisionAssigned[0].receiptSecondParagraph ||
               !divisionAssigned[0].deniedFirstParagraph ||
               !divisionAssigned[0].deniedSecondParagraph ||
               !divisionAssigned[0].approvedFirstParagraph ||
               !divisionAssigned[0].approvedSecondParagraph ||
               !divisionAssigned[0].approvedBoldParagraph ||
               !divisionAssigned[0].contactApprovedBody ||
               !divisionAssigned[0].contactDeniedBody ||
               !divisionAssigned[0].duplicationNotification ||
               !divisionAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         } else {
            if (!companyAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!companyAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !companyAssigned[0].invitationBody ||
               !companyAssigned[0].invitationTopNote ||
               !companyAssigned[0].receiptFirstParagraph ||
               !companyAssigned[0].receiptSecondParagraph ||
               !companyAssigned[0].deniedFirstParagraph ||
               !companyAssigned[0].deniedSecondParagraph ||
               !companyAssigned[0].approvedFirstParagraph ||
               !companyAssigned[0].approvedSecondParagraph ||
               !companyAssigned[0].approvedBoldParagraph ||
               !companyAssigned[0].contactApprovedBody ||
               !companyAssigned[0].contactDeniedBody ||
               !companyAssigned[0].duplicationNotification ||
               !companyAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         }

         const responseData = await setActivationCopy(
            activationSelected?._id as string,
            divisionAssigned.length > 0 ? divisionAssigned[0].company_id : selectedCompany.toString(),
            divisionAssigned.length > 0 ? divisionAssigned[0]._id : "",
            emailAddress,
            selectedContacts,
         );

         if (responseData.success) {
            openAlertMessage("Successfully copied application. ", "success");
         } else {
            openAlertMessage(responseData.message, "warning");
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "warning");
      }
   };

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlert({
         message: alertMessage,
         variant: alertType,
         open: true,
      });
   }

   const showSuccessAlert = () => {
      setAlert({ open: true, message: "Successfully updated the customer activation", variant: "success" });
   };

   async function saveCustomerActivation(
      resendCustomerActivation: boolean,
      fieldChanged: ActivationDetailsButtonType,
      value: string | ApprovalParams | DenialParams,
   ) {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            setAlert({
               open: true,
               message: "Access to change the customer activation is denied!",
               variant: "warning",
            });

            return;
         }

         const id = approvalModal.id || denialModal.id;
         const selectedCustomerActivation = customerActivations.find((activation) => activation._id === id)!;

         const activationObj = { ...selectedCustomerActivation };

         let updateList = false;

         if (activationObj.status === "NEW") {
            activationObj.status = "PENDING" as CAStatus;
            updateList = true;
         }

         if (fieldChanged === "approvedAmount") {
            const { customerCode, approvedAmount, contacts, paymentTerms } = value as ApprovalParams;
            const onlyNums = approvedAmount.replace(/[^0-9]/g, "");
            activationObj.approvedAmount = onlyNums ? +onlyNums : 0;
            activationObj.companyCode = customerCode;
            activationObj.contacts = contacts;
            activationObj.paymentTerms = paymentTerms || "";
            // update status to approved
            activationObj.status = "APPROVED" as CAStatus;
         } else if (fieldChanged === "denialCode") {
            const { denialCode, contacts, notes } = value as DenialParams;
            activationObj.notes = notes;
            activationObj.denialCode = denialCode;
            activationObj.contacts = contacts;
            // update status to denied
            activationObj.status = "DENIED" as CAStatus;
         } else if (fieldChanged === "pdf") {
            // not exists pdf field
         } else {
            activationObj[fieldChanged] = value as string;
         }

         const setType = () => {
            if (activationObj.status === "APPROVED") return SetType.Approved;
            if (activationObj.status === "DENIED") return SetType.Denied;
            return SetType.Blank;
         };

         const responseData = await setCustomerActivation(activationObj, setType());

         if (responseData.success) {
            setAlert({ open: true, message: "Successfully updated the customer activation.", variant: "success" });

            // update customer activations if status is changed
            if (updateList) {
               setCustomerActivations((prev) => prev.filter((item) => item._id !== id));
            }
            // if we are in pending status, we need to update the list
            if (!updateList) {
               fetchData({
                  status,
               });
            }
         } else {
            setAlert({ open: true, message: responseData.message, variant: "warning" });
         }
      } catch (err) {
         setAlert({
            open: true,
            message: "An error has occurred. Please reach out to administration for assistance.",
            variant: "error",
         });
      }
   }

   const selectedApprovedCustomerActivation = useMemo(() => {
      return customerActivations.find((activation) => activation._id.toString() === approvalModal.id.toString())!;
   }, [approvalModal]);

   const selectedDeniedCustomerActivation = useMemo(() => {
      return customerActivations.find((activation) => activation._id.toString() === denialModal.id.toString())!;
   }, [denialModal]);

   // get data on mount
   useEffect(() => {
      fetchData({
         status,
      });
   }, [status]);

   const contacts = useMemo(() => {
      if (
         // check if data is loaded
         customerActivations.length > 0 &&
         // check if companies are loaded
         companyState.Companies.length > 0 &&
         // check if divisions are loaded
         divisionState.Divisions.length > 0
         // check if status is reviewed
         // status === "REVIEWED"
      ) {
         return customerActivations.flatMap((application) => {
            if (application.division_id) {
               const divisionsAssigned = divisionState.Divisions.find(
                  (division) => division._id.toString() === application.division_id!.toString(),
               );

               if (divisionsAssigned) {
                  const contactAssigned = divisionsAssigned.contacts.filter((contact) =>
                     application.contacts.includes(application.contacts ? contact._id : application.contacts),
                  );
                  const contactNames = contactAssigned.map((contact) => contact.name).join(", ");
                  return { _id: application._id, contactList: contactNames };
               }
            } else {
               const companyAssigned = companyState.Companies.find(
                  (company) => company._id.toString() === application.company_id.toString(),
               );

               if (companyAssigned) {
                  const contactAssigned = companyAssigned.contacts.filter((contact) =>
                     application.contacts.includes(application.contacts ? contact._id : application.contacts),
                  );
                  const contactNames = contactAssigned.map((contact) => contact.name).join(", ");
                  return { _id: application._id, contactList: contactNames };
               }
            }

            return { _id: application._id, contactList: "" };
         });
      }

      return [];
   }, [customerActivations, companyState.Companies, divisionState.Divisions, status]);

   return (
      <div
         className={`${styles.main_container} ${isSideBarOpen ? `${styles.sidebar_open}` : `${styles.sidebar_closed}`}`}
      >
         {/* Header */}
         <Box className={styles.header}>
            <h1>Customer Activations</h1>
            <ActivationInvite showAlert={showSuccessAlert} />
         </Box>
         {/* Body */}
         <div>
            <ActivationViewFilters
               loading={isLoading}
               status={status}
               setStatus={setStatus}
               fetchData={fetchData}
               getCompanyOrDivisionName={getCompanyOrDivisionName}
            />
            {isLoading ? (
               <div className={styles.loader_container}>
                  <LoaderAnimation />
               </div>
            ) : (
               <>
                  {/* if there are no results, show an advice */}
                  {customerActivations.length === 0 && (
                     <div
                        style={{
                           height: "100%",
                           width: "100%",
                           marginTop: "5rem",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <img src="/no-data-to-display.png" alt="There is no information to display" />
                     </div>
                  )}
                  <CardList>
                     {customerActivations.map((item, index) => (
                        <CardItem key={item._id}>
                           {status === "SENT" ? (
                              <SentActivationRow
                                 date={item.addDate}
                                 companyOrDivisionName={getCompanyOrDivisionName(
                                    item.company_id,
                                    item.division_id ?? "",
                                 )}
                                 clientName={item.companyName}
                                 email={item.email}
                                 pointOfcontact={contacts
                                    .filter(({ _id }) => _id === item._id)
                                    .map(({ contactList }) => contactList)
                                    .join(", ")}
                                 status={"SENT"}
                                 handleLinkActionClick={handleLinkActionClick(index)}
                                 handleResendActionClick={handleResendActionClick(index)}
                                 handleDeleteDialogOpen={handleDeleteDialogOpen(index)}
                                 handleGoToDetails={handleGoToDetails(item._id)}
                              />
                           ) : null}
                           {status === "NEW" ? (
                              <NewActivationRow
                                 applicationNumber={item.applicationNumber}
                                 dateReceived={item.signatureDate!}
                                 companyOrDivisionName={getCompanyOrDivisionName(
                                    item.company_id,
                                    item.division_id ?? "",
                                 )}
                                 clientName={item.companyName}
                                 pointOfcontact={contacts
                                    .filter(({ _id }) => _id === item._id)
                                    .map(({ contactList }) => contactList)
                                    .join(", ")}
                                 handlePrintViewPDFActionClick={handlePrintViewPDFActionClick(index)}
                                 handleDownloadPDFActionClick={handleDownloadPDFActionClick(index)}
                                 handleEmailActionClick={handleEmailActionClick(index)}
                                 handleNotesDialogOpen={handleNotesDialogOpen(index)}
                                 handleApprovalDialogOpen={handleApprovalDialogOpen(index)}
                                 handleDenialDialogOpen={handleDenialDialogOpen(index)}
                                 handleGoToDetails={handleGoToDetails(item._id)}
                              />
                           ) : null}
                           {status === "PENDING" ? (
                              <PendingActivationRow
                                 applicationNumber={item.applicationNumber}
                                 dateReceived={item.signatureDate!}
                                 companyOrDivisionName={getCompanyOrDivisionName(
                                    item.company_id,
                                    item.division_id ?? "",
                                 )}
                                 clientName={item.companyName}
                                 pointOfcontact={contacts
                                    .filter(({ _id }) => _id === item._id)
                                    .map(({ contactList }) => contactList)
                                    .join(", ")}
                                 notes={item.notes}
                                 handlePrintViewPDFActionClick={handlePrintViewPDFActionClick(index)}
                                 handleDownloadPDFActionClick={handleDownloadPDFActionClick(index)}
                                 handleEmailActionClick={handleEmailActionClick(index)}
                                 handleNotesDialogOpen={handleNotesDialogOpen(index)}
                                 handleApprovalDialogOpen={handleApprovalDialogOpen(index)}
                                 handleDenialDialogOpen={handleDenialDialogOpen(index)}
                                 handleGoToDetails={handleGoToDetails(item._id)}
                              />
                           ) : null}
                           {status === "APPROVED" ? (
                              <ApprovedActivationRow
                                 applicationNumber={item.applicationNumber}
                                 dateReceived={item.signatureDate!}
                                 dateApproved={item.approvedOrDeniedDate!}
                                 companyOrDivisionName={getCompanyOrDivisionName(
                                    item.company_id,
                                    item.division_id ?? "",
                                 )}
                                 clientName={item.companyName}
                                 pointOfcontact={contacts
                                    .filter(({ _id }) => _id === item._id)
                                    .map(({ contactList }) => contactList)
                                    .join(", ")}
                                 handlePrintViewPDFActionClick={handlePrintViewPDFActionClick(index)}
                                 handleDownloadPDFActionClick={handleDownloadPDFActionClick(index)}
                                 handleEmailActionClick={handleEmailActionClick(index)}
                                 handleNotesDialogOpen={handleNotesDialogOpen(index)}
                                 handleGoToDetails={handleGoToDetails(item._id)}
                              />
                           ) : null}
                           {status === "DENIED" ? (
                              <DeniedActivationRow
                                 applicationNumber={item.applicationNumber}
                                 dateReceived={item.signatureDate!}
                                 dateDenied={item.approvedOrDeniedDate!}
                                 denialCode={item.denialCode}
                                 clientName={item.companyName}
                                 companyOrDivisionName={getCompanyOrDivisionName(
                                    item.company_id,
                                    item.division_id ?? "",
                                 )}
                                 pointOfcontact={contacts
                                    .filter(({ _id }) => _id === item._id)
                                    .map(({ contactList }) => contactList)
                                    .join(", ")}
                                 handlePrintViewPDFActionClick={handlePrintViewPDFActionClick(index)}
                                 handleDownloadPDFActionClick={handleDownloadPDFActionClick(index)}
                                 handleEmailActionClick={handleEmailActionClick(index)}
                                 handleNotesDialogOpen={handleNotesDialogOpen(index)}
                                 handleGoToDetails={handleGoToDetails(item._id)}
                              />
                           ) : null}
                           {status === "REVIEWED" ? (
                              <ReviewActivationRow
                                 applicationNumber={item.applicationNumber}
                                 dateReceived={item.signatureDate!}
                                 dateApproved={item.status === "APPROVED" ? item.approvedOrDeniedDate : null}
                                 dateDenied={item.status === "DENIED" ? item.approvedOrDeniedDate! : null}
                                 denialCode={item.status === "DENIED" ? item.denialCode : null}
                                 status={item.status}
                                 notes={item.notes}
                                 approvedAmount={item.approvedAmount}
                                 companyOrDivisionName={getCompanyOrDivisionName(
                                    item.company_id,
                                    item.division_id ?? "",
                                 )}
                                 clientName={item.companyName}
                                 pointOfcontact={contacts
                                    .filter(({ _id }) => _id === item._id)
                                    .map(({ contactList }) => contactList)
                                    .join(", ")}
                                 handlePrintViewPDFActionClick={handlePrintViewPDFActionClick(index)}
                                 handleDownloadPDFActionClick={handleDownloadPDFActionClick(index)}
                                 handleEmailActionClick={handleEmailActionClick(index)}
                                 handleNotesDialogOpen={handleNotesDialogOpen(index)}
                                 handleGoToDetails={handleGoToDetails(item._id)}
                                 handleCopyActivation={handleCopyActivation(item)}
                              />
                           ) : null}
                        </CardItem>
                     ))}
                  </CardList>
               </>
            )}
         </div>
         <ActivationApproveDialog
            open={approvalModal.isOpen}
            divisionId={
               selectedApprovedCustomerActivation ? (selectedApprovedCustomerActivation.division_id as string) : ""
            }
            companyId={selectedApprovedCustomerActivation ? selectedApprovedCustomerActivation.company_id : ""}
            contacts={selectedApprovedCustomerActivation ? selectedApprovedCustomerActivation.contacts : []}
            approvedAmount={selectedApprovedCustomerActivation ? selectedApprovedCustomerActivation.approvedAmount : 0}
            customerCode={selectedApprovedCustomerActivation ? selectedApprovedCustomerActivation.companyCode : ""}
            handleClose={() => setApprovalModal({ isOpen: false, id: "" })}
            callback={handleSaveApproval}
         />
         <ActivationDenialDialog
            open={denialModal.isOpen}
            divisionId={
               selectedDeniedCustomerActivation ? (selectedDeniedCustomerActivation.division_id as string) : ""
            }
            companyId={selectedDeniedCustomerActivation ? selectedDeniedCustomerActivation.company_id : ""}
            contacts={selectedDeniedCustomerActivation ? selectedDeniedCustomerActivation.contacts : []}
            denialNotes={selectedDeniedCustomerActivation ? selectedDeniedCustomerActivation.notes : ""}
            denialCode={selectedDeniedCustomerActivation ? selectedDeniedCustomerActivation.denialCode : ""}
            denialCodes={denialCodesList}
            handleClose={() => setDenialModal({ isOpen: false, id: "" })}
            callback={handleSaveDenial}
         />
         <ActivationDeleteDialog
            item={deleteDialog.item}
            open={deleteDialog.isOpen}
            handleClose={handleDeleteDialogClose}
            callback={handleDeleteActionClick}
         />
         <ActivationNotesDialog
            open={notesDialog.isOpen}
            value={notesValue}
            handleClose={handleNotesDialogClose}
            callback={handleNotesActionClick}
            setValue={handleSetActivationNotes}
         />
         <ActivationCopyDialog
            open={copyDialog.isOpen}
            activationSelected={activationSelected!}
            handleClose={handleCopyDialogClose}
            callback={handleCopyActionClick}
         />
         <ActivationSnackbar
            open={alert.open}
            message={alert.message}
            variant={alert.variant}
            handleClose={() => setAlert({ open: false, message: "", variant: "success" })}
         />
      </div>
   );
};
