import { MenuItem, useMediaQuery } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import { blue, green, grey, purple, red } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SearchIcon from "@material-ui/icons/Search";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { getUserList, removeUser, resendInvite } from "../../api/UserApi";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import CheckAnimation from "../../styles/CheckAnimation";
import { Users } from "../../types/Users";
import * as CookieTools from "../../utilities/CookieTools";
import { DateFormatter } from "../../utilities/DateFormatter";
import UserNew from "./UserNew";
import "./UserView.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      headerDrawerOpen: {
         marginLeft: 240,
      },
      headerDrawerClosed: {
         marginLeft: theme.spacing(7),
      },
      avatar: {
         width: theme.spacing(6),
         height: theme.spacing(6),
      },
      divider: {
         backgroundColor: "#778BA3",
         height: "3px",
         width: "100%",
      },
      activeChip: {
         backgroundColor: green[500],
         color: "white",
      },
      inactiveChip: {
         backgroundColor: red[500],
         color: "white",
      },
      readOnlyChip: {
         color: "white",
         backgroundColor: grey[900],
      },
      adminChip: {
         color: "white",
         backgroundColor: blue[500],
      },
      superAdminChip: {
         color: "white",
         backgroundColor: purple[200],
      },
   }),
);

export const UserView: React.FC<any> = (): JSX.Element => {
   const classes = useStyles();
   const history = useHistory();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const { state: drawerState } = useContext(DrawerContext);
   const { state: userState } = useContext(UserContext);
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const [users, setUsers] = useState<Users[]>([]);
   const [originalUsers, setOriginalUsers] = useState<Users[]>([]);
   const [selectedUser, setSelectedUser] = useState<Users>({
      _id: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      active: false,
      companies: [],
      divisions: [],
      security: "",
      invite: "",
      maximumApprovalAmount: 0,
      department: "",
      addDate: new Date(),
      deleteDate: null,
      homePage: {
         page: null,
         view: null,
      },
   });
   const [userCompanies, setUserCompanies] = useState<{ _id: string; companiesList: string }[]>([]);
   const [deleteModal, setDeleteModal] = useState(false);
   const [inviteModal, setInviteModal] = useState(false);
   const [loading, setLoading] = useState(true);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [active, setActive] = useState<"Active" | "Inactive" | "All">("Active");
   const [filter, setFilter] = useState<string>("");
   const activeOptions = ["All", "Active", "Inactive"];

   let themeCounter = 1;

   function breadcrumbOnClick(path: string) {
      if (path) {
         history.push(path);
      }
   }

   async function getUsers() {
      try {
         const responseData = await getUserList();

         if (responseData.success) {
            let filteredObjects = _.orderBy(
               _.filter(
                  responseData.data,
                  (x) =>
                     x.email.toLowerCase().includes(filter.toLowerCase()) ||
                     x.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                     x.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                     DateFormatter(x.addDate) === filter,
               ),
               ["firstName"],
               ["asc"],
            );

            if (active === "Active") {
               filteredObjects = _.filter(filteredObjects, (x) => x.active === true);
            } else if (active === "Inactive") {
               filteredObjects = _.filter(filteredObjects, (x) => x.active === false);
            }

            setUsers(filteredObjects);
            setOriginalUsers(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   useEffect(() => {
      getUsers();
   }, []);

   useEffect(() => {
      if (originalUsers.length > 0 && companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         const newUserCompanies: { _id: string; companiesList: string }[] = [];

         _.forEach(originalUsers, (user) => {
            const companyList: string[] = [];

            const companiesAssigned = _.orderBy(
               _.filter(companyState.Companies, (x) =>
                  user.companies
                     ? user.companies.includes(x._id)
                     : x._id === null && x.active === true && x.deleteDate === null,
               ),
               ["name"],
               ["asc"],
            );

            _.forEach(companiesAssigned, (company) => {
               const divisionsAssigned = _.orderBy(
                  _.filter(
                     divisionState.Divisions,
                     (x) =>
                        (user.divisions ? user.divisions.includes(x._id) : x._id === null) &&
                        x.active === true &&
                        x.deleteDate === null,
                  ),
                  ["name"],
                  ["asc"],
               );

               const divisionsAvailable = _.orderBy(
                  _.filter(
                     divisionState.Divisions,
                     (x) => x.company_id === company._id && x.active === true && x.deleteDate === null,
                  ),
                  ["name"],
                  ["asc"],
               );

               if (divisionsAvailable.length === 0) {
                  companyList.push(company.code);
                  return;
               }

               if (divisionsAssigned.length === divisionsAvailable.length) {
                  companyList.push(`ALL ${company.code}`);
               } else {
                  _.forEach(divisionsAssigned, (division) => {
                     companyList.push(division.code);
                  });
               }
            });

            newUserCompanies.push({ _id: user._id, companiesList: companyList.join(", ") });
         });

         setUserCompanies(newUserCompanies);
         setLoading(false);
      }
   }, [originalUsers, companyState, divisionState]);

   async function handleDetailsClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         history.push("/users/details/" + users[index]._id);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleDeleteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         setSelectedUser(users[index]);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleInviteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         const responseData = await resendInvite(users[index]._id);

         if (responseData.success) {
            openAlertMessage("Successfully resent invite.", "success");
            getUsers();
         } else {
            openAlertMessage(responseData.message, "warning");
         }
      } catch (err) {
         handleError(err);
      }
   }

   function getTheme() {
      try {
         if (themeCounter === 1) {
            themeCounter = 2;
            return "UserView-card-header1";
         }

         if (themeCounter === 2) {
            themeCounter = 3;
            return "UserView-card-header2";
         }

         if (themeCounter === 3) {
            themeCounter = 1;
            return "UserView-card-header3";
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function deleteUser() {
      try {
         setDeleteModal(!deleteModal);

         const responseData = await removeUser(selectedUser._id ? selectedUser._id : "");

         if (responseData.success) {
            openAlertMessage("Successfully deleted user.", "success");
            getUsers();
         } else {
            openAlertMessage(responseData.message, "warning");
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function onChangeFilter(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      try {
         let filteredObjects = _.orderBy(
            _.filter(
               originalUsers,
               (x) =>
                  x.email.toLowerCase().includes(filter.toLowerCase()) ||
                  x.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                  x.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                  DateFormatter(x.addDate) === filter,
            ),
            ["firstName"],
            ["asc"],
         );

         if (active === "Active") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === true);
         } else if (active === "Inactive") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === false);
         }

         //set the hook with the newly filtered data and it will render
         setUsers(filteredObjects);
         setFilter(event.target.value);
      } catch (err) {
         handleError(err);
      }
   }

   async function onChangeFilterActive(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      try {
         let filteredObjects = _.orderBy(
            _.filter(
               originalUsers,
               (x) =>
                  x.email.toLowerCase().includes(filter.toLowerCase()) ||
                  x.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                  x.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                  DateFormatter(x.addDate) === filter,
            ),
            ["firstName"],
            ["asc"],
         );

         if (event.target.value === "Active") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === true);
         } else if (event.target.value === "Inactive") {
            filteredObjects = _.filter(filteredObjects, (x) => x.active === false);
         }

         //set the hook with the newly filtered data and it will render
         setUsers(filteredObjects);
         setActive(event.target.value === "Active" ? "Active" : event.target.value === "Inactive" ? "Inactive" : "All");
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <>
         <div className="UserView">
            <div className="UserView-root">
               {loading ? (
                  <>
                     <CheckAnimation />
                  </>
               ) : (
                  <>
                     <div className="UserView-header">
                        <div
                           className={
                              drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                           }
                        >
                           <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              style={{ height: "60px" }}
                           >
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={2}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <Breadcrumbs separator={<NavigateNextIcon />}>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/users")}
                                    >
                                       <Typography noWrap variant="h6" style={{ fontWeight: 700 }}>
                                          {"Users"}
                                       </Typography>
                                    </Link>
                                 </Breadcrumbs>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 6 : 4}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <TextField
                                    id="tf-search"
                                    placeholder="Search..."
                                    variant="outlined"
                                    name="search"
                                    size={"small"}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <SearchIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                    value={filter}
                                    onChange={(event) => onChangeFilter(event)}
                                 />
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 3 : 2}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "50px" }}
                              >
                                 <TextField
                                    select
                                    id="tf-active"
                                    variant="outlined"
                                    name="active"
                                    size="small"
                                    value={active}
                                    label={"Active"}
                                    onChange={(event) => onChangeFilterActive(event)}
                                 >
                                    {activeOptions.map((option) => (
                                       <MenuItem key={option} value={option}>
                                          {option}
                                       </MenuItem>
                                    ))}
                                 </TextField>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 1 : 4}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              ></Grid>
                           </Grid>
                        </div>
                     </div>
                     <div
                        className={
                           drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                        }
                     >
                        <Collapse in={alertMessage ? true : false}>
                           <Alert
                              variant="filled"
                              severity={alertType}
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       setAlertMessage("");
                                    }}
                                 >
                                    <CloseIcon />
                                 </IconButton>
                              }
                           >
                              {alertMessage}
                           </Alert>
                        </Collapse>
                        <div className="UserView-body">
                           <div
                              className={
                                 drawerState.DrawerOpen === true ? "UserView-layout-drawer-open" : "UserView-layout"
                              }
                           >
                              <Grid container direction={"column"} item xs={12}>
                                 <Grid
                                    container
                                    direction={"row"}
                                    item
                                    xs={12}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                 >
                                    <div
                                       style={{
                                          display:
                                             userState.CurrentUser.security.toUpperCase() === "SUPER ADMIN"
                                                ? "block"
                                                : "none",
                                       }}
                                    >
                                       <Card className="UserView-card-active">
                                          <CardHeader
                                             title={
                                                <Typography noWrap variant="h5">
                                                   Add New User
                                                </Typography>
                                             }
                                          />
                                          <CardActions disableSpacing>
                                             <Grid
                                                container
                                                direction={"row"}
                                                item
                                                xs={12}
                                                justifyContent="center"
                                                style={{ height: "100%", width: "100%" }}
                                             >
                                                <Button
                                                   style={{ height: "210px", width: "250px" }}
                                                   onClick={() => setInviteModal(true)}
                                                >
                                                   <AddIcon style={{ height: "210px", width: "250px" }} />
                                                </Button>
                                             </Grid>
                                          </CardActions>
                                       </Card>
                                    </div>
                                    {users.map((user, index) => {
                                       return (
                                          <Card
                                             key={index}
                                             className={user.active ? "UserView-card-active" : "UserView-card-inactive"}
                                          >
                                             <CardHeader
                                                className={getTheme()}
                                                avatar={
                                                   <Avatar aria-label="avatar" className={classes.avatar}>
                                                      {user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}
                                                   </Avatar>
                                                }
                                                action={
                                                   <Chip
                                                      label={user.active ? "Active" : "Inactive"}
                                                      style={{ marginTop: "1.25rem" }}
                                                      className={
                                                         user.active ? classes.activeChip : classes.inactiveChip
                                                      }
                                                      size="small"
                                                   />
                                                }
                                                title={
                                                   <Typography style={{ maxWidth: "140px" }} noWrap variant="h6">
                                                      {user.firstName + " " + user.lastName}
                                                   </Typography>
                                                }
                                             />
                                             <Divider className={classes.divider} />
                                             <CardContent>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography noWrap>
                                                      <b>Email: </b> {user.email}
                                                   </Typography>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography noWrap>
                                                      <b>First Name: </b> {user.firstName}
                                                   </Typography>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography noWrap>
                                                      <b>Last Name: </b> {user.lastName}
                                                   </Typography>
                                                </Grid>
                                                <Grid container direction={"row"} item xs={12} alignItems="center">
                                                   <Typography>
                                                      <b>Companies: </b>
                                                      {userCompanies
                                                         .filter((x) => x._id === user._id)
                                                         .map((x) => {
                                                            return x.companiesList;
                                                         })}
                                                   </Typography>
                                                </Grid>
                                             </CardContent>
                                             <CardActions disableSpacing>
                                                <Grid container direction={"row"} item xs={12}>
                                                   <Grid
                                                      container
                                                      item
                                                      xs={6}
                                                      alignItems="center"
                                                      justifyContent="flex-start"
                                                   >
                                                      <Chip
                                                         label={user.security}
                                                         className={
                                                            user.security.toUpperCase() === "SUPER ADMIN"
                                                               ? classes.superAdminChip
                                                               : user.security.toUpperCase() === "ADMIN"
                                                               ? classes.adminChip
                                                               : classes.readOnlyChip
                                                         }
                                                         size="small"
                                                      />
                                                   </Grid>
                                                   <Grid
                                                      container
                                                      item
                                                      xs={6}
                                                      alignItems="center"
                                                      justifyContent="flex-end"
                                                   >
                                                      <Tooltip title="Edit User" arrow>
                                                         <IconButton
                                                            onClick={(event) => handleDetailsClick(event, index)}
                                                         >
                                                            <EditIcon color="primary" />
                                                         </IconButton>
                                                      </Tooltip>
                                                      <div
                                                         style={{
                                                            display:
                                                               user.active === false && user.invite ? "block" : "none",
                                                         }}
                                                      >
                                                         <Tooltip title="Resend Invite" arrow>
                                                            <IconButton
                                                               onClick={(event) => handleInviteClick(event, index)}
                                                            >
                                                               <EmailIcon />
                                                            </IconButton>
                                                         </Tooltip>
                                                      </div>
                                                      <div
                                                         style={{
                                                            display:
                                                               user._id === CookieTools.getCookie("ca-user-id") ||
                                                               userState.CurrentUser.security.toUpperCase() !==
                                                                  "SUPER ADMIN"
                                                                  ? "none"
                                                                  : "block",
                                                         }}
                                                      >
                                                         <Tooltip title="Delete User" arrow>
                                                            <IconButton
                                                               onClick={(event) => handleDeleteClick(event, index)}
                                                            >
                                                               <DeleteForeverIcon color="secondary" />
                                                            </IconButton>
                                                         </Tooltip>
                                                      </div>
                                                   </Grid>
                                                </Grid>
                                             </CardActions>
                                          </Card>
                                       );
                                    })}
                                 </Grid>
                              </Grid>
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
         <DeleteDialog
            open={deleteModal}
            handleDelete={() => deleteUser()}
            handleClose={() => setDeleteModal(!deleteModal)}
            title={selectedUser && `Delete ${selectedUser.firstName + " " + selectedUser.lastName}`}
            text={selectedUser && `Are you sure you want to delete ${selectedUser.email}?`}
         />
         <UserNew
            open={inviteModal}
            handleClose={() => {
               setInviteModal(!inviteModal);
               getUsers();
            }}
            openAlertMessage={openAlertMessage}
         />
      </>
   );
};

export default UserView;
