import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React from "react";
import InputMask from "react-input-mask";
import { Applications } from "../../types/Applications";
import { countryList } from "../../utilities/CountryList";
import "./Apply.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         width: "100%",
      },
      divider: {
         backgroundColor: "#000000",
         height: "2px",
         marginBottom: "1rem",
      },
      buttonRemove: {
         width: "100%",
      },
      labelRemove: {
         flexDirection: "column",
         fontSize: "11px",
      },
      icon: {
         fontSize: "24px",
      },
   }),
);

export const TradeReference: React.FC<any> = (props: {
   application: Applications;
   handleChangeTradeRefInfo: (
      event:
         | React.ChangeEvent<HTMLInputElement>
         | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
         | React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
           }>,
      value: "company" | "city" | "state" | "country" | "phone" | "email" | "contact",
      index: number,
   ) => void;
   addTradeRefInfo: () => void;
   removeTradeRefInfo: (index: number) => void;
   errorHighlight: {
      tradeRefInfo: [
         {
            company: { error: false; message: "" };
            city: { error: false; message: "" };
            state: { error: false; message: "" };
            country: { error: false; message: "" };
            phone: { error: false; message: "" };
            email: { error: false; message: "" };
            contact: { error: false; message: "" };
         },
      ];
   };
}): JSX.Element => {
   const classes = useStyles();

   return (
      <>
         <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
               <h1>Trade References</h1>
               <Typography style={{ fontSize: "16px" }}>
                  {`Minimum of 3 product-related references must be provided. `}{" "}
                  <b>
                     <u>{`No Steamship Lines!`}</u>
                  </b>
               </Typography>
            </Grid>
         </Grid>
         {props.application.tradeRefInfo.map((trade, index) => {
            return (
               <>
                  <h3>{`Reference ${index + 1}`}</h3>
                  <Divider className={classes.divider} />
                  <Paper elevation={3} key={`paper-${index}`} style={{ marginBottom: "1rem" }}>
                     <Grid container direction="row">
                        <Grid container item xs={11} direction="column">
                           <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                           >
                              <Grid item xs={4}>
                                 <TextField
                                    required={true}
                                    id={`tf-company-${index.toString()}`}
                                    label="Company"
                                    variant="outlined"
                                    onChange={(event) => props.handleChangeTradeRefInfo(event, "company", index)}
                                    value={trade.company}
                                    helperText={
                                       props.errorHighlight.tradeRefInfo[index].company.error
                                          ? props.errorHighlight.tradeRefInfo[index].company.message
                                          : " "
                                    }
                                    error={props.errorHighlight.tradeRefInfo[index].company.error ? true : false}
                                    inputProps={{ maxLength: 100 }}
                                 />
                              </Grid>
                              <Grid item xs={3}>
                                 <TextField
                                    required={true}
                                    id={`tf-city-${index.toString()}`}
                                    label="City"
                                    variant="outlined"
                                    onChange={(event) => props.handleChangeTradeRefInfo(event, "city", index)}
                                    value={trade.city}
                                    helperText={
                                       props.errorHighlight.tradeRefInfo[index].city.error
                                          ? props.errorHighlight.tradeRefInfo[index].city.message
                                          : " "
                                    }
                                    error={props.errorHighlight.tradeRefInfo[index].city.error ? true : false}
                                    inputProps={{ maxLength: 50 }}
                                 />
                              </Grid>
                              <Grid item xs={1}>
                                 <TextField
                                    required={true}
                                    id={`tf-state-${index.toString()}`}
                                    label="State"
                                    variant="outlined"
                                    onChange={(event) => props.handleChangeTradeRefInfo(event, "state", index)}
                                    value={trade.state}
                                    helperText={
                                       props.errorHighlight.tradeRefInfo[index].state.error
                                          ? props.errorHighlight.tradeRefInfo[index].state.message
                                          : " "
                                    }
                                    error={props.errorHighlight.tradeRefInfo[index].state.error ? true : false}
                                    inputProps={{ maxLength: 2 }}
                                 />
                              </Grid>
                              <Grid item xs={4}>
                                 <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel
                                       id={`lbl-country-${index.toString()}`}
                                       style={{
                                          color: props.errorHighlight.tradeRefInfo[index].country.error
                                             ? "red"
                                             : "inherit",
                                       }}
                                    >
                                       Country *
                                    </InputLabel>
                                    <Select
                                       required={true}
                                       labelId={`lbl-country-${index.toString()}`}
                                       id={`tf-country-${index.toString()}`}
                                       className="country"
                                       variant="outlined"
                                       onChange={(event) => props.handleChangeTradeRefInfo(event, "country", index)}
                                       defaultValue={""}
                                       value={trade.country}
                                       label="Country"
                                       error={props.errorHighlight.tradeRefInfo[index].country.error ? true : false}
                                    >
                                       {countryList.map((country, index) => {
                                          return (
                                             <MenuItem key={index} value={country.name}>
                                                {country.name}
                                             </MenuItem>
                                          );
                                       })}
                                    </Select>
                                    <FormHelperText style={{ color: "red" }}>
                                       {props.errorHighlight.tradeRefInfo[index].country.error
                                          ? props.errorHighlight.tradeRefInfo[index].country.message
                                          : " "}
                                    </FormHelperText>
                                 </FormControl>
                              </Grid>
                           </Grid>
                           <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                           >
                              <Grid item xs={4}>
                                 <TextField
                                    required={true}
                                    id={`tf-contact-${index.toString()}`}
                                    label="Contact"
                                    variant="outlined"
                                    onChange={(event) => props.handleChangeTradeRefInfo(event, "contact", index)}
                                    value={trade.contact}
                                    helperText={
                                       props.errorHighlight.tradeRefInfo[index].contact.error
                                          ? props.errorHighlight.tradeRefInfo[index].contact.message
                                          : " "
                                    }
                                    error={props.errorHighlight.tradeRefInfo[index].contact.error ? true : false}
                                    inputProps={{ maxLength: 100 }}
                                 />
                              </Grid>
                              <Grid item xs={4}>
                                 <TextField
                                    id={`tf-email-${index.toString()}`}
                                    label="Email"
                                    variant="outlined"
                                    onChange={(event) => props.handleChangeTradeRefInfo(event, "email", index)}
                                    value={trade.email}
                                    helperText={
                                       props.errorHighlight.tradeRefInfo[index].email.error
                                          ? props.errorHighlight.tradeRefInfo[index].email.message
                                          : " "
                                    }
                                    error={props.errorHighlight.tradeRefInfo[index].email.error ? true : false}
                                 />
                              </Grid>
                              <Grid item xs={4}>
                                 <InputMask
                                    mask="999-999-9999"
                                    value={trade.phone}
                                    onChange={(event) => props.handleChangeTradeRefInfo(event, "phone", index)}
                                 >
                                    {() => (
                                       <TextField
                                          required={true}
                                          id={`tf-phone-${index.toString()}`}
                                          label="Phone"
                                          variant="outlined"
                                          error={props.errorHighlight.tradeRefInfo[index].phone.error ? true : false}
                                          inputProps={{
                                             autocomplete: "new-password",
                                             form: {
                                                autocomplete: "off",
                                             },
                                          }}
                                       />
                                    )}
                                 </InputMask>
                                 <FormHelperText style={{ color: "red" }}>
                                    {props.errorHighlight.tradeRefInfo[index].phone.error
                                       ? props.errorHighlight.tradeRefInfo[index].phone.message
                                       : " "}
                                 </FormHelperText>
                              </Grid>
                           </Grid>
                        </Grid>
                        {index > 2 ? (
                           <Grid container item xs={1} direction="column" key={index}>
                              <Grid
                                 item
                                 xs={12}
                                 container
                                 spacing={2}
                                 justifyContent="space-between"
                                 alignItems="center"
                              >
                                 <Button
                                    id={`btn-delete-${index.toString()}`}
                                    classes={{ root: classes.buttonRemove, label: classes.labelRemove }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => props.removeTradeRefInfo(index)}
                                 >
                                    <DeleteForeverIcon className={classes.icon} />
                                    Delete
                                 </Button>
                              </Grid>
                           </Grid>
                        ) : null}
                     </Grid>
                  </Paper>
               </>
            );
         })}
         <Grid container xs={12} direction="row" justifyContent="center" alignItems="center">
            <Fab color="primary" size="small" aria-label="add" onClick={() => props.addTradeRefInfo()}>
               <AddIcon />
            </Fab>
         </Grid>
      </>
   );
};

export default TradeReference;
