import {
   Box,
   Button,
   Card,
   Checkbox,
   createStyles,
   FormControl,
   FormControlLabel,
   FormHelperText,
   InputAdornment,
   makeStyles,
   Theme,
   Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import React, { useState } from "react";
import { Applications } from "../../../types/Applications";
import { DateFormatter } from "../../../utilities/DateFormatter";
import AdoptSign from "./AdoptSign";
import { useEsignStyles } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         width: "100%",
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
   }),
);

export const SignComplete: React.FC<any> = (props: {
   application: Applications;
   setApplication: (value: React.SetStateAction<Applications>) => void;
   handleChange: (event: string, value: "signature" | "printName" | "signatureTitle") => void;
   handleCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
   errorHighlight: {
      signature: { error: boolean; message: string };
      signatureTitle: { error: boolean; message: string };
      termsAndConditions: { error: boolean; message: string };
   };
   division: string;
}): JSX.Element => {
   const classes = useEsignStyles();
   const [adoptAndSignModal, setAdoptAndSignModal] = useState(false);

   return (
      <>
         <Grid container direction="row" alignItems="center">
            <Card className={classes.termsContainer} elevation={0}>
               <Typography style={{ fontSize: "16px", fontWeight: "bold", padding: "0px" }}>
                  Terms and Conditions:
               </Typography>
               <ol>
                  <li>All new clients will be COD, unless otherwise negotiated.</li>
                  <li>
                     Final Shipment Policy: if client has no other freight in UWL/WDS custody, all outstanding balances
                     must be paid in full before releasing the final shipment. Including outstanding amounts within
                     terms or with other subsidiaries.
                  </li>
                  <li>
                     If Credit has been negotiated, Credit terms can be rescinded or adjusted at any time and for any
                     reason.
                  </li>
                  <li>Late payments are subject to a 1.5% surcharge.</li>
                  <li>
                     Standard Invoicing Policy (not applicable for charters/new liner services): UWL will bill ocean
                     freight, base dray + FSC, and AMS approximately 5 days before arrival at port of discharge.
                     Additional charges will be billed upon empty return as part of a supplemental invoice. WDS will
                     invoice for all activity upon completion and will invoice for all storage on the first of the
                     month, for the coming month, unless otherwise agreed upon.
                  </li>
                  <li>
                     The above Business Details and Bank Information may be supplied on a supplemental document on your
                     company letterhead, but signature is still required below as acceptance of Terms and Conditions.
                  </li>
               </ol>
               <Box className={classes.agreementContainer}>
                  <FormControlLabel
                     control={
                        <Checkbox size="medium" color="primary" onChange={(event) => props.handleCheckChange(event)} />
                     }
                     label={<b>{"I Agree to the Terms and Conditions."}</b>}
                  />
                  {props.errorHighlight.termsAndConditions.error ? (
                     <Grid spacing={0} item xs={12} style={{ marginTop: "-20px" }}>
                        <FormControl
                           required
                           error={props.errorHighlight.termsAndConditions.error ? true : false}
                           component="fieldset"
                        >
                           <FormHelperText>
                              {props.errorHighlight.termsAndConditions.error
                                 ? props.errorHighlight.termsAndConditions.message
                                 : null}
                           </FormHelperText>
                        </FormControl>
                     </Grid>
                  ) : (
                     <></>
                  )}
               </Box>
            </Card>
         </Grid>

         <Card className={classes.note} elevation={0}>
            *** Credit Application must be signed by a member of Company Management. ***
         </Card>

         <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item style={{ paddingLeft: "0px" }} xs={8}>
               <TextField
                  id="tf-signature"
                  label="Signature"
                  variant="outlined"
                  onChange={(event) => event.preventDefault()}
                  value={props.application.signature}
                  InputProps={{
                     style: { fontFamily: '"Brush Script MT"', fontSize: "22px" },
                     endAdornment: !props.application.signature ? (
                        <InputAdornment position="end">
                           <Button
                              style={{
                                 backgroundColor: "primary",
                                 marginRight: "-.90rem",
                                 height: "3.5rem",
                                 fontSize: "15px",
                              }}
                              onClick={() => setAdoptAndSignModal(true)}
                              variant="contained"
                              color="primary"
                           >
                              {"Adopt Signature"}
                              <CreateIcon style={{ marginLeft: "15px" }} />
                           </Button>
                        </InputAdornment>
                     ) : null,
                  }}
                  helperText={props.errorHighlight.signature.error ? props.errorHighlight.signature.message : null}
                  error={!!props.errorHighlight.signature.error}
               />
            </Grid>
            <Grid item style={{ paddingRight: "0px" }} xs={4}>
               <TextField
                  required={true}
                  disabled={true}
                  id="tf-signatureDate"
                  label="Date"
                  variant="outlined"
                  value={
                     props.application.signatureDate
                        ? DateFormatter(props.application.signatureDate)
                        : DateFormatter(Date.now())
                  }
                  InputProps={{
                     style: { fontFamily: '"Brush Script MT"' },
                  }}
                  helperText={props.errorHighlight.signature.error ? " " : null}
               />
            </Grid>
         </Grid>
         <Grid container direction="row" alignItems="center">
            <Grid item style={{ paddingLeft: "0px" }} xs={6}>
               <TextField
                  disabled={true}
                  required={true}
                  id="tf-printName"
                  label="Printed Name"
                  variant="outlined"
                  value={props.application.signature}
                  helperText={props.errorHighlight.signatureTitle.error ? " " : null}
               />
            </Grid>
            <Grid item style={{ paddingRight: "0px" }} xs={6}>
               <TextField
                  required={true}
                  id="tf-title"
                  label="Title"
                  variant="outlined"
                  onChange={(event) => props.handleChange(event.target.value, "signatureTitle")}
                  value={props.application.signatureTitle}
                  helperText={
                     props.errorHighlight.signatureTitle.error ? props.errorHighlight.signatureTitle.message : null
                  }
                  error={props.errorHighlight.signatureTitle.error ? true : false}
                  inputProps={{ maxLength: 100 }}
               />
            </Grid>
         </Grid>
         <AdoptSign
            open={adoptAndSignModal}
            handleClose={() => {
               setAdoptAndSignModal(!adoptAndSignModal);
            }}
            application={props.application}
            setApplication={props.setApplication}
            division={props.division}
         />
      </>
   );
};

export default SignComplete;
