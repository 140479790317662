import { Collapse } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { passwordRequest } from "../../api/UserApi";
import { Users } from "../../types/Users";
import { validateEmail } from "../../utilities/ValidationTools";
import "./ForgotPassword.css";

export const ForgotPassword: React.FC<any> = (): JSX.Element => {
   const handleError = useErrorHandler();
   const history = useHistory();
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");

   const [user, setUser] = useState<Users>({
      _id: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      active: false,
      companies: [],
      divisions: [],
      security: "",
      invite: "",
      maximumApprovalAmount: 0,
      department: "",
      addDate: new Date(),
      deleteDate: null,
      homePage: {
         page: "",
         view: "",
      },
   });

   const submitPasswordReset = async () => {
      try {
         const newAlert = { ...alert };
         const emailValidation = validateEmail(user.email);

         if (emailValidation.length) {
            openAlertMessage(emailValidation, "warning");
            return;
         } else {
            const responseData = await passwordRequest(user);

            if (responseData.success == false) {
               openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
               return;
            } else {
               openAlertMessage(
                  "If a user with the provided email exists, a password reset email was sent.",
                  "success",
               );
               return;
            }
         }
      } catch (err) {
         handleError(err);
      }
   };

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   const onChange = async (
      key: "email",
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
   ) => {
      try {
         const newUser = _.cloneDeep(user);
         newUser[key] = event?.target?.value ?? "";
         setUser(newUser);
      } catch (err) {
         handleError(err);
      }
   };

   return (
      <>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <div className={"divbody-forgotpw grid-container-forgotpw"}>
            <div className="blurred-boxLogin-forgotpw grid-item-content-forgotpw">
               <div className="login-forgotpw">
                  <h1>Forgot Password</h1>
                  <p>A reset link will be sent to your email if there is an account registered to your email.</p>
                  <FormControl>
                     <Input
                        id="inputLoginTextbox"
                        className="inputTextbox-forgotpw"
                        name="email"
                        type="text"
                        autoFocus={true}
                        placeholder="Email"
                        value={user?.email}
                        onChange={(event) => onChange("email", event)}
                     />
                  </FormControl>
                  <Button id="resetButton" variant="contained" color="primary" onClick={() => submitPasswordReset()}>
                     Submit Password Reset
                  </Button>
                  <br />
                  <br />
                  <Button
                     id="cancelButton"
                     variant="contained"
                     color="secondary"
                     onClick={() => history.push("/login")}
                  >
                     Cancel
                  </Button>
               </div>
            </div>
         </div>
      </>
   );
};

export default ForgotPassword;
