import {
   Box,
   Button,
   Chip,
   CircularProgress,
   Dialog,
   Divider,
   FormControl,
   FormHelperText,
   Grid,
   MenuItem,
   Select,
   TextField,
   Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import _ from "lodash";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { sendInvite } from "../../api/CustomerActivationApi";
import {
   ActivationAlert,
   AlertProps,
   AlertVariants,
} from "../../components/Activation/ActivationAlert/ActivationAlert";
import { FormLabel } from "../../components/Activation/ActivationInvite/ActivationInvite.styled";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { UserContext } from "../../stores/Users/Context";
import { Companies } from "../../types/Companies";
import { Contacts } from "../../types/Contacts";
import { CustomerActivation } from "../../types/CustomerActivation";
import { CustomerActivationEmailTemplate } from "../../types/CustomerActivationEmailTemplate";
import { Divisions } from "../../types/Divisions";
import { validateEmailAddress } from "../../utilities/validateEmailAddress";

interface FormStateErrors {
   email: string;
   company: string;
   contacts: string;
   link: string;
}

type ActivationInviteProps = {
   showAlert: () => void;
};

export const ActivationInvite: React.FC<ActivationInviteProps> = ({ showAlert }) => {
   const [open, setOpen] = useState(false);
   const [companyList, setCompanyList] = useState<Divisions[]>([]);
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [selectedCompany, setSelectedCompany] = useState("");
   const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);
   const [email, setEmail] = useState("");
   const [isSendingInvite, setIsSendingInvite] = useState(false);
   const [alert, setAlert] = useState<AlertProps>({
      isOpen: false,
      message: "",
      variant: "",
   });
   const [errors, setErrors] = useState<FormStateErrors>({
      email: "",
      company: "",
      contacts: "",
      link: "",
   });

   // context
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);
   // error handling
   const handleError = useErrorHandler();

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = (_?: unknown, reason?: string) => {
      if (reason === "backdropClick") return;

      setOpen(false);
   };

   const handleClearButtonClick = () => {
      // clear all fields
      setEmail("");
      setSelectedCompany("");
      setSelectedContacts([]);
      setSelectedContactsList([]);
      // clear errors
      setErrors((errors) => ({ ...errors, email: "", company: "", contacts: "" }));

      handleClose();
   };

   const handleEmailInputChange = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setEmail(event.target.value);
   };

   const handleEmailInputBlur = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // validate email on blur
      const result = await validateEmailAddress(event.target.value);
      setErrors((errors) => ({ ...errors, email: result }));
   };

   const handleCompanyOrDivisionSelectChange = async (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
   ) => {
      const selectedValue = event.target.value ? String(event.target.value) : "";
      setSelectedCompany(selectedValue);

      const assignedDivision = divisionState.Divisions.find((x) => x._id.toString() === selectedValue);
      const assignedCompany = companyState.Companies.find((x) => x._id.toString() === selectedValue);

      const assignedContacts = assignedDivision
         ? assignedDivision.contacts
         : assignedCompany
         ? assignedCompany.contacts
         : [];

      const filteredContacts = assignedContacts.filter((x) => x.active === true);

      const sortedContacts = _.orderBy(filteredContacts, [(contact) => contact.name.toLowerCase()], ["asc"]);

      setContactList(sortedContacts);
      setSelectedContacts([]);
      setSelectedContactsList([]);
   };

   const handleCompanyOrDivisionSelectBlur = async () => {
      // validate company or division on blur
      const result = !selectedCompany ? "You must select at least one company or division." : "";
      setErrors((errors) => ({ ...errors, company: result }));
   };

   const handleContactsSelectChange = async (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const contacts = event.target.value ? (event.target.value as string[]) : [];
      setSelectedContacts(contacts);

      const contactAssigned = contactList.filter((contact) => contacts.includes(contacts ? contact._id : contacts));
      setSelectedContactsList(contactAssigned);
   };

   const handleContactsSelectBlur = async () => {
      // validate contacts on blur
      const result = selectedContacts.length === 0 ? "You must select at least one contact." : "";
      setErrors((errors) => ({ ...errors, contacts: result }));
   };

   const handleChipDelete = (id: string) => () => {
      setSelectedContactsList(selectedContactsList.filter((contact) => contact._id !== id));
      setSelectedContacts(selectedContacts.filter((_id) => _id !== id));
   };

   const sendActivationInvite = async () => {
      // initialize the loader
      setIsSendingInvite(true);

      try {
         // validate permissions
         if (userState.CurrentUser.security.toLocaleUpperCase() === "READ ONLY") return;
         // validate fields again, this could be in the case that the user has not touched the fields
         interface Validation {
            condition: boolean;
            field: string;
            message: string;
         }

         const validations: Validation[] = [
            { condition: !email, field: "email", message: "Email is required." },
            {
               condition: !selectedCompany,
               field: "company",
               message: "You must select at least one company or division.",
            },
            {
               condition: selectedContacts.length === 0,
               field: "contacts",
               message: "You must select at least one contact.",
            },
         ];

         const errorsToUpdate = validations.reduce((acc, { condition, field, message }) => {
            if (condition) acc[field as keyof FormStateErrors] = message;
            return acc;
         }, {} as FormStateErrors);

         // if there are errors, stop the execution and update the errors
         if (Object.keys(errorsToUpdate).length > 0) {
            setErrors((errors) => ({ ...errors, ...errorsToUpdate }));
            return;
         }

         const assignedDivisions = divisionState.Divisions.filter(
            ({ _id }) => _id.toString() === selectedCompany.toString(),
         );

         const assignedCompanies = companyState.Companies.filter(
            ({ _id }) => _id.toString() === selectedCompany.toString(),
         );

         const requiredProperties = [
            "invitationBody",
            "invitationTopNote",
            "receiptFirstParagraph",
            "receiptSecondParagraph",
            "approvedFirstParagraph",
            "approvedSecondParagraph",
            "statusParagraph",
         ];

         // props validation

         if (assignedDivisions.length > 0) {
            console.log(assignedDivisions[0]);
            // validate there is an email template
            if (!assignedDivisions[0].emailTemplate || !assignedDivisions[0].customerActivationEmailTemplate) {
               setErrors((errors) => ({
                  ...errors,
                  company: "Division does not have an email template set.",
               }));
               return;
            }
            // validate the rest of the fields
            if (
               !requiredProperties.every(
                  (property) =>
                     assignedDivisions[0].customerActivationEmailTemplate![
                        property as keyof CustomerActivationEmailTemplate
                     ],
               )
            ) {
               setErrors((errors) => ({
                  ...errors,
                  company: "This division is missing required text for activation emails.",
                  link: `/divisions/details/${assignedDivisions[0]._id}#CustomerActivationEmailTemplates`,
               }));
               return;
            }
         } else {
            // validate there is an email template
            if (!assignedCompanies[0].emailTemplate || !assignedCompanies[0].customerActivationEmailTemplate) {
               setErrors((errors) => ({
                  ...errors,
                  company: "Company does not have an email template set.",
               }));
               return;
            }
            // validate the rest of the fields
            if (
               !requiredProperties.every(
                  (property) =>
                     assignedCompanies[0].customerActivationEmailTemplate![
                        property as keyof CustomerActivationEmailTemplate
                     ],
               )
            ) {
               setErrors((errors) => ({
                  ...errors,
                  company: "This company is missing required text for activation emails.",
                  link: `/companies/details/${assignedCompanies[0]._id}#CustomerActivationEmailTemplates`,
               }));
               return;
            }
         }

         const data = {
            email: email,
            company_id: assignedDivisions.length > 0 ? assignedDivisions[0].company_id : selectedCompany.toString(),
            division_id: assignedDivisions.length > 0 ? assignedDivisions[0]._id : "",
            contacts: selectedContacts,
            notifiedBy_id: userState.CurrentUser._id,
         };

         const response = await sendInvite(data as CustomerActivation);

         if (!response.success) {
            // show error message
            setAlert({
               isOpen: true,
               message: "Something went wrong.",
               variant: AlertVariants.ERROR,
            });
            return;
         }

         // show success message
         showAlert();
         setOpen(false);
         setAlert({
            isOpen: false,
            message: "",
            variant: AlertVariants.SUCCESS,
         });
         // clear all fields
         setEmail("");
         setSelectedCompany("");
         setSelectedContacts([]);
         setSelectedContactsList([]);
         // clear errors
         setErrors((errors) => ({ ...errors, email: "", company: "", contacts: "" }));
      } catch (error) {
         handleError(error);
      } finally {
         setIsSendingInvite(false);
      }
   };

   // set companies and divisions for select
   useEffect(() => {
      // merge these for a clean list.
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         let newCompanyList: Divisions[] = [];

         companyState.Companies.forEach(function (company: Companies) {
            if (company.active === false || company.deleteDate !== null) {
               return;
            }

            const divisionsAssigned = _.filter(
               divisionState.Divisions,
               (x) => x.company_id === company._id && x.active === true && x.deleteDate === null,
            );

            if (divisionsAssigned.length > 0) {
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: [],
                     contacts: [],
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         newCompanyList = _.uniqBy(newCompanyList, "code");
         newCompanyList = _.orderBy(newCompanyList, ["code"], ["asc"]);

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState]);

   return (
      <>
         <Button variant="contained" color="primary" endIcon={<SendIcon />} onClick={handleClickOpen}>
            Activation Invite
         </Button>
         <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <Box style={{ padding: "36px", width: "458px" }}>
               {/* Dialog Title */}
               <Box
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     justifyContent: "center",
                     gap: "16px",
                     marginBottom: "24px",
                  }}
               >
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                     <mask
                        id="mask0_655_6094"
                        // style="mask-type:alpha"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="80"
                        height="80"
                     >
                        <rect width="80" height="80" fill="#D9D9D9" />
                     </mask>
                     <g mask="url(#mask0_655_6094)">
                        <path
                           d="M43.0835 36.8333L28.9168 22.6667L33.6668 18L43.0835 27.4167L62.0002 8.58333L66.6668 13.25L43.0835 36.8333ZM46.6668 75L23.3335 68.5V36.6667H29.8335L50.5002 44.3333C52.3335 45 53.8196 46.1667 54.9585 47.8333C56.0974 49.5 56.6668 51.3333 56.6668 53.3333H50.0002C47.6668 53.3333 45.8335 53.25 44.5002 53.0833C43.1668 52.9167 42.0002 52.6667 41.0002 52.3333L34.3335 50.1667L33.3335 53.3333L38.5835 55.25C40.139 55.8611 41.5557 56.25 42.8335 56.4167C44.1113 56.5833 45.6113 56.6667 47.3335 56.6667H63.3335C67.0002 56.6667 69.5835 57.2639 71.0835 58.4583C72.5835 59.6528 73.3335 61.2778 73.3335 63.3333V66.6667L46.6668 75ZM3.3335 73.3333V36.6667H16.6668V73.3333H3.3335Z"
                           fill="#010440"
                        />
                     </g>
                  </svg>
                  <Typography style={{ color: "#010440", fontSize: "24px", fontWeight: "700", lineHeight: "34px" }}>
                     Invite Customer
                  </Typography>
               </Box>
               <Divider />
               {/* Dialog Content */}
               <Box style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px" }}>
                  {/* Alert */}
                  <ActivationAlert isOpen={alert.isOpen} message={alert.message} variant={alert.variant} />
                  {/* Email */}
                  <FormLabel>Email</FormLabel>
                  <TextField
                     variant="outlined"
                     placeholder="Email"
                     name="email"
                     type="email"
                     size="small"
                     fullWidth
                     value={email}
                     onChange={handleEmailInputChange}
                     onBlur={handleEmailInputBlur}
                     inputProps={{ style: { height: "40px" } }}
                     error={Boolean(errors.email)}
                     helperText={errors.email}
                  />
                  {/* Company | Division */}
                  <FormLabel>Company | Division</FormLabel>
                  <FormControl size="small" variant="outlined" fullWidth error={Boolean(errors.company)}>
                     <Select
                        id="company-select"
                        name="company"
                        value={selectedCompany}
                        onChange={handleCompanyOrDivisionSelectChange}
                        onBlur={handleCompanyOrDivisionSelectBlur}
                     >
                        {companyList.map((company) => (
                           <MenuItem
                              key={company._id ? company._id : company.company_id}
                              value={company._id ? company._id : company.company_id}
                           >
                              {company.code + " | " + company.name}
                           </MenuItem>
                        ))}
                     </Select>
                     <FormHelperText>{errors.company}</FormHelperText>
                  </FormControl>
                  {errors.link && (
                     <Typography
                        style={{
                           color: "#f44336",
                           fontSize: "16px",
                           fontWeight: "400",
                           lineHeight: "20px",
                           textDecoration: "underline",
                           cursor: "pointer",
                        }}
                        onClick={() => window.open(errors.link, "_blank")}
                     >
                        Click here to set the email template for{" "}
                        {companyList.find((x) => x._id === selectedCompany)?.name}
                     </Typography>
                  )}
                  {/* Contacts */}
                  <FormLabel>Contacts</FormLabel>
                  <FormControl size="small" variant="outlined" fullWidth error={Boolean(errors.contacts)}>
                     <Select
                        multiple
                        id="contacts-select"
                        name="contacts"
                        disabled={contactList.length === 0}
                        value={selectedContacts}
                        onChange={handleContactsSelectChange}
                        onBlur={handleContactsSelectBlur}
                     >
                        {contactList.map((contact) => (
                           <MenuItem key={contact._id} value={contact._id}>
                              {contact.name}
                           </MenuItem>
                        ))}
                     </Select>
                     <FormHelperText>{errors.contacts}</FormHelperText>
                  </FormControl>
                  {/* Chips */}
                  <Grid container spacing={1}>
                     {selectedContactsList.map((contact) => (
                        <Grid key={contact._id} item xs={4}>
                           <Chip
                              label={contact.name}
                              variant="outlined"
                              color="primary"
                              style={{
                                 width: "100%",
                                 display: "flex",
                                 justifyContent: "space-between",
                                 alignItems: "center",
                                 padding: "0 6px",
                              }}
                              onDelete={handleChipDelete(contact._id)}
                           />
                        </Grid>
                     ))}
                  </Grid>
               </Box>
               {/* Dialog Actions */}
               <Box
                  style={{
                     marginTop: "36px",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "flex-end",
                     gap: "16px",
                  }}
               >
                  <Button
                     onClick={handleClearButtonClick}
                     color="primary"
                     variant="text"
                     startIcon={<CloseIcon />}
                     style={{ borderRadius: "100px", fontWeight: 600 }}
                  >
                     Close
                  </Button>
                  <Button
                     onClick={sendActivationInvite}
                     color="primary"
                     variant="contained"
                     disabled={isSendingInvite}
                     startIcon={
                        isSendingInvite ? (
                           <CircularProgress style={{ color: "#FFF", width: "20px", height: "20px" }} />
                        ) : (
                           <AddIcon />
                        )
                     }
                     style={{ borderRadius: "100px", fontWeight: 600 }}
                  >
                     Send Activation Invite
                  </Button>
               </Box>
            </Box>
         </Dialog>
      </>
   );
};
