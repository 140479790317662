import DateFnsUtils from "@date-io/date-fns";
import {
   Breadcrumbs,
   Button,
   ButtonGroup,
   Card,
   Chip,
   CircularProgress,
   Collapse,
   Divider,
   Drawer,
   FormControl,
   Grid,
   IconButton,
   InputAdornment,
   InputLabel,
   Link,
   List,
   ListItem,
   MenuItem,
   Select,
   TextField,
   Typography,
   useMediaQuery,
} from "@material-ui/core";
import { green, grey, red, yellow } from "@material-ui/core/colors";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
   AllInclusive as AllInclusiveIcon,
   Check as CheckIcon,
   Close as CloseIcon,
   Delete as DeleteIcon,
   Description as DescriptionIcon,
   Edit as EditIcon,
   FileCopy as FileCopyIcon,
   GetApp as GetAppIcon,
   Link as LinkIcon,
   Mail as MailIcon,
   Menu as MenuIcon,
   NavigateNext as NavigateNextIcon,
   Note as NoteIcon,
   Print as PrintIcon,
   Search as SearchIcon,
   ThumbDown as ThumbDownIcon,
   ThumbUp as ThumbUpIcon,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { saveAs } from "file-saver";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
   deleteApplication as deleteApplicationApi,
   getApplicationList,
   getApplicationPdf,
   getDocuSignPdf,
   sendInvite as sendInviteApi,
} from "../../api/ApplicationApi";
import CopyDialog from "../../components/CopyDialog/CopyDialog";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import NotesDialog from "../../components/NotesDialog/NotesDialog";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import CheckAnimation from "../../styles/CheckAnimation";
import { Applications } from "../../types/Applications";
import { Companies } from "../../types/Companies";
import { Divisions } from "../../types/Divisions";
import * as CookieTools from "../../utilities/CookieTools";
import { DUNSFormatter } from "../../utilities/DUNSFormatter";
import { DateTimeFormatterShortYear } from "../../utilities/DateTimeFormatterShortYear";
import ApplicationNew from "./ApplicationNew";
import "./ApplicationView.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      headerDrawerOpen: {
         marginLeft: 240,
      },
      headerDrawerClosed: {
         marginLeft: theme.spacing(7),
      },
      divider: {
         backgroundColor: "#778BA3",
         height: "3px",
         width: "100%",
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      labelMobile: {
         flexDirection: "column",
         fontSize: "7px",
      },
      inviteButton: {
         color: "white",
         backgroundColor: green[500],
         "&:hover": {
            backgroundColor: green[600],
         },
      },
      primaryGreen: {
         color: green[500],
      },
      primaryRed: {
         color: red[500],
      },
      chipGreen: {
         color: "white",
         backgroundColor: green[500],
      },
      chipRed: {
         color: "white",
         backgroundColor: red[500],
      },
      listDrawer: {
         minWidth: "300px",
         maxWidth: "360px",
      },
      notesButton: {
         color: "white",
         backgroundColor: "#FCC201",
         "&:hover": {
            backgroundColor: yellow[700],
         },
      },
      docuSignButton: {
         color: "white",
         backgroundColor: "#555555",
         "&:hover": {
            backgroundColor: grey[800],
         },
      },
      buttonProgress: {
         color: green[500],
      },
      chipContainer: {
         height: "100%",
         display: "flex",
         alignItems: "center",
         justifyContent: "left",
      },
   }),
);

type ApplicationViewProps = RouteComponentProps<{ view: string }>;

type statuses = "SENT" | "NEW" | "PENDING" | "REVIEWED";

export const ApplicationView: React.FC<ApplicationViewProps> = (props): JSX.Element => {
   //Theme/Error handler.
   const classes = useStyles();
   const history = useHistory();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
   const largeScreen = useMediaQuery(theme.breakpoints.down("lg"));
   const handleError = useErrorHandler();

   //Global Stores.
   const { state: userState } = useContext(UserContext);
   const { state: drawerState } = useContext(DrawerContext);

   //React Hook/Variables.
   let selectedView = "NEW" as statuses;
   if (props.match.params.view) {
      selectedView = props.match.params.view.toUpperCase() as statuses;
   }
   const [applications, setApplications] = useState<Applications[]>([]);
   const [loading, setLoading] = useState(true);
   const [inviteModal, setInviteModal] = useState(false);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [status, setStatus] = useState<statuses>(selectedView as statuses);
   const [applicationStatus, setApplicationStatus] = useState<"ALL" | "APPROVED" | "DENIED">("ALL");
   const [filter, setFilter] = useState<string>("");
   const [lastFilter, setLastFilter] = useState<string>("");
   const [deleteText, setDeleteText] = useState<string>("");
   const [selectedApplicationId, setSelectedApplicationId] = useState<string>("");
   const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const [companyList, setCompanyList] = useState<Divisions[]>([]);
   const [contactsAssigned, setContactsAssigned] = useState<{ _id: string; contactList: string }[]>([]);
   const [notesModal, setNotesModal] = useState<boolean>(false);
   const [copyModal, setCopyModal] = useState<boolean>(false);
   const [deleteModal, setDeleteModal] = useState<boolean>(false);
   const [viewing, setViewing] = useState<string[]>([]);
   const [downloading, setDownloading] = useState<string[]>([]);
   const [emailing, setEmailing] = useState<string[]>([]);
   const [printing, setPrinting] = useState<string[]>([]);
   const [docuSign, setDocuSign] = useState<string[]>([]);
   const [linkCopying, setLinkCopying] = useState<string[]>([]);
   const [resending, setResending] = useState<string[]>([]);
   const [page, setPage] = useState<number>(1);
   const [totalPages, setTotalPages] = useState<number>(1);

   const [applicationQuery, setApplicationQuery] = useState<{
      company: string;
      startDate: Date | null;
      endDate: Date | null;
   }>({
      company: "",
      startDate: null,
      endDate: null,
   });

   const [selectedApplication, setSelectedApplication] = useState<Applications>({
      _id: "",
      applicationNumber: "",
      email: "",
      companyName: "",
      customerType: "",
      customerSubType: "",
      brokerBond: null,
      companyEstablishedDate: null,
      accountsPayableEmail: "",
      companyEmail: "",
      companyContact: "",
      companyWebsite: "",
      ctpatNumber: "",
      address: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      billingAddress: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         email: "",
         phone: "",
         contact: "",
      },
      chb: {
         _id: "",
         uwlHandleChb: false,
         achImporter: false,
         companyName: "",
         contact: "",
         address: "",
         email: "",
         city: "",
         state: "",
         zip: "",
         phone: "",
      },
      companyPhone: "",
      status: "",
      amountReq: 0,
      numberEmployees: 0,
      annualRevenue: 0,
      method: {
         code: "",
         value: "",
      },
      ownership: "",
      documents: [],
      bankInfo: {
         duns: "",
         bankName: "",
         accountNumber: "",
         principalName: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         contact: "",
         phone: "",
         country: "",
         federalTaxNumber: "",
         paymentMethod: "",
      },
      tradeRefInfo: [
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
      ],
      tradeReferencesFileName: "",
      signature: "",
      signatureDate: null,
      signatureTitle: "",
      code: "",
      approvedBy_id: "",
      approvedAmount: 0,
      netTerms: "",
      company_id: "",
      division_id: "",
      isCOD: null,
      notes: "",
      companyCode: "",
      fedTax_id: "",
      referral: [],
      notifiedBy_id: "",
      notifiedDate: null,
      docuSignEnvelope_id: "",
      docuSignCompleted: null,
      approvedOrDeniedDate: null,
      contacts: [],
      applicationOpened: null,
      addDate: new Date(),
      deleteDate: null,
      tradeReferencesSkipped: false,
   });

   function breadcrumbOnClick(path: string) {
      if (path) {
         history.push(path);
      }
   }

   useEffect(() => {
      if (userState.CurrentUser._id) {
         getApplications();
      }
   }, [userState]);

   useEffect(() => {
      if (!loading) {
         if (status.toLowerCase() !== "reviewed") {
            setApplicationStatus("ALL");
         }

         if (page != 1) {
            setPage(1);
         } else {
            setLoading(true);
            getApplications();
         }
      }
   }, [status, applicationStatus, applicationQuery]);

   useEffect(() => {
      if (!loading) {
         if (status.toLowerCase() !== "reviewed") {
            setApplicationStatus("ALL");
         }

         setLoading(true);
         getApplications();
      }
   }, [page]);

   //Contact list Use Effect.
   useEffect(() => {
      if (
         applications.length > 0 &&
         companyState.Companies.length > 0 &&
         divisionState.Divisions.length > 0 &&
         status.toUpperCase() === "REVIEWED"
      ) {
         const newContactsAssigned: { _id: string; contactList: string }[] = [];

         _.forEach(applications, (application) => {
            const contactList: string[] = [];
            if (application.division_id) {
               const divisionsAssigned = _.filter(
                  divisionState.Divisions,

                  (x) => x._id === application.division_id,
               );
               if (divisionsAssigned.length > 0) {
                  const contactAssigned = _.filter(divisionsAssigned[0].contacts, (x) =>
                     application.contacts.includes(application.contacts ? x._id : application.contacts),
                  );
                  _.forEach(contactAssigned, (contact) => {
                     contactList.push(contact.name);
                  });
                  if (contactList.length > 0) {
                     newContactsAssigned.push({ _id: application._id, contactList: contactList.join(", ") });
                  } else {
                     newContactsAssigned.push({ _id: application._id, contactList: "" });
                  }
               }
            } else {
               const companiesAssigned = _.filter(
                  companyState.Companies,

                  (x) => x._id === application.company_id,
               );
               if (companiesAssigned.length > 0) {
                  const contactAssigned = _.filter(companiesAssigned[0].contacts, (x) =>
                     application.contacts.includes(application.contacts ? x._id : application.contacts),
                  );
                  _.forEach(contactAssigned, (contact) => {
                     contactList.push(contact.name);
                  });
                  if (contactList.length > 0) {
                     newContactsAssigned.push({ _id: application._id, contactList: contactList.join(", ") });
                  } else {
                     newContactsAssigned.push({ _id: application._id, contactList: "" });
                  }
               }
            }
         });
         setContactsAssigned(newContactsAssigned);
      }
   }, [applications, divisionState, companyState, status]);

   //States Use Effect.
   useEffect(() => {
      //Merge these for a clean list.
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0 && companyList.length === 0) {
         let newCompanyList: Divisions[] = [];
         const selectedCompanies = CookieTools.getCookie("selected-company");
         const selectedDivisions = CookieTools.getCookie("selected-division");

         //First, setup an all option.
         const newCompanyAll: Divisions[] = [
            {
               _id: "",
               company_id: "ALL",
               code: "ALL",
               name: "All Companies | Divisions",
               managers: [],
               active: true,
               prefix: "",
               counter: 0,
               addDate: null,
               deleteDate: null,
               invitationBody: "",
               invitationTopNote: "",
               emailTemplate: "",
               docuSignTemplateId: "",
               receiptFirstParagraph: "",
               receiptSecondParagraph: "",
               deniedFirstParagraph: "",
               deniedSecondParagraph: "",
               approvedFirstParagraph: "",
               approvedSecondParagraph: "",
               approvedBoldParagraph: "",
               contactApprovedBody: "",
               contactDeniedBody: "",
               duplicationNotification: "",
               contactDuplicationNotification: "",
               denialCodes: [],
               contacts: [],
               quickCreditAmount: 0,
            },
         ];

         newCompanyList = [...newCompanyList, ...newCompanyAll];

         companyState.Companies.forEach(function (company: Companies) {
            if (!selectedCompanies.includes(company._id)) {
               return;
            }

            const divisionsAssigned = _.orderBy(
               _.filter(
                  divisionState.Divisions,
                  (x) =>
                     x.company_id === company._id &&
                     selectedDivisions.includes(selectedDivisions ? x._id : selectedDivisions),
               ),
               ["name"],
               ["asc"],
            );

            if (divisionsAssigned.length > 0) {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: company.denialCodes,
                     contacts: company.contacts,
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: company.denialCodes,
                     contacts: company.contacts,
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState]);

   useEffect(() => {
      setLastFilter(filter);

      setTimeout(function () {
         setLastFilter("");
      }, 1500);
   }, [mobileScreen, openMenuDrawer]);

   async function getApplications(enterKeyValue = "") {
      try {
         const selectedCompanies = CookieTools.getCookie("selected-company");
         const selectedDivisions = CookieTools.getCookie("selected-division");

         const responseData = await getApplicationList(
            enterKeyValue ? enterKeyValue : filter,
            status,
            applicationStatus,
            applicationQuery,
            selectedCompanies,
            selectedDivisions,
            page,
         );

         if (responseData.success) {
            if (status.toLowerCase() === "reviewed") {
               setTotalPages(
                  responseData.applicationCount.length > 0
                     ? Math.ceil(
                          responseData.applicationCount[0].item2 / parseInt(responseData.applicationCount[0].item1),
                       )
                     : 1,
               );
            }

            setApplications(responseData.data);
         } else {
            openAlertMessage("Error loading applications. Please contact your administrator for assistance!", "error");
         }

         setLoading(false);
         setFilter(enterKeyValue ? enterKeyValue : filter);
         setLastFilter(enterKeyValue ? enterKeyValue : filter);
         setLastFilter("");
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function onBlurFilter(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      try {
         setFilter(event.target.value);
      } catch (err) {
         handleError(err);
      }
   }

   async function onChangeNotes(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: "notes") {
      try {
         if (value === "notes") {
            const applicationObj = { ...selectedApplication };
            applicationObj[value] = event.target.value;
            setSelectedApplication(applicationObj);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function handleSearchClick(enterKeyValue = "") {
      try {
         setLoading(true);
         getApplications(enterKeyValue);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleDetailsClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         history.push("/applications/details/" + applications[index]._id);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleNotesClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         setSelectedApplication(applications[index]);
         setNotesModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleCopyClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         setSelectedApplication(applications[index]);
         setCopyModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleViewClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         let finalViewing = [...viewing];
         finalViewing.push(applications[index]._id);
         setViewing(finalViewing);

         const responseData = await getApplicationPdf(applications[index]._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob));
            });
         } else {
            openAlertMessage(
               "Error loading PDF application. Please contact your administrator for assistance!",
               "error",
            );
         }

         const viewingIndex = finalViewing.indexOf(applications[index]._id);

         if (viewingIndex > -1) {
            finalViewing = [...finalViewing.slice(viewingIndex, -1)];
         }

         setViewing(finalViewing);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleDownloadClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         let finalDownloading = [...downloading];
         finalDownloading.push(applications[index]._id);
         setDownloading(finalDownloading);

         const responseData = await getApplicationPdf(applications[index]._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               saveAs(blob, `Application # ${applications[index].applicationNumber}.pdf`);
            });
         } else {
            openAlertMessage(
               "Error loading PDF application. Please contact your administrator for assistance!",
               "error",
            );
         }

         const downloadingIndex = finalDownloading.indexOf(applications[index]._id);

         if (downloadingIndex > -1) {
            finalDownloading = [...finalDownloading.slice(downloadingIndex, -1)];
         }

         setDownloading(finalDownloading);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleEmailClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         let finalEmailing = [...emailing];
         finalEmailing.push(applications[index]._id);
         setEmailing(finalEmailing);

         window.open(
            `mailto:?subject=${applications[index].companyName.replace(
               "&",
               "AND",
            )} Credit Request Needs Further Review&body=Application Details:%20${
               window.location.origin
            }/applications/details/${applications[index]._id}`,
         );

         const emailingIndex = finalEmailing.indexOf(applications[index]._id);

         if (emailingIndex > -1) {
            finalEmailing = [...finalEmailing.slice(emailingIndex, -1)];
         }

         setEmailing(finalEmailing);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function handlePrintClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         let finalPrinting = [...printing];
         finalPrinting.push(applications[index]._id);
         setPrinting(finalPrinting);

         const responseData = await getApplicationPdf(applications[index]._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob), "PRINT", "height=500,width=700");
            });
         } else {
            openAlertMessage(
               "Error loading PDF application. Please contact your administrator for assistance!",
               "error",
            );
         }

         const printingIndex = finalPrinting.indexOf(applications[index]._id);

         if (printingIndex > -1) {
            finalPrinting = [...finalPrinting.slice(printingIndex, -1)];
         }

         setPrinting(finalPrinting);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleDocuSignClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         if (applications[index].status.toUpperCase() !== "APPROVED") {
            openAlertMessage("Application is not approved!", "warning");
            return;
         }

         if (!applications[index].docuSignEnvelope_id) {
            openAlertMessage("DocuSign PDF is unavailable for this application!", "warning");
            return;
         }

         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to view the DocuSign PDF is denied!", "warning");
            return;
         }

         let finalDocuSign = [...docuSign];
         finalDocuSign.push(applications[index]._id);
         setDocuSign(finalDocuSign);

         const responseData = await getDocuSignPdf(applications[index]._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob));
            });
         } else {
            openAlertMessage("Error loading DocuSign PDF. Please contact your administrator for assistance!", "error");
         }

         const docuSignIndex = finalDocuSign.indexOf(applications[index]._id);

         if (docuSignIndex > -1) {
            finalDocuSign = [...finalDocuSign.slice(docuSignIndex, -1)];
         }

         setDocuSign(finalDocuSign);
      } catch (err) {
         openAlertMessage("Error loading DocuSign PDF. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleAppLinkClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to copy the application link is denied!", "warning");
            return;
         }

         let finalLinkCopying = [...linkCopying];
         finalLinkCopying.push(applications[index]._id);
         setLinkCopying(finalLinkCopying);

         let theme = "";

         if (applications[index].division_id) {
            const divisionAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id.toString() === applications[index].division_id.toString(),
            );

            theme =
               divisionAssigned[0].code === "BRI"
                  ? "Bristol"
                  : divisionAssigned[0].code === "MBT"
                  ? "MiddleBay"
                  : "CPG";
         } else {
            const companyAssigned = _.filter(
               companyState.Companies,
               (x) => x._id.toString() === applications[index].company_id.toString(),
            );

            theme = companyAssigned[0].prefix;
         }

         navigator.clipboard.writeText(
            window.location.href.replace("applications", "") +
               "Apply/" +
               theme +
               "/" +
               applications[index].email +
               "/" +
               applications[index]._id,
         );

         const linkCopyingIndex = finalLinkCopying.indexOf(applications[index]._id);

         if (linkCopyingIndex > -1) {
            finalLinkCopying = [...finalLinkCopying.slice(linkCopyingIndex, -1)];
         }

         setTimeout(function () {
            setLinkCopying(finalLinkCopying);
         }, 1000);
      } catch (err) {
         openAlertMessage("Error copying application link. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleNotesClose() {
      try {
         setNotesModal(false);
         setLoading(true);
         getApplications();
      } catch (err) {
         handleError(err);
      }
   }

   async function handleCopyClose() {
      try {
         setCopyModal(false);
         setLoading(true);
         getApplications();
      } catch (err) {
         handleError(err);
      }
   }

   async function handleStatusChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         setStatus(
            event.target.value === "SENT" ||
               event.target.value === "NEW" ||
               event.target.value === "PENDING" ||
               event.target.value === "REVIEWED"
               ? event.target.value
               : "NEW",
         );
      } catch (err) {
         handleError(err);
      }
   }

   //This should be async function handleEnterKey(event: React.KeyboardEvent<HTMLDivElement>) {
   //Bug in typing to prevent event.target.value even though it exists.
   async function handleEnterKey(event: any) {
      if (event.key === "Enter") {
         handleSearchClick(event.target.value);
      }
   }

   function getCompanyOrDivisionName(companyId: string, divisionId: string) {
      try {
         if (divisionId) {
            const divisionAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id.toString() === divisionId.toString(),
            );

            if (divisionAssigned.length >= 0) {
               return divisionAssigned[0].name;
            }
         }

         const companyAssigned = _.filter(companyState.Companies, (x) => x._id.toString() === companyId.toString());

         if (companyAssigned.length >= 0) {
            return companyAssigned[0].name;
         }
      } catch (err) {
         handleError(err);
      }
   }

   function checkIfExpired(date: Date) {
      try {
         const formattedDate = new Date(date);

         if (formattedDate.setDate(formattedDate.getDate() + 30) < Date.now()) {
            return true;
         }

         return false;
      } catch (err) {
         handleError(err);
      }
   }

   async function handleResendClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to resend an Application invite is denied!", "warning");
            return;
         }

         if (!applications[index].email) {
            openAlertMessage("You must enter in an email!", "warning");
            return;
         }

         if (!applications[index].company_id) {
            openAlertMessage("You must select at least one company or division!", "warning");
            return;
         }

         if (applications[index].contacts.length === 0) {
            openAlertMessage("You must select at least one contact!", "warning");
            return;
         }

         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id.toString() === applications[index].division_id.toString(),
         );

         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id.toString() === applications[index].company_id.toString(),
         );

         if (divisionAssigned.length > 0) {
            if (!divisionAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!divisionAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !divisionAssigned[0].invitationBody ||
               !divisionAssigned[0].invitationTopNote ||
               !divisionAssigned[0].receiptFirstParagraph ||
               !divisionAssigned[0].receiptSecondParagraph ||
               !divisionAssigned[0].deniedFirstParagraph ||
               !divisionAssigned[0].deniedSecondParagraph ||
               !divisionAssigned[0].approvedFirstParagraph ||
               !divisionAssigned[0].approvedSecondParagraph ||
               !divisionAssigned[0].approvedBoldParagraph ||
               !divisionAssigned[0].contactApprovedBody ||
               !divisionAssigned[0].contactDeniedBody ||
               !divisionAssigned[0].duplicationNotification ||
               !divisionAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         } else {
            if (!companyAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!companyAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !companyAssigned[0].invitationBody ||
               !companyAssigned[0].invitationTopNote ||
               !companyAssigned[0].receiptFirstParagraph ||
               !companyAssigned[0].receiptSecondParagraph ||
               !companyAssigned[0].deniedFirstParagraph ||
               !companyAssigned[0].deniedSecondParagraph ||
               !companyAssigned[0].approvedFirstParagraph ||
               !companyAssigned[0].approvedSecondParagraph ||
               !companyAssigned[0].approvedBoldParagraph ||
               !companyAssigned[0].contactApprovedBody ||
               !companyAssigned[0].contactDeniedBody ||
               !companyAssigned[0].duplicationNotification ||
               !companyAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         }

         let finalResending = [...resending];
         finalResending.push(applications[index]._id);
         setResending(finalResending);

         const newApplication: Applications = { ...applications[index] };
         newApplication.addDate = new Date();

         const responseData = await sendInviteApi(newApplication);

         if (responseData.success) {
            openAlertMessage("Application invite has been resent", "success");

            const linkResendingIndex = finalResending.indexOf(applications[index]._id);

            if (linkResendingIndex > -1) {
               finalResending = [...finalResending.slice(linkResendingIndex, -1)];
            }

            setResending(finalResending);
         } else {
            openAlertMessage(responseData.message, "warning");

            const linkResendingIndex = finalResending.indexOf(applications[index]._id);

            if (linkResendingIndex > -1) {
               finalResending = [...finalResending.slice(linkResendingIndex, -1)];
            }

            setResending(finalResending);
            return;
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function handleDeleteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to resend an Application invite is denied!", "warning");
            return;
         }

         setSelectedApplicationId(applications[index]._id);

         setDeleteText(
            `You are about to delete the application for ${
               applications[index].companyName ? applications[index].companyName : applications[index].email
            }`,
         );

         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleDeleteClose() {
      try {
         setDeleteModal(false);
         setLoading(true);
         getApplications();
      } catch (err) {
         handleError(err);
      }
   }

   async function deleteApplication() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to resend an Application invite is denied!", "warning");
            return;
         }

         const responseData = await deleteApplicationApi(selectedApplicationId);
         setDeleteModal(false);

         if (responseData.success) {
            openAlertMessage("Application invite has been deleted", "success");
            setLoading(true);
            getApplications();
         } else {
            openAlertMessage("Error dleteing application. Please contact your administrator for assistance!", "error");
         }
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <>
         <div className="ApplicationView">
            <div className="ApplicationView-root">
               {loading ? (
                  <>
                     <CheckAnimation />
                  </>
               ) : (
                  <>
                     <div className="ApplicationView-header">
                        <div
                           className={
                              drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                           }
                        >
                           <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              style={{ height: "60px" }}
                           >
                              {!mobileScreen ? (
                                 <Grid
                                    container
                                    direction="column"
                                    item
                                    xs={4}
                                    sm={2}
                                    md={2}
                                    lg={1}
                                    xl={1}
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    style={{ height: "60px" }}
                                 >
                                    <Breadcrumbs separator={<NavigateNextIcon />}>
                                       <Link
                                          color="inherit"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => breadcrumbOnClick("/applications")}
                                       >
                                          <Typography noWrap variant="h6" style={{ fontWeight: 700 }}>
                                             {"Applications"}
                                          </Typography>
                                       </Link>
                                    </Breadcrumbs>
                                 </Grid>
                              ) : (
                                 <></>
                              )}
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={6}
                                 sm={4}
                                 md={3}
                                 lg={2}
                                 xl={2}
                                 justifyContent="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <InputLabel>Application Status</InputLabel>
                                    <Select
                                       id="select-status"
                                       label="Application Status"
                                       defaultValue=""
                                       value={status}
                                       onChange={(event) => handleStatusChange(event)}
                                    >
                                       {userState.CurrentUser.security.toUpperCase() === "ADMIN" ||
                                       userState.CurrentUser.security.toUpperCase() === "SUPER ADMIN" ? (
                                          <MenuItem key={"SENT"} value={"SENT"}>
                                             Sent
                                          </MenuItem>
                                       ) : (
                                          <></>
                                       )}
                                       <MenuItem key={"NEW"} value={"NEW"}>
                                          New
                                       </MenuItem>
                                       <MenuItem key={"PENDING"} value={"PENDING"}>
                                          Pending
                                       </MenuItem>
                                       <MenuItem key={"REVIEWED"} value={"REVIEWED"}>
                                          Reviewed
                                       </MenuItem>
                                    </Select>
                                 </FormControl>
                              </Grid>
                              {!mobileScreen ? (
                                 <Grid
                                    container
                                    direction="column"
                                    item
                                    xs={5}
                                    sm={5}
                                    md={6}
                                    lg={4}
                                    xl={4}
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    style={{ height: "60px" }}
                                 >
                                    <Grid item container xs={12} direction={"row"} spacing={0}>
                                       <Grid item container direction={"column"} xs={11} spacing={0}>
                                          <Grid item xs={12} container alignItems="flex-start" justifyContent="center">
                                             <TextField
                                                id="tf-search"
                                                placeholder="Search..."
                                                variant="outlined"
                                                name="search"
                                                size={"small"}
                                                style={{ maxWidth: "375px" }}
                                                InputProps={{
                                                   endAdornment: (
                                                      <InputAdornment position="end">
                                                         <Button
                                                            color="primary"
                                                            variant="contained"
                                                            style={{
                                                               marginRight: "-.90rem",
                                                               height: "3.5rem",
                                                               borderTopLeftRadius: 0,
                                                               borderBottomLeftRadius: 0,
                                                            }}
                                                            classes={{
                                                               label: smallScreen ? classes.labelMobile : classes.label,
                                                            }}
                                                            onClick={() => handleSearchClick()}
                                                            disabled={
                                                               userState.CurrentUser.security.toUpperCase() ===
                                                               "READ ONLY"
                                                            }
                                                         >
                                                            <SearchIcon />
                                                            Search
                                                         </Button>
                                                      </InputAdornment>
                                                   ),
                                                }}
                                                value={lastFilter ? lastFilter : undefined}
                                                onBlur={(event) => onBlurFilter(event)}
                                                onKeyDown={(event) => handleEnterKey(event)}
                                             />
                                          </Grid>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              ) : (
                                 <></>
                              )}
                              {!mediumScreen ? (
                                 <Grid
                                    container
                                    direction="column"
                                    item
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    style={{ height: "60px" }}
                                 >
                                    <Button
                                       variant="contained"
                                       classes={{
                                          root: classes.inviteButton,
                                          label: smallScreen ? classes.labelMobile : classes.label,
                                       }}
                                       onClick={() => setInviteModal(true)}
                                       disabled={userState.CurrentUser.security.toUpperCase() === "READ ONLY"}
                                    >
                                       <MailIcon />
                                       Application Invite
                                    </Button>
                                 </Grid>
                              ) : (
                                 <></>
                              )}
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={6}
                                 sm={1}
                                 md={1}
                                 lg={3}
                                 xl={3}
                                 justifyContent="center"
                                 alignItems="flex-end"
                                 style={{ height: "60px" }}
                              >
                                 {!mediumScreen ? (
                                    <div
                                       style={{
                                          display: status.toLowerCase() === "reviewed" ? "block" : "none",
                                       }}
                                    >
                                       <ButtonGroup>
                                          <Button
                                             variant="contained"
                                             color={applicationStatus.toLowerCase() === "all" ? "primary" : undefined}
                                             style={{ width: largeScreen ? undefined : "100px" }}
                                             classes={{
                                                label: smallScreen ? classes.labelMobile : classes.label,
                                             }}
                                             onClick={() => setApplicationStatus("ALL")}
                                          >
                                             <AllInclusiveIcon />
                                             All
                                          </Button>
                                          <Button
                                             variant="contained"
                                             color={
                                                applicationStatus.toLowerCase() === "approved" ? "primary" : undefined
                                             }
                                             style={{ width: largeScreen ? undefined : "100px" }}
                                             classes={{
                                                label: smallScreen ? classes.labelMobile : classes.label,
                                             }}
                                             className={
                                                applicationStatus.toLowerCase() !== "approved"
                                                   ? classes.primaryGreen
                                                   : undefined
                                             }
                                             onClick={() => setApplicationStatus("APPROVED")}
                                          >
                                             <ThumbUpIcon />
                                             Approved
                                          </Button>
                                          <Button
                                             variant="contained"
                                             color={
                                                applicationStatus.toLowerCase() === "denied" ? "primary" : undefined
                                             }
                                             style={{ width: largeScreen ? undefined : "100px" }}
                                             classes={{
                                                label: smallScreen ? classes.labelMobile : classes.label,
                                             }}
                                             className={
                                                applicationStatus.toLowerCase() !== "denied"
                                                   ? classes.primaryRed
                                                   : undefined
                                             }
                                             onClick={() => setApplicationStatus("DENIED")}
                                          >
                                             <ThumbDownIcon />
                                             Denied
                                          </Button>
                                       </ButtonGroup>
                                    </div>
                                 ) : (
                                    <></>
                                 )}
                                 <IconButton
                                    color="primary"
                                    classes={{
                                       label: smallScreen ? classes.labelMobile : classes.label,
                                    }}
                                    onClick={() => setOpenMenuDrawer(true)}
                                 >
                                    <MenuIcon />
                                    Menu
                                 </IconButton>
                              </Grid>
                           </Grid>
                        </div>
                     </div>
                     <div
                        className={
                           drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                        }
                     >
                        <Collapse in={alertMessage ? true : false}>
                           <Alert
                              variant="filled"
                              severity={alertType}
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       setAlertMessage("");
                                    }}
                                 >
                                    <CloseIcon />
                                 </IconButton>
                              }
                           >
                              {alertMessage}
                           </Alert>
                        </Collapse>
                        <Drawer anchor={"right"} open={openMenuDrawer} onClose={() => setOpenMenuDrawer(false)}>
                           <div style={{ backgroundColor: "#002D72" }}>
                              <h3 style={{ marginLeft: "1rem", fontWeight: 700, color: "white" }}>Menu</h3>
                           </div>
                           <List className={classes.listDrawer}>
                              {mediumScreen ? (
                                 <>
                                    <ListItem style={{ height: "4.5rem" }}>
                                       <Button
                                          variant="contained"
                                          classes={{
                                             root: classes.inviteButton,
                                             label: smallScreen ? classes.labelMobile : classes.label,
                                          }}
                                          onClick={() => {
                                             setInviteModal(true);
                                             setOpenMenuDrawer(false);
                                          }}
                                          disabled={userState.CurrentUser.security.toUpperCase() === "READ ONLY"}
                                          style={{ marginLeft: "auto", marginRight: "auto" }}
                                       >
                                          <MailIcon />
                                          Application Invite
                                       </Button>
                                    </ListItem>
                                    <Divider />
                                    <div
                                       style={{
                                          display: mobileScreen ? "block" : "none",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                       }}
                                    >
                                       <ListItem style={{ height: "2rem" }}>
                                          <h3 style={{ fontWeight: 700 }}>Application Search</h3>
                                       </ListItem>
                                       <ListItem style={{ display: "flex", justifyContent: "center" }}>
                                          <TextField
                                             id="tf-menu-search"
                                             placeholder="Search..."
                                             variant="outlined"
                                             name="search"
                                             size={"small"}
                                             style={{ maxWidth: "375px" }}
                                             InputProps={{
                                                endAdornment: (
                                                   <InputAdornment position="end">
                                                      <Button
                                                         color="primary"
                                                         variant="contained"
                                                         style={{
                                                            marginRight: "-.90rem",
                                                            height: "3.5rem",
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 0,
                                                         }}
                                                         classes={{
                                                            label: smallScreen ? classes.labelMobile : classes.label,
                                                         }}
                                                         onClick={() => handleSearchClick()}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                                         }
                                                      >
                                                         <SearchIcon />
                                                         Search
                                                      </Button>
                                                   </InputAdornment>
                                                ),
                                             }}
                                             value={lastFilter ? lastFilter : undefined}
                                             onBlur={(event) => onBlurFilter(event)}
                                             onKeyDown={(event) => handleEnterKey(event)}
                                          />
                                       </ListItem>
                                       <Divider />
                                    </div>
                                    <div
                                       style={{
                                          display: status.toLowerCase() === "reviewed" ? "block" : "none",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                       }}
                                    >
                                       <ListItem style={{ height: "2rem" }}>
                                          <h3 style={{ fontWeight: 700 }}>Reviewed Filters</h3>
                                       </ListItem>
                                       <ListItem style={{ height: "4.5rem" }}>
                                          <ButtonGroup
                                             style={{
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                             }}
                                          >
                                             <Button
                                                variant="contained"
                                                color={
                                                   applicationStatus.toLowerCase() === "all" ? "primary" : undefined
                                                }
                                                style={{
                                                   width: largeScreen ? undefined : "100px",
                                                }}
                                                classes={{
                                                   label: smallScreen ? classes.labelMobile : classes.label,
                                                }}
                                                onClick={() => setApplicationStatus("ALL")}
                                             >
                                                <AllInclusiveIcon />
                                                All
                                             </Button>
                                             <Button
                                                variant="contained"
                                                color={
                                                   applicationStatus.toLowerCase() === "approved"
                                                      ? "primary"
                                                      : undefined
                                                }
                                                style={{ width: largeScreen ? undefined : "100px" }}
                                                classes={{
                                                   label: smallScreen ? classes.labelMobile : classes.label,
                                                }}
                                                className={
                                                   applicationStatus.toLowerCase() !== "approved"
                                                      ? classes.primaryGreen
                                                      : undefined
                                                }
                                                onClick={() => setApplicationStatus("APPROVED")}
                                             >
                                                <ThumbUpIcon />
                                                Approved
                                             </Button>
                                             <Button
                                                variant="contained"
                                                color={
                                                   applicationStatus.toLowerCase() === "denied" ? "primary" : undefined
                                                }
                                                style={{ width: largeScreen ? undefined : "100px" }}
                                                classes={{
                                                   label: smallScreen ? classes.labelMobile : classes.label,
                                                }}
                                                className={
                                                   applicationStatus.toLowerCase() !== "denied"
                                                      ? classes.primaryRed
                                                      : undefined
                                                }
                                                onClick={() => setApplicationStatus("DENIED")}
                                             >
                                                <ThumbDownIcon />
                                                Denied
                                             </Button>
                                          </ButtonGroup>
                                       </ListItem>
                                       <Divider />
                                    </div>
                                 </>
                              ) : (
                                 <></>
                              )}
                              <ListItem style={{ height: "2rem" }}>
                                 <h3 style={{ fontWeight: 700 }}>Company | Division</h3>
                              </ListItem>
                              <ListItem style={{ display: "flex", justifyContent: "center" }}>
                                 <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <Select
                                       id="select-companies"
                                       defaultValue=""
                                       value={applicationQuery.company}
                                       onChange={(event) =>
                                          setApplicationQuery({
                                             ...applicationQuery,
                                             company: event.target.value ? (event.target.value as string) : "",
                                          })
                                       }
                                    >
                                       {companyList.map((company) => {
                                          return (
                                             <MenuItem
                                                style={{
                                                   fontWeight: company._id ? 500 : 700,
                                                   marginLeft: company._id ? "1rem" : "0rem",
                                                   color: company._id ? "#000000" : "#002D72",
                                                }}
                                                key={company._id ? company._id : company.company_id}
                                                value={company._id ? company._id : company.company_id}
                                             >
                                                {(company._id ? "  " : "") + company.code + " | " + company.name}
                                             </MenuItem>
                                          );
                                       })}
                                    </Select>
                                 </FormControl>
                              </ListItem>
                              <Divider />
                              <ListItem style={{ height: "2rem" }}>
                                 <h3 style={{ fontWeight: 700 }}>Date Range</h3>
                              </ListItem>
                              <ListItem>
                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                       disableToolbar
                                       variant="inline"
                                       format="MM/dd/yyyy"
                                       margin="normal"
                                       id="tf-startDate"
                                       style={{ marginRight: "1rem" }}
                                       label="Start Date"
                                       onChange={(event) =>
                                          setApplicationQuery({
                                             ...applicationQuery,
                                             startDate: event ? event : null,
                                          })
                                       }
                                       value={applicationQuery.startDate}
                                       KeyboardButtonProps={{
                                          "aria-label": "change date",
                                       }}
                                    />
                                    <KeyboardDatePicker
                                       disableToolbar
                                       variant="inline"
                                       format="MM/dd/yyyy"
                                       margin="normal"
                                       id="tf-endDate"
                                       style={{ marginRight: "1rem" }}
                                       label="End Date"
                                       onChange={(event) =>
                                          setApplicationQuery({
                                             ...applicationQuery,
                                             endDate: event ? event : null,
                                          })
                                       }
                                       value={applicationQuery.endDate}
                                       KeyboardButtonProps={{
                                          "aria-label": "change date",
                                       }}
                                    />
                                 </MuiPickersUtilsProvider>
                              </ListItem>
                              <Divider light />
                           </List>
                        </Drawer>
                        <div
                           className={
                              status.toLowerCase() === "reviewed"
                                 ? "ApplicationView-body-reviewed"
                                 : "ApplicationView-body"
                           }
                        >
                           <div
                              className={
                                 drawerState.DrawerOpen === true
                                    ? "ApplicationView-layout-drawer-open"
                                    : "ApplicationView-layout"
                              }
                           >
                              {status.toLowerCase() !== "reviewed" && status.toLowerCase() !== "sent"
                                 ? applications.map((application, index) => {
                                      return (
                                         <>
                                            <Card key={index}>
                                               <Grid
                                                  container
                                                  direction={"row"}
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                               >
                                                  <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           Application #
                                                        </Typography>
                                                        <Typography align="left" variant="h6">
                                                           {application.applicationNumber}
                                                        </Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                     <Typography
                                                        align="left"
                                                        noWrap
                                                        variant="h6"
                                                        style={{ color: "#010440", fontWeight: 700 }}
                                                     >
                                                        <div>
                                                           <Typography
                                                              align="left"
                                                              noWrap
                                                              variant="h6"
                                                              style={{ color: "#010440", fontWeight: 700 }}
                                                           >
                                                              Company Name
                                                           </Typography>
                                                           <Typography align="left" variant="h6">
                                                              {application.companyName}
                                                           </Typography>
                                                        </div>
                                                     </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           Contact Name
                                                        </Typography>
                                                        <Typography align="left" variant="h6">
                                                           {application.companyContact}
                                                        </Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           Address
                                                        </Typography>
                                                        <Typography align="left" variant="h6">
                                                           {`${application.address.city}, ${application.address.state} ${application.address.zip}`}
                                                        </Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={3} lg={1} xl={2}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           {largeScreen ? "Amt Req" : "Amount Requested"}
                                                        </Typography>
                                                        <Typography
                                                           align="left"
                                                           variant="h6"
                                                        >{`$${application.amountReq.toLocaleString(undefined, {
                                                           maximumFractionDigits: 2,
                                                        })}`}</Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={3} lg={1} xl={1}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           {largeScreen ? "Dt Recv" : "Date Received"}
                                                        </Typography>
                                                        <Typography align="left" variant="h6">
                                                           {DateTimeFormatterShortYear(application.signatureDate)}
                                                        </Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={3} lg={1} xl={1}>
                                                     {status.toLowerCase() === "pending" ? (
                                                        <ButtonGroup style={{ width: "100%" }}>
                                                           <Button
                                                              onClick={(event) => handleNotesClick(event, index)}
                                                              variant="contained"
                                                              color="primary"
                                                              classes={{
                                                                 root: classes.notesButton,
                                                                 label: largeScreen
                                                                    ? classes.labelMobile
                                                                    : classes.label,
                                                              }}
                                                           >
                                                              <NoteIcon />
                                                              Notes
                                                           </Button>
                                                           <Button
                                                              onClick={(event) => handleDetailsClick(event, index)}
                                                              variant="contained"
                                                              color="primary"
                                                              classes={{
                                                                 label: largeScreen
                                                                    ? classes.labelMobile
                                                                    : classes.label,
                                                              }}
                                                           >
                                                              <EditIcon />
                                                              Details
                                                           </Button>
                                                        </ButtonGroup>
                                                     ) : (
                                                        <Button
                                                           onClick={(event) => handleDetailsClick(event, index)}
                                                           variant="contained"
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                           }}
                                                           style={{ width: "100%" }}
                                                        >
                                                           <EditIcon />
                                                           Details
                                                        </Button>
                                                     )}
                                                  </Grid>
                                                  <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                                     <ButtonGroup>
                                                        <IconButton
                                                           onClick={(event) => handleViewClick(event, index)}
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                           }}
                                                           disabled={viewing.includes(application._id)}
                                                        >
                                                           {viewing.includes(application._id) ? (
                                                              <CircularProgress
                                                                 size={19}
                                                                 className={classes.buttonProgress}
                                                              />
                                                           ) : (
                                                              <SearchIcon />
                                                           )}
                                                           View
                                                        </IconButton>
                                                        <IconButton
                                                           onClick={(event) => handleDownloadClick(event, index)}
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                           }}
                                                           disabled={downloading.includes(application._id)}
                                                        >
                                                           {downloading.includes(application._id) ? (
                                                              <CircularProgress
                                                                 size={19}
                                                                 className={classes.buttonProgress}
                                                              />
                                                           ) : (
                                                              <GetAppIcon />
                                                           )}
                                                           Download
                                                        </IconButton>
                                                        <IconButton
                                                           onClick={(event) => handleEmailClick(event, index)}
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                           }}
                                                           disabled={emailing.includes(application._id)}
                                                        >
                                                           {emailing.includes(application._id) ? (
                                                              <CircularProgress
                                                                 size={19}
                                                                 className={classes.buttonProgress}
                                                              />
                                                           ) : (
                                                              <MailIcon />
                                                           )}
                                                           Email
                                                        </IconButton>
                                                        <IconButton
                                                           onClick={(event) => handlePrintClick(event, index)}
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                           }}
                                                           disabled={printing.includes(application._id)}
                                                        >
                                                           {printing.includes(application._id) ? (
                                                              <CircularProgress
                                                                 size={19}
                                                                 className={classes.buttonProgress}
                                                              />
                                                           ) : (
                                                              <PrintIcon />
                                                           )}
                                                           Print
                                                        </IconButton>
                                                     </ButtonGroup>
                                                  </Grid>
                                               </Grid>
                                               <Divider className={classes.divider} />
                                            </Card>
                                         </>
                                      );
                                   })
                                 : status.toLowerCase() === "sent"
                                 ? applications.map((application, index) => {
                                      return (
                                         <>
                                            <Card key={index}>
                                               <Grid
                                                  container
                                                  direction={"row"}
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                               >
                                                  <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           Date
                                                        </Typography>
                                                        <Typography align="left" variant="h6">
                                                           {DateTimeFormatterShortYear(application.addDate)}
                                                        </Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                     <Typography
                                                        align="left"
                                                        noWrap
                                                        variant="h6"
                                                        style={{ color: "#010440", fontWeight: 700 }}
                                                     >
                                                        <div>
                                                           <Typography
                                                              align="left"
                                                              noWrap
                                                              variant="h6"
                                                              style={{ color: "#010440", fontWeight: 700 }}
                                                           >
                                                              Company | Division
                                                           </Typography>
                                                           <Typography align="left" variant="h6">
                                                              {getCompanyOrDivisionName(
                                                                 application.company_id,
                                                                 application.division_id,
                                                              )}
                                                           </Typography>
                                                        </div>
                                                     </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           Contact Name
                                                        </Typography>
                                                        <Typography align="left" variant="h6">
                                                           {application.companyContact}
                                                        </Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           Email
                                                        </Typography>
                                                        <Typography align="left" variant="h6">
                                                           {application.email}
                                                        </Typography>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={3} lg={1} xl={2}>
                                                     <div>
                                                        <Typography
                                                           align="left"
                                                           noWrap
                                                           variant="h6"
                                                           style={{ color: "#010440", fontWeight: 700 }}
                                                        >
                                                           Status
                                                        </Typography>
                                                        <div className={classes.chipContainer}>
                                                           {application.addDate ? (
                                                              checkIfExpired(application.addDate) === true ? (
                                                                 //Expired
                                                                 <Chip
                                                                    key={application._id}
                                                                    label="Expired"
                                                                    size="small"
                                                                    clickable={false}
                                                                    className={classes.chipRed}
                                                                 />
                                                              ) : application.applicationOpened ? (
                                                                 //Opened
                                                                 <Chip
                                                                    key={application._id}
                                                                    label="Opened"
                                                                    size="small"
                                                                    clickable={false}
                                                                    className={classes.chipGreen}
                                                                 />
                                                              ) : (
                                                                 //Sent
                                                                 <Chip
                                                                    key={application._id}
                                                                    label="Sent"
                                                                    size="small"
                                                                    clickable={false}
                                                                    color="primary"
                                                                 />
                                                              )
                                                           ) : (
                                                              //Expired. This is a safety measure.
                                                              <Chip
                                                                 key={application._id}
                                                                 label="Sent"
                                                                 size="small"
                                                                 color="primary"
                                                                 clickable={false}
                                                              />
                                                           )}
                                                        </div>
                                                     </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={2} lg={1} xl={1}>
                                                     <Button
                                                        onClick={(event) => handleDetailsClick(event, index)}
                                                        variant="contained"
                                                        color="primary"
                                                        classes={{
                                                           label: smallScreen ? classes.labelMobile : classes.label,
                                                        }}
                                                        style={{ width: "100%" }}
                                                        disabled={
                                                           userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                                        }
                                                     >
                                                        <EditIcon />
                                                        Details
                                                     </Button>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                                     <ButtonGroup>
                                                        <IconButton
                                                           onClick={(event) => handleAppLinkClick(event, index)}
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                           }}
                                                           disabled={linkCopying.includes(application._id)}
                                                        >
                                                           {linkCopying.includes(application._id) ? (
                                                              <CheckIcon className={classes.buttonProgress} />
                                                           ) : (
                                                              <LinkIcon />
                                                           )}
                                                           {linkCopying.includes(application._id)
                                                              ? "Copied"
                                                              : "App Link"}
                                                        </IconButton>
                                                        <IconButton
                                                           onClick={(event) => handleResendClick(event, index)}
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                           }}
                                                           disabled={resending.includes(application._id)}
                                                        >
                                                           {resending.includes(application._id) ? (
                                                              <CircularProgress
                                                                 size={19}
                                                                 className={classes.buttonProgress}
                                                              />
                                                           ) : (
                                                              <MailIcon />
                                                           )}
                                                           Resend App
                                                        </IconButton>
                                                        <IconButton
                                                           onClick={(event) => handleDeleteClick(event, index)}
                                                           color="primary"
                                                           classes={{
                                                              label: smallScreen ? classes.labelMobile : classes.label,
                                                              root: classes.primaryRed,
                                                           }}
                                                           disabled={emailing.length > 0}
                                                        >
                                                           {emailing.includes(application._id) ? (
                                                              <CircularProgress
                                                                 size={19}
                                                                 className={classes.buttonProgress}
                                                              />
                                                           ) : (
                                                              <DeleteIcon />
                                                           )}
                                                           Delete
                                                        </IconButton>
                                                     </ButtonGroup>
                                                  </Grid>
                                               </Grid>
                                               <Divider className={classes.divider} />
                                            </Card>
                                         </>
                                      );
                                   })
                                 : applications.map((application, index) => {
                                      return (
                                         <>
                                            <Card key={index}>
                                               <Grid
                                                  container
                                                  direction={"row"}
                                                  style={{
                                                     display: "flex",
                                                     justifyContent: "flex-start",
                                                  }}
                                               >
                                                  <Grid
                                                     container
                                                     direction={"column"}
                                                     item
                                                     xs={6}
                                                     sm={7}
                                                     md={7}
                                                     lg={7}
                                                     xl={9}
                                                  >
                                                     <Grid
                                                        container
                                                        direction={"row"}
                                                        style={{
                                                           display: "flex",
                                                           justifyContent: "flex-start",
                                                        }}
                                                     >
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Application #
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {application.applicationNumber}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Company Name
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {application.companyName}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Contact Name
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {application.companyContact}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Address
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {`${application.address.city}, ${application.address.state} ${application.address.zip}`}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Customer Code
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {application.companyCode}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Date Received
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {DateTimeFormatterShortYear(application.signatureDate)}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 {application.status === "APPROVED"
                                                                    ? "Date Approved"
                                                                    : "Date Denied"}
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {DateTimeFormatterShortYear(
                                                                    application.approvedOrDeniedDate,
                                                                 )}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 D&B D-U-N-S #
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {DUNSFormatter(application.bankInfo.duns)}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Payment Terms
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {application.status === "APPROVED"
                                                                    ? application.netTerms
                                                                    : "N/A"}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Amount Requested
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {`$ ${application.amountReq.toLocaleString(undefined, {
                                                                    maximumFractionDigits: 2,
                                                                 })}`}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 {application.status === "APPROVED"
                                                                    ? "Approved Amount"
                                                                    : "Denied Code"}
                                                              </Typography>
                                                              <Typography align="left" variant="h6">
                                                                 {application.status === "APPROVED"
                                                                    ? `$ ${application.approvedAmount.toLocaleString(
                                                                         undefined,
                                                                         {
                                                                            maximumFractionDigits: 2,
                                                                         },
                                                                      )}`
                                                                    : application.code}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
                                                           <div>
                                                              <Typography
                                                                 align="left"
                                                                 noWrap
                                                                 variant="h6"
                                                                 style={{ color: "#010440", fontWeight: 700 }}
                                                              >
                                                                 Point Of Contact
                                                              </Typography>

                                                              <Typography variant="h6" align="left">
                                                                 {contactsAssigned
                                                                    .filter((x) => x._id === application._id)
                                                                    .map((x) => {
                                                                       return x.contactList;
                                                                    })}
                                                              </Typography>
                                                           </div>
                                                        </Grid>
                                                     </Grid>
                                                  </Grid>
                                                  <Grid
                                                     container
                                                     direction={"column"}
                                                     item
                                                     xs={6}
                                                     sm={5}
                                                     md={5}
                                                     lg={5}
                                                     xl={3}
                                                  >
                                                     <Grid
                                                        container
                                                        direction={"row"}
                                                        style={{
                                                           display: "flex",
                                                           justifyContent: "flex-start",
                                                        }}
                                                     >
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                           {application.status.toLowerCase() === "approved" ? (
                                                              <ButtonGroup>
                                                                 <Button
                                                                    onClick={(event) => handleNotesClick(event, index)}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    classes={{
                                                                       root: classes.notesButton,
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    style={{
                                                                       width: smallScreen ? undefined : "100px",
                                                                    }}
                                                                 >
                                                                    <NoteIcon />
                                                                    Notes
                                                                 </Button>
                                                                 <Button
                                                                    onClick={(event) =>
                                                                       handleDetailsClick(event, index)
                                                                    }
                                                                    variant="contained"
                                                                    color="primary"
                                                                    classes={{
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    style={{
                                                                       width: smallScreen ? undefined : "100px",
                                                                    }}
                                                                 >
                                                                    <EditIcon />
                                                                    Details
                                                                 </Button>
                                                                 <Button
                                                                    onClick={(event) =>
                                                                       handleDocuSignClick(event, index)
                                                                    }
                                                                    variant="contained"
                                                                    color="primary"
                                                                    classes={{
                                                                       root: classes.docuSignButton,
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    style={{
                                                                       width: smallScreen ? undefined : "100px",
                                                                    }}
                                                                    disabled={
                                                                       docuSign.includes(application._id) ||
                                                                       userState.CurrentUser.security.toUpperCase() ===
                                                                          "READ ONLY" ||
                                                                       application.docuSignCompleted !== true
                                                                    }
                                                                 >
                                                                    {docuSign.includes(application._id) ? (
                                                                       <CircularProgress
                                                                          size={19}
                                                                          className={classes.buttonProgress}
                                                                       />
                                                                    ) : (
                                                                       <DescriptionIcon />
                                                                    )}
                                                                    DocuSign
                                                                 </Button>
                                                              </ButtonGroup>
                                                           ) : (
                                                              <ButtonGroup>
                                                                 <Button
                                                                    onClick={(event) => handleNotesClick(event, index)}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    classes={{
                                                                       root: classes.notesButton,
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    style={{
                                                                       width: smallScreen ? undefined : "150px",
                                                                    }}
                                                                 >
                                                                    <NoteIcon />
                                                                    Notes
                                                                 </Button>
                                                                 <Button
                                                                    onClick={(event) =>
                                                                       handleDetailsClick(event, index)
                                                                    }
                                                                    variant="contained"
                                                                    color="primary"
                                                                    classes={{
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    style={{
                                                                       width: smallScreen ? undefined : "150px",
                                                                    }}
                                                                 >
                                                                    <EditIcon />
                                                                    Details
                                                                 </Button>
                                                              </ButtonGroup>
                                                           )}
                                                           {application.status === "APPROVED" ? (
                                                              <ThumbUpIcon
                                                                 style={{
                                                                    marginLeft: "2.60rem",
                                                                    marginTop: "1rem",
                                                                 }}
                                                                 className={classes.primaryGreen}
                                                              />
                                                           ) : (
                                                              <ThumbDownIcon
                                                                 style={{
                                                                    marginLeft: "2.60rem",
                                                                    marginTop: "1rem",
                                                                 }}
                                                                 className={classes.primaryRed}
                                                              />
                                                           )}
                                                        </Grid>
                                                        <Grid
                                                           container
                                                           direction={"row"}
                                                           style={{
                                                              display: "flex",
                                                              justifyContent: "flex-start",
                                                              marginTop: "1rem",
                                                           }}
                                                        >
                                                           {smallScreen ? (
                                                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                 <IconButton
                                                                    onClick={(event) => handleCopyClick(event, index)}
                                                                    color="primary"
                                                                    classes={{
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                 >
                                                                    <FileCopyIcon />
                                                                    Copy
                                                                 </IconButton>
                                                                 <IconButton
                                                                    onClick={(event) => handleViewClick(event, index)}
                                                                    color="primary"
                                                                    classes={{
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    disabled={viewing.includes(application._id)}
                                                                 >
                                                                    {viewing.includes(application._id) ? (
                                                                       <CircularProgress
                                                                          size={19}
                                                                          className={classes.buttonProgress}
                                                                       />
                                                                    ) : (
                                                                       <SearchIcon />
                                                                    )}
                                                                    View
                                                                 </IconButton>
                                                                 <IconButton
                                                                    onClick={(event) =>
                                                                       handleDownloadClick(event, index)
                                                                    }
                                                                    color="primary"
                                                                    classes={{
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    disabled={downloading.includes(application._id)}
                                                                 >
                                                                    {downloading.includes(application._id) ? (
                                                                       <CircularProgress
                                                                          size={19}
                                                                          className={classes.buttonProgress}
                                                                       />
                                                                    ) : (
                                                                       <GetAppIcon />
                                                                    )}
                                                                    Download
                                                                 </IconButton>
                                                                 <IconButton
                                                                    onClick={(event) => handleEmailClick(event, index)}
                                                                    color="primary"
                                                                    classes={{
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    disabled={emailing.includes(application._id)}
                                                                 >
                                                                    {emailing.includes(application._id) ? (
                                                                       <CircularProgress
                                                                          size={19}
                                                                          className={classes.buttonProgress}
                                                                       />
                                                                    ) : (
                                                                       <MailIcon />
                                                                    )}
                                                                    Email
                                                                 </IconButton>
                                                                 <IconButton
                                                                    onClick={(event) => handlePrintClick(event, index)}
                                                                    color="primary"
                                                                    classes={{
                                                                       label: smallScreen
                                                                          ? classes.labelMobile
                                                                          : classes.label,
                                                                    }}
                                                                    disabled={printing.includes(application._id)}
                                                                 >
                                                                    {printing.includes(application._id) ? (
                                                                       <CircularProgress
                                                                          size={19}
                                                                          className={classes.buttonProgress}
                                                                       />
                                                                    ) : (
                                                                       <PrintIcon />
                                                                    )}
                                                                    Print
                                                                 </IconButton>
                                                              </Grid>
                                                           ) : (
                                                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                 <ButtonGroup>
                                                                    <IconButton
                                                                       onClick={(event) =>
                                                                          handleCopyClick(event, index)
                                                                       }
                                                                       color="primary"
                                                                       classes={{
                                                                          label: smallScreen
                                                                             ? classes.labelMobile
                                                                             : classes.label,
                                                                       }}
                                                                    >
                                                                       <FileCopyIcon />
                                                                       Copy
                                                                    </IconButton>
                                                                    <IconButton
                                                                       onClick={(event) =>
                                                                          handleViewClick(event, index)
                                                                       }
                                                                       color="primary"
                                                                       classes={{
                                                                          label: smallScreen
                                                                             ? classes.labelMobile
                                                                             : classes.label,
                                                                       }}
                                                                       disabled={viewing.includes(application._id)}
                                                                    >
                                                                       {viewing.includes(application._id) ? (
                                                                          <CircularProgress
                                                                             size={19}
                                                                             className={classes.buttonProgress}
                                                                          />
                                                                       ) : (
                                                                          <SearchIcon />
                                                                       )}
                                                                       View
                                                                    </IconButton>
                                                                    <IconButton
                                                                       onClick={(event) =>
                                                                          handleDownloadClick(event, index)
                                                                       }
                                                                       color="primary"
                                                                       classes={{
                                                                          label: smallScreen
                                                                             ? classes.labelMobile
                                                                             : classes.label,
                                                                       }}
                                                                       disabled={downloading.includes(application._id)}
                                                                    >
                                                                       {downloading.includes(application._id) ? (
                                                                          <CircularProgress
                                                                             size={19}
                                                                             className={classes.buttonProgress}
                                                                          />
                                                                       ) : (
                                                                          <GetAppIcon />
                                                                       )}
                                                                       Download
                                                                    </IconButton>
                                                                    <IconButton
                                                                       onClick={(event) =>
                                                                          handleEmailClick(event, index)
                                                                       }
                                                                       color="primary"
                                                                       classes={{
                                                                          label: smallScreen
                                                                             ? classes.labelMobile
                                                                             : classes.label,
                                                                       }}
                                                                       disabled={emailing.includes(application._id)}
                                                                    >
                                                                       {emailing.includes(application._id) ? (
                                                                          <CircularProgress
                                                                             size={19}
                                                                             className={classes.buttonProgress}
                                                                          />
                                                                       ) : (
                                                                          <MailIcon />
                                                                       )}
                                                                       Email
                                                                    </IconButton>
                                                                    <IconButton
                                                                       onClick={(event) =>
                                                                          handlePrintClick(event, index)
                                                                       }
                                                                       color="primary"
                                                                       classes={{
                                                                          label: smallScreen
                                                                             ? classes.labelMobile
                                                                             : classes.label,
                                                                       }}
                                                                       disabled={printing.includes(application._id)}
                                                                    >
                                                                       {printing.includes(application._id) ? (
                                                                          <CircularProgress
                                                                             size={19}
                                                                             className={classes.buttonProgress}
                                                                          />
                                                                       ) : (
                                                                          <PrintIcon />
                                                                       )}
                                                                       Print
                                                                    </IconButton>
                                                                 </ButtonGroup>
                                                              </Grid>
                                                           )}
                                                        </Grid>
                                                     </Grid>
                                                  </Grid>
                                               </Grid>
                                               <Divider className={classes.divider} />
                                            </Card>
                                         </>
                                      );
                                   })}
                           </div>
                        </div>
                        <div
                           style={{
                              display: status.toLowerCase() === "reviewed" ? "block" : "none",
                           }}
                        >
                           <div className="ApplicationView-footer">
                              <div
                                 className={
                                    drawerState.DrawerOpen === true
                                       ? "ApplicationView-layout-drawer-open"
                                       : "ApplicationView-layout"
                                 }
                              >
                                 <Pagination
                                    color="primary"
                                    count={totalPages}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, page) => setPage(page)}
                                    page={page}
                                    boundaryCount={smallScreen ? undefined : 7}
                                    style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "100%" }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
         <ApplicationNew
            open={inviteModal}
            handleClose={() => {
               setInviteModal(!inviteModal);
            }}
            openAlertMessage={openAlertMessage}
         />
         <NotesDialog
            open={notesModal}
            application={selectedApplication}
            setApplication={setSelectedApplication}
            handleClose={() => handleNotesClose()}
            onChange={onChangeNotes}
         />
         <CopyDialog open={copyModal} application={selectedApplication} handleClose={() => handleCopyClose()} />
         <DeleteDialog
            title={"Delete Application"}
            text={deleteText}
            text2={"Are you sure you want to continue?"}
            open={deleteModal}
            handleDelete={() => deleteApplication()}
            handleClose={() => handleDeleteClose()}
         />
      </>
   );
};

export default ApplicationView;
