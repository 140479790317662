import { makeStyles, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { setCompany } from "../../api/CompanyApi";
import { setDivision } from "../../api/DivisionApi";
import { UserContext } from "../../stores/Users/Context";
import { Divisions } from "../../types/Divisions";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   saveButton: {
      color: "white",
      backgroundColor: green[500],
      "&:hover": {
         backgroundColor: green[600],
      },
   },
   buttonProgress: {
      color: green[500],
   },
}));

export const DenialCodeNewDialog: React.FC<any> = (props: {
   open: boolean;
   divisionOrCompany: Divisions;
   setDivisionOrCompany: (value: React.SetStateAction<Divisions>) => void;
   component: "division" | "company";
   denialCodeId: string;
   handleClose: () => void;
   openAlertMessage: (alertMessage: string, alertType: "error" | "info" | "success" | "warning") => Promise<void>;
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const { state: userState } = useContext(UserContext);
   const [denialCode, setDenialCode] = useState({
      _id: "",
      code: "",
      description: "",
      addDate: new Date(),
      deleteDate: null,
   });
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");

   useEffect(() => {
      if (props.open && props.denialCodeId) {
         const selectedDenialCode = _.filter(props.divisionOrCompany.denialCodes, (x) => x._id === props.denialCodeId);

         if (selectedDenialCode.length > -1) {
            const newDenialCode = {
               _id: selectedDenialCode[0]._id,
               code: selectedDenialCode[0].code,
               description: selectedDenialCode[0].description,
               addDate: selectedDenialCode[0].addDate ? selectedDenialCode[0].addDate : new Date(),
               deleteDate: null,
            };

            setDenialCode(newDenialCode);
         }
      }
   }, [props.open, props.denialCodeId]);

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function saveDenialCode() {
      try {
         if (!denialCode.code) {
            openAlertMessage("You must enter a Denial Code!", "warning");
            return;
         }

         if (!denialCode.description) {
            openAlertMessage("You must enter a Denial Description!", "warning");
            return;
         }

         //allows no characters except for (-)
         const allowSomeSpecialCharOnlyDash = new RegExp(/[!@#?$%><:;"^/*){}&,|(+=._\\]/);

         if (denialCode.code.match(allowSomeSpecialCharOnlyDash)) {
            openAlertMessage("Special characters except '-' are not allowed for the Denial Code!", "warning");
            return;
         }

         console.log(props.divisionOrCompany.denialCodes);

         const denialCodeExists = _.filter(
            props.divisionOrCompany.denialCodes,
            (x) => x.code === denialCode.code && x._id !== denialCode._id,
         );

         if (denialCodeExists.length > 0) {
            openAlertMessage("Denial Code must be unique!", "warning");
            return;
         }

         if (userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN") {
            openAlertMessage("Access to add a new denial code is denied!", "warning");
            return;
         }

         setButtonClicked(true);

         const divisionOrCompanyObj = { ...props.divisionOrCompany };

         if (props.denialCodeId) {
            const index = _.findIndex(divisionOrCompanyObj.denialCodes, (x) => x._id === props.denialCodeId);

            divisionOrCompanyObj.denialCodes[index] = denialCode;
         } else {
            divisionOrCompanyObj.denialCodes.push(denialCode);
         }

         if (props.component === "division") {
            const responseData = await setDivision(divisionOrCompanyObj);

            if (responseData.success) {
               setButtonClicked(false);
               props.setDivisionOrCompany(responseData.data);
               props.openAlertMessage("Successfully added the denial code.", "success");
               setDenialCode({
                  _id: "",
                  code: "",
                  description: "",
                  addDate: new Date(),
                  deleteDate: null,
               });
               props.handleClose();
            } else {
               openAlertMessage(responseData.message, "warning");
               setButtonClicked(false);
               return;
            }
         } else {
            const responseData = await setCompany(divisionOrCompanyObj);

            if (responseData.success) {
               setButtonClicked(false);
               props.setDivisionOrCompany(responseData.data);
               props.openAlertMessage("Successfully added the denial code.", "success");
               setDenialCode({
                  _id: "",
                  code: "",
                  description: "",
                  addDate: new Date(),
                  deleteDate: null,
               });
               props.handleClose();
            } else {
               openAlertMessage(responseData.message, "warning");
               setButtonClicked(false);
               return;
            }
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "warning");
         setButtonClicked(false);
      }
   }

   async function onChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: "code" | "description",
   ) {
      try {
         const newDenialCode = { ...denialCode };

         if (value === "code") {
            newDenialCode[value] = event.target.value ? event.target.value.toUpperCase() : "";
         } else {
            newDenialCode[value] = event.target.value ? event.target.value : "";
         }

         setDenialCode(newDenialCode);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         fullScreen={mobileScreen}
         fullWidth
         maxWidth={"xs"}
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            New Denial Code
         </DialogTitle>
         <DialogContent>
            <Collapse in={alertMessage ? true : false}>
               <Alert
                  variant="filled"
                  severity={alertType}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           setAlertMessage("");
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <TextField
               id="tf-code"
               label="Denial Code"
               name="code"
               onChange={(event) => onChange(event, "code")}
               value={denialCode.code}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
               inputProps={{ maxLength: 20 }}
               autoComplete="new-password"
            />
            <TextField
               id="tf-description"
               label="Denial Description"
               name="description"
               multiline
               minRows={15}
               rowsMax={15}
               onChange={(event) => onChange(event, "description")}
               value={denialCode.description}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
               autoComplete="new-password"
            />
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={() => {
                     setDenialCode({
                        _id: "",
                        code: "",
                        description: "",
                        addDate: new Date(),
                        deleteDate: null,
                     });
                     props.handleClose();
                  }}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={saveDenialCode}
                  variant="contained"
                  color="primary"
                  classes={{
                     root: classes.saveButton,
                     label: classes.label,
                  }}
                  disabled={buttonClicked}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <SaveIcon />}
                  Save
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default DenialCodeNewDialog;
