import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import "./App.css";
import { getCompanyList } from "./api/CompanyApi";
import { getDivisionList } from "./api/DivisionApi";
import { getUserById } from "./api/UserApi";
import { Header } from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Activation from "./pages/Activation/Activation";
import Application from "./pages/Application/Application";
import Company from "./pages/Company/Company";
import Division from "./pages/Division/Division";
import Home from "./pages/Home";
import User from "./pages/User/User";
import { CompanyContext } from "./stores/Companies/Context";
import { CompanyTypes } from "./stores/Companies/Reducers";
import { DivisionContext } from "./stores/Divisions/Context";
import { DivisionTypes } from "./stores/Divisions/Reducers";
import { UserContext } from "./stores/Users/Context";
import { Types } from "./stores/Users/Reducers";
import CheckAnimation from "./styles/CheckAnimation";
import { Companies } from "./types/Companies";
import { Divisions } from "./types/Divisions";
import { Users } from "./types/Users";
import * as CookieTools from "./utilities/CookieTools";
import SecureRoute from "./utilities/SecureRoute";

export const App: React.FC<any> = (): JSX.Element => {
   const { state: userState, dispatch: userDispatch } = useContext(UserContext);
   const { state: companyState, dispatch: companyDispatch } = useContext(CompanyContext);
   const { state: divisionState, dispatch: divisionDispatch } = useContext(DivisionContext);
   const [loading, setLoading] = useState(true);
   let inactiveTimerId = 0;

   //Loading Use Effect.
   useEffect(() => {
      if (
         window.location.pathname !== "/login" &&
         window.location.pathname !== "/forgotpassword" &&
         window.location.pathname !== "/passwordreset"
      ) {
         if (!CookieTools.checkCookie("ca-user-id")) {
            window.location.replace("/login");
         } else {
            async function getUser() {
               const userId = CookieTools.getCookie("ca-user-id");

               const responseData = await getUserById(userId);

               if (responseData.success && responseData.data) {
                  const user: Users = responseData.data;

                  userDispatch({
                     type: Types.Create,
                     payload: { CurrentUser: user },
                  });
               } else {
                  window.location.replace("/login");
               }
            }

            async function getCompanies() {
               const responseData = await getCompanyList();

               if (responseData.success && responseData.data) {
                  const companies: Companies[] = responseData.data;

                  companyDispatch({
                     type: CompanyTypes.Create,
                     payload: { Companies: companies },
                  });
               } else {
                  window.location.replace("/login");
               }
            }

            async function getDivisions() {
               const responseData = await getDivisionList();

               if (responseData.success && responseData.data) {
                  const divisions: Divisions[] = responseData.data;

                  divisionDispatch({
                     type: DivisionTypes.Create,
                     payload: { Divisions: divisions },
                  });
               } else {
                  window.location.replace("/login");
               }
            }

            getUser();
            getCompanies();
            getDivisions();
         }
      }
   }, []);

   //Logged in user Use Effect.
   useEffect(() => {
      if (!loading) {
         if (!userState.CurrentUser._id || !CookieTools.checkCookie("ca-user-id")) {
            window.location.replace("/login");
         }
      }
   }, [userState, loading]);

   //Loading Use Effect
   useEffect(() => {
      if (userState.CurrentUser._id && companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         if (userState.CurrentUser.security.toUpperCase() === "SUPER ADMIN") {
            if (!CookieTools.getCookie("selected-company") && !CookieTools.getCookie("selected-division")) {
               const companyList: string[] = [];

               _.forEach(companyState.Companies, async (company) => {
                  await companyList.push(company._id);
               });

               CookieTools.setCookie("selected-company", companyList.toString());

               const divisionList: string[] = [];

               _.forEach(divisionState.Divisions, async (division) => {
                  await divisionList.push(division._id);
               });

               CookieTools.setCookie("selected-division", divisionList.toString());
            }
         } else {
            if (!CookieTools.getCookie("selected-company")) {
               CookieTools.setCookie(
                  "selected-company",
                  userState.CurrentUser.companies ? userState.CurrentUser.companies.toString() : "",
               );
            }

            if (!CookieTools.getCookie("selected-division")) {
               CookieTools.setCookie(
                  "selected-division",
                  userState.CurrentUser.divisions ? userState.CurrentUser.divisions.toString() : "",
               );
            }
         }
      }

      if (userState.CurrentUser._id && companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         if (loading) {
            setLoading(false);
         }
      }
   }, [userState, companyState, divisionState]);

   async function inactiveListeners() {
      window.addEventListener("mousemove", resetTimer, false);
      window.addEventListener("mousedown", resetTimer, false);
      window.addEventListener("keypress", resetTimer, false);
      window.addEventListener("DOMMouseScroll", resetTimer, false);
      window.addEventListener("mousewheel", resetTimer, false);
      window.addEventListener("touchmove", resetTimer, false);
      window.addEventListener("MSPointerMove", resetTimer, false);

      startTimer();
   }

   inactiveListeners();

   async function startTimer() {
      // wait 30 minutes before logging user out.
      inactiveTimerId = window.setTimeout(goInactive, 1800000);
   }

   async function resetTimer() {
      window.clearTimeout(inactiveTimerId);
      goActive();
   }

   async function goInactive() {
      logout();
   }

   async function goActive() {
      startTimer();
   }

   async function logout() {
      CookieTools.deleteCookie("ca-user-id");
      CookieTools.deleteCookie("selected-company");
      CookieTools.deleteCookie("selected-division");
      window.location.replace("/login/loggedOut");
   }

   return (
      <div className="App">
         {loading ? (
            <>
               <CheckAnimation />
            </>
         ) : (
            <>
               <Sidebar />
               <Header content={userState.CurrentUser.firstName} loading={loading} />
               <Switch>
                  <SecureRoute path="/applications" component={Application} module="applications" />
                  <SecureRoute path="/activations" component={Activation} module="activations" />
                  <SecureRoute path="/users" component={User} module="users" />
                  <SecureRoute path="/companies" component={Company} module="companies" />
                  <SecureRoute path="/divisions" component={Division} module="divisions" />
                  {/* THIS ONE MUST BE AT THE BOTTOM!!! */}
                  <SecureRoute path="/" component={Home} module="home" />
               </Switch>
            </>
         )}
      </div>
   );
};

export default App;
