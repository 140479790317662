import { createStyles, makeStyles } from "@material-ui/core";

export const useValidationCompanyInformationStyles = makeStyles((theme) =>
   createStyles({
      container: {
         borderRadius: "8px",
         padding: "24px",
      },
      title: {
         color: theme.palette.primary.main,
         fontSize: "24px",
      },
      section2: {
         display: "flex",
         justifyContent: "space-between",
         gap: "24px",
      },
      section2Title: {
         fontSize: "24px",
         fontWeight: 400,
         letterSpacing: "0.1px",
         "& span": {
            color: theme.palette.primary.main,
         },
      },
      section2Paragraph: {
         fontSize: "14px",
      },
      formControl: {
         width: "100%",
         minWidth: "200px",
      },
   }),
);
